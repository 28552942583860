import { Button, Input, MenuItem, Modal, Select, VSpacer } from '@/components/DesignSystem';
import {
  Benefit,
  Tier,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramModal';
import { useState } from 'react';

interface RewardsTierBenefitModalProps {
  benefit?: Benefit,
  onClose: () => void,
  onSave: (benefit: Benefit) => void,
  tiers: Tier[],
}

export const RewardsTierBenefitModal = ({
  benefit,
  onClose,
  onSave,
  tiers,
}: RewardsTierBenefitModalProps) => {
  const [selectedTier, setSelectedTier] = useState<Tier | undefined>(benefit?.tier);
  const [name, setName] = useState<string | undefined>(benefit?.name);
  const [description, setDescription] = useState<string | undefined>(benefit?.description);
  const [termsUrl, setTermsUrl] = useState<string | null | undefined>(benefit?.termsUrl);
  const isValid = !!selectedTier && !!name && description;

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={() => {
            onClose();
            if (selectedTier && name && description) {
              onSave({
                description,
                name,
                termsUrl: termsUrl || null,
                tier: selectedTier,
              });
            }
          }}
          testID="tier-benefit-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
          testID="tier-benefit-modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID="tier-benefit-modal"
      title="Add Custom Benefit"
    >
      <Select
        label="Select tier"
        onChangeValue={(tierName) => {
          const foundTier = tiers.find((tier) => tier.name === tierName);
          setSelectedTier(foundTier);
        }}
        testID="tier-select"
        value={selectedTier?.name}
      >
        {tiers.map((tier) => (
          <MenuItem key={tier.name} testID={`${tier.name}-tier-select`} value={tier.name}>
            {tier.name}
          </MenuItem>
        ))}
      </Select>
      <VSpacer size="7" />
      <Input
        label="Benefit title"
        maxCharacterLimit={50}
        onChangeText={setName}
        showCharacterCountdown
        testID="benefit-title-input"
        value={name}
      />
      <VSpacer size="7" />
      <Input
        label="Benefit description"
        maxCharacterLimit={250}
        multiline
        onChangeText={setDescription}
        rows={3}
        showCharacterCountdown
        testID="benefit-title-input"
        value={description}
      />
      <VSpacer size="7" />
      <Input
        label="Terms URL (optional)"
        onChangeText={setTermsUrl}
        testID="benefit-terms-url-input"
        value={termsUrl}
      />
    </Modal>
  );
};
