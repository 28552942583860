import { Divider, Stack } from '@mui/material';
import { Button, Modal, Text, TextAreaInput, VSpacer } from '@/components/DesignSystem';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import Edit from '@mui/icons-material/Edit';

const MaxDescriptionLength = 1000;

interface DescriptionCardProps {
  description?: string | null,
  onChange: (description: string) => void,
}

export const DescriptionCard = ({
  description,
  onChange,
}: DescriptionCardProps) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);

  return (
    <>
      <Stack>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text category="body-xlarge">
            Retailer Description
          </Text>
          <Button
            color={description ? 'inherit' : 'primary'}
            onClick={() => setShowDescriptionModal(true)}
            startIcon={description ? <Edit/> : <AddIcon/>}
            testID="add-description-button"
            variant="outlined"
          >
            {description ? 'Edit' : 'Add Description'}
          </Button>
        </Stack>
        {description ? (
          <>
            <VSpacer size="3" />
            <Text category="label-medium">
              Description
            </Text>
            <Text category="body-large">
              {description}
            </Text>
            <VSpacer size="3" />
          </>
        ) : (
          <>
            <VSpacer size="6" />
            <Divider />
          </>
        )}
      </Stack>
      {showDescriptionModal && (
        <DescriptionModal
          description={description}
          onClose={() => setShowDescriptionModal(false)}
          onSave={(newDescription) => onChange(newDescription)}
        />
      )}
    </>
  );
};


interface DescriptionModalProps {
  description?: string | null,
  onClose: () => void,
  onSave: (description: string) => void,
}

const DescriptionModal = ({
  description,
  onClose,
  onSave,
}: DescriptionModalProps) => {
  const [value, setValue] = useState(description ?? '');
  const isEditing = !!description;
  const isValueChanged = value !== (description ?? '');
  const isValid = isValueChanged && value.length <= MaxDescriptionLength;

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={() => {
            onClose();
            onSave(value);
          }}
          testID="description-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
          testID="description-modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID="description-modal"
      title={`${isEditing ? 'Edit' : 'Add'} retailer description`}
    >
      <TextAreaInput
        characterCountdownStart={99}
        maxLength={MaxDescriptionLength}
        onChangeText={setValue}
        showCharacterCountdown
        testID="description-modal-input"
        value={value}
      />
    </Modal>
  );
};
