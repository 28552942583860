import { ReactComponent as GrowersLogo } from '@/assets/icons/growers-icon.svg';
import { ReactComponent as ManufacturerLogo } from '@/assets/icons/manufacturer-icon.svg';
import { ReactComponent as ProductLogo } from '@/assets/logos/black/custom_product_black.svg';
import { Button, HSpacer, MenuItem, Text } from "@/components/DesignSystem";
import { Avatar } from "@/components/DesignSystem/Avatar/Avatar";
import { AppConfig } from "@/constants/AppConfig";
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useGetRetailerList } from "@/hooks/useProductQuery";
import { HierarchyOfRetailersApi } from "@/utilities/api/HierarchyOfRetailersApi";
import { ApiRetailer } from "@api/interfaces";
import { Check } from "@mui/icons-material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Store from "@mui/icons-material/Store";
import { ListItemIcon, ListItemText, Menu, Stack } from "@mui/material";
import { isBoolean } from "lodash";
import React, { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

enum QueryParams {
  'retailerId' = 'retailerId',
}

const ERPRetailerSelector = ({
  isErpEnabled,
  hasErpProducts,
  header,
  logo,
  onSelect,
} : {
  isErpEnabled?: boolean,
  hasErpProducts?: boolean,
  header: string,
  logo?: 'growers' | 'manufacturer' | 'product' | 'store' | 'none'
  onSelect: (retailer?: ApiRetailer) => void,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRetailer, setSelectedRetailer] = useState<null | ApiRetailer>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isInternal } = useAuthentication();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRetailerSelected = useCallback(async (selectedRetailerId?: string) => {
    setAnchorEl(null);

    if (!selectedRetailerId) {
      setSelectedRetailer(null);
      onSelect();
      return;
    }

    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      newParams.set(QueryParams.retailerId, selectedRetailerId);
      return newParams;
    });

    const { data } = await HierarchyOfRetailersApi.listRetailers(
      { id: [selectedRetailerId] },
    );
    setSelectedRetailer(data[0]);
    onSelect(data[0]);
  }, []);

  const { retailerList } = useGetRetailerList({
    ...(isBoolean(isErpEnabled) && { isErpEnabled }),
    ...(isBoolean(hasErpProducts) && { hasErpProducts }),
  }, {
    enabled: isInternal,
    onSuccess: (data) => {
      const selectedRetailerId = searchParams.get(QueryParams.retailerId);
      if (selectedRetailerId) {
        void handleRetailerSelected(selectedRetailerId);
      } else {
        void handleRetailerSelected(data?.[0].id);
      }
    },
  });

  if (!isInternal) {
    return (
      <Stack alignItems="center" direction="row">
        <Text category="display-small">{header}</Text>
      </Stack>
    );
  }

  const logoSrc = selectedRetailer?.image && `${AppConfig.staticImageHost}/${selectedRetailer.image}`;

  const getDisplayIcon = () => {
    if (!logo || (selectedRetailer && !logoSrc)) {
      return <Store sx={{ height: 16, width: 16 }} />;
    }

    if (logo === 'growers') {
      return <GrowersLogo height={16} width={16}/>;
    } else if (logo === 'manufacturer') {
      return <ManufacturerLogo height={16} width={16}/>;
    } else if (logo === 'product') {
      return <ProductLogo height={16} width={16}/>;
    } else if (logo === 'store') {
      return <Store sx={{ height: 16, width: 16 }} />;
    }
    return null;
  };

  return (
    <Stack alignItems="center" direction="row">
      <Text category="display-small">{header}</Text>
        <HSpacer size='6' />
        <Button
          onClick={(e) => handleClick(e)}
          square={true}
          testID="retailer-selector-menu"
        >
          <Stack alignItems="center" direction="row">
            <Text category="label-medium">Select a list</Text>
            <HSpacer size="3"/>
            <ArrowDropDown sx={{ height: '16px', width: '16px' }} />
          </Stack>
        </Button>
        <HSpacer size='5' />
        <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
          {retailerList?.map((retailer) => {
            return (
              <MenuItem
                key={retailer.id}
                onClick={() => handleRetailerSelected(retailer.id)}
                selected={selectedRetailer?.id === retailer.id}
                testID={`menu-item-${retailer.id}`}
                value={retailer.id}
              >
                <ListItemText>{retailer.name}</ListItemText>
                {selectedRetailer?.id === retailer.id && (
                  <ListItemIcon>
                    <Check color='primary' />
                  </ListItemIcon>
                )}
              </MenuItem>
            );
          })}
        </Menu>
        {(!!selectedRetailer || logo !== 'none') && (
          <Avatar
            alt={`${selectedRetailer?.name ?? 'retailer'} logo`}
            height={24}
            src={logoSrc ?? undefined}
            width={24}
          >
            {logoSrc ? null : getDisplayIcon()}
          </Avatar>
        )}
        <HSpacer size="4" />
        <Text category="title-large">
          {selectedRetailer ? selectedRetailer.name : ''}
        </Text>
    </Stack>
  );
};

export default ERPRetailerSelector;
