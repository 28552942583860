import { Tabs, Text } from '@/components/DesignSystem';
import { DashboardModal } from '@/pages/CustomerDashboard/DashboardModal';
import { ResolvedRewardsProgram, ResolvedTier } from '@/pages/CustomerDashboard/interfaces';
import { TierProgress } from '@/pages/CustomerDashboard/MyProgress/TierProgress';
import { ProgramOverview } from '@/pages/CustomerDashboard/MyProgress/ProgramOverview';
import { ApiCategory } from '@api/interfaces';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { Divider, Tab } from '@mui/material';
import { useState } from 'react';

interface MyProgressModalProps {
  brandColor: string,
  categories: ApiCategory[],
  customerId: string,
  isReachedFinalTier: boolean,
  nextTier: ResolvedTier,
  onClose: () => void,
  program: ResolvedRewardsProgram,
  rewards: ApiFarmerRewards,
}

export const MyProgressModal = ({
  brandColor,
  categories,
  customerId,
  isReachedFinalTier,
  nextTier,
  onClose,
  program,
  rewards,
}: MyProgressModalProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <DashboardModal
      hideCloseButtonIcon
      onClose={onClose}
      open
      testID="my-progress-modal"
      title="My Progress"
    >
      <Tabs
        onChange={(_, value) => setSelectedTab(value)}
        sx={{ '.MuiTabs-indicator': { backgroundColor: brandColor } }}
        testID="my-progress-modal-tabs"
        value={selectedTab}
        variant="fullWidth"
      >
        <Tab
          label={
            <Text
              category="title-small"
              sx={{ color: selectedTab === 0 ? brandColor : '#525252' }}
            >
              Tier progress
            </Text>
          }
        />
        <Tab
          label={
            <Text
              category="title-small"
              sx={{ color: selectedTab === 1 ? brandColor : '#525252' }}
            >
              Program overview
            </Text>
          }
        />
      </Tabs>
      <Divider />
      {selectedTab === 0 && (
        <TierProgress
          brandColor={brandColor}
          categories={categories}
          isReachedFinalTier={isReachedFinalTier}
          nextTier={nextTier}
          program={program}
          rewards={rewards}
        />
      )}
      {selectedTab === 1 && (
        <ProgramOverview
          categories={categories}
          customerId={customerId}
          farmerRewards={rewards}
          program={program}
        />
      )}
    </DashboardModal>
  );
};
