import React from "react";
import { BottomSheet, Button, Dialog, MenuItem, Text } from "@/components/DesignSystem";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { MobileOnly } from "@/components/shared/MobileOnly";
import { Stack } from "@mui/material";

type ConfirmDialogProps = {
  cancelText?: string,
  children?: React.ReactNode,
  confirmText?: string,
  isLoading?: boolean,
  onCancel: () => void,
  onConfirm: () => void,
  open: boolean,
  showCloseButton?: boolean,
  testID: string,
  title?: string,
}
export const ConfirmDialog = ({
  cancelText,
  children,
  confirmText,
  isLoading = false,
  onCancel,
  onConfirm,
  open,
  showCloseButton,
  testID,
  title,
}: ConfirmDialogProps) => {
  return (
    <>
      <MobileOnly>
        <BottomSheet
          onClose={onCancel}
          onOpen={() => { /* nothing to do, but onOpen is required */ }}
          open={open}
          testID={testID}
        >
          <Stack alignItems="start">
            <Stack padding="0px 0px 12px 16px">
              <Text category="title-large" testID={`${testID}-title`}>{title}</Text>
            </Stack>
            <Stack padding="0px 0px 12px 16px">
              {children}
            </Stack>
            <MenuItem
              color="primary"
              onClick={onConfirm}
              testID={`${testID}-confirm`}
            >
              {confirmText || 'Confirm'}
            </MenuItem>
            <MenuItem
              onClick={onCancel}
              testID={`${testID}-cancel`}
            >
              {cancelText || 'Cancel'}
            </MenuItem>
          </Stack>
        </BottomSheet>
      </MobileOnly>
      <DesktopOnly>
        <Dialog
          acceptButton={() => (
            <Button
              loading={isLoading}
              onClick={onConfirm}
              testID={`${testID}-confirm`}
              variant="contained"
            >
              {confirmText  || 'Confirm'}
            </Button>
          )}
          cancelButton={() => (
            <Button
              disabled={isLoading}
              onClick={onCancel}
              testID={`${testID}-cancel`}
              variant="text"
            >
              {cancelText || 'Cancel'}
            </Button>
          )}
          onClose={onCancel}
          open={open}
          showCloseButton={showCloseButton}
          testID={testID}
          title={title}
        >
          {children}
        </Dialog>
      </DesktopOnly>
    </>
  );
};
