import { AppConfig } from '@/constants/AppConfig';
import { ISO8601Regex, recursiveRewrite } from '@shared/utilities';
import { ApiUtils } from './ApiUtils';

export type ClientOptions = {
  body?: object | FormData,
  isImage?: boolean
  method?: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'PATCH',
};

export const Client = async <T = unknown>(
  endpoint: string,
  options: ClientOptions = {},
): Promise<T> => {
  const { body, isImage: image = false, method = body ? 'POST' : 'GET' } = options;
  const headers: HeadersInit = {
    Accept: 'application/json',
  };

  const hasFormData = !!(body && body instanceof FormData);
  if (body) {
    if (!hasFormData) {
      headers['Content-Type'] = 'application/json';
    }
  }

  const config: RequestInit = {
    credentials: 'include',
    headers,
    method,
  };

  if (body) {
    if (hasFormData) {
      config.body = body;
    } else {
      config.body = JSON.stringify(body);
    }
  }

  const baseUrl = AppConfig.biddingApiUrl;

  const response = await fetch(`${baseUrl}/${endpoint}`, config);

  if (
    endpoint !== "users/me" &&
    endpoint !== "login" &&
    response.status === 401
  ) {
    document.location.reload();
  }

  if (!response.ok) {
    await ApiUtils.handleError(response);
  }

  if (image) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.blob() as any;
  }

  if (response.status !== 204) {
    if (response.headers.get('content-type')?.includes('text/html')) {
      return (await response.text()) as T;
    } else {
      const json = await response.json() as T;
      return mapStringDatesToDate(json) as T;
    }
  }

  return {} as T;
};

export function mapStringDatesToDate<T> (obj: T): T {
  return recursiveRewrite(obj as never, (value) => {
    if (typeof value === 'string' && value.match(ISO8601Regex)) {
      return new Date(value);
    }
    return value;
  }) as T;
}
