import { Client } from '@/utilities/api/Client';
import { QueryUtil } from '../Query';
import { PromotionEndpoint } from '@api/endpoints/PromotionEndpoint';

export class PromotionApi {
  static create (
    body: PromotionEndpoint.Create.Request,
  ): Promise<PromotionEndpoint.Create.Response> {
    return Client(`promotions`, {
      method: 'POST',
      body,
    });
  }

  static delete (id: string): Promise<void> {
    return Client(`promotions/${id}`, { method: 'DELETE' });
  }

  static get (id: string, accessToken?: string): Promise<PromotionEndpoint.Get.Response> {
    let query = '';
    if (accessToken) {
      query = `accessToken=${accessToken}`;
    }
    return Client(`promotions/${id}?${query}`);
  }

  static list (
    body: PromotionEndpoint.List.Query,
  ): Promise<PromotionEndpoint.List.Response> {
    return Client(`promotions/list`, {
      method: 'POST',
      body,
    });
  }

  static listForRetailer (
    query: PromotionEndpoint.ListForRetailer.Query,
  ): Promise<PromotionEndpoint.ListForRetailer.Response> {
    const queryString = QueryUtil.stringify(query);
    return Client(`promotions-retailer?${queryString}`);
  }

  static restartProcessing (
    id: string,
  ): Promise<void> {
    return Client(`promotions/${id}/restart-processing`, { method: 'POST' });
  }

  static update (
    id: string,
    body: PromotionEndpoint.Update.Request,
  ): Promise<PromotionEndpoint.Update.Response> {
    return Client(`promotions/${id}`, {
      method: 'PATCH',
      body,
    });
  }
}
