import { Button, Dialog, Text, VSpacer } from '@/components/DesignSystem';
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';
import { PointsTag } from '@/components/shared/PointsTag';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { LoyaltyPointsCapProgress } from '@/pages/Promotions/LoyaltyPointsCapProgress';
import { PromotionStatusChip } from '@/pages/Promotions/PromotionStatusChip';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { CircularProgress, Divider, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { SponsorType } from '@shared/enums';
import { getJsDateFromString } from '@shared/utilities';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { useQuery } from 'react-query';
import { getGenericImage } from "@/pages/FarmerList/helper";

interface PromotionDetailsModalProps {
  onBack?: () => void,
  onClose: () => void,
  promotionId: string,
}

export const PromotionDetailsModal = ({
  onBack,
  onClose,
  promotionId,
}: PromotionDetailsModalProps) => {
  const theme = useTheme();
  const { data: promotion, isFetching } = useQuery(
    [QueryKeys.GET_PROMOTION, promotionId],
    () => PromotionApi.get(promotionId),
  );

  const getLogoUrl = () => {
    const imageName = promotion?.manufacturerId
      ? promotion.manufacturer?.logo ?? null
      : promotion?.retailer?.image ?? null;
    return imageName
      ? `${AppConfig.staticImageHost}/${imageName}`
      : getGenericImage(promotion?.retailerId ?? null, promotion?.manufacturerId ?? null);
  };

  const getSponsorName = () => (
    promotion?.manufacturer?.name ?? promotion?.retailer?.name
  );

  const formatDate = (date: Date) => DateTime.fromJSDate(date).toFormat('MMM d, yyyy');
  const formattedStartDate = promotion && formatDate(getJsDateFromString(promotion.startDate));
  const formattedEndDate = promotion && formatDate(getJsDateFromString(promotion.endDate));
  const locationNameList = sortBy(
    promotion?.locations ?? [],
    ({ name }) => name,
  ).map((location) => location.name).join(', ');
  const productsEarnList = sortBy(
    promotion?.productsForEarningPoints ?? [],
    ({ name }) => name,
  ).map((product) => product.name).join(', ');
  const productsRedeemList = sortBy(
    promotion?.productsForRedeemingPoints ?? [],
    ({ name }) => name,
  ).map((product) => product.name).join(', ');
  const manufacturerName = promotion?.manufacturer?.name ?? '';
  const retailerName = promotion?.retailer?.name ?? '';

  const getProductRedeemText = () => {
    if (!promotion) {
      return '';
    }
    if (productsRedeemList) {
      return productsRedeemList;
    }
    if (promotion.sponsorType === SponsorType.Retailer) {
      return `All products sold by ${retailerName}`;
    }
    return `All ${manufacturerName} products sold by ${retailerName}`;
  };

  return (
    <Dialog
      acceptButton={(props) => (
        <Button {...props} onClick={onClose} variant="contained">
          Close
        </Button>
      )}
      dialogWidth="560px"
      onClose={onClose}
      open
      secondaryActionButton={onBack ? (props) => (
        <Button {...props} onClick={onBack} startIcon={<ArrowBack />} variant="outlined">
          Back
        </Button>
      ) : undefined}
      showCloseButton={false}
      testID="promotion-details-modal"
      title="Promotion details"
    >
      {(isFetching || !promotion) ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Stack>
          <Stack alignItems="center" p="0px 24px 16px 24px">
            <Avatar
              alt={`${getSponsorName()} logo`}
              height={56}
              src={getLogoUrl()}
              width={56}
            />
            <VSpacer size="2" />
            <Text category="headline-medium" textAlign="center">
              {getSponsorName()}
            </Text>
            {!!promotion.manufacturerId && (
              <>
                <VSpacer size="2" />
                <Text category="overline" sx={{ color: theme.palette.text.secondary }}>
                  MANUFACTURER PROMOTION
                </Text>
              </>
            )}
          </Stack>
          <Stack bgcolor="white" borderRadius="2px" color="#141414" py="4px" width="100%">
            <Text category="label-medium" textAlign="center">
              Runs {formattedStartDate} – {formattedEndDate}
            </Text>
          </Stack>
          <VSpacer size="6" />
          <PromotionStatusChip promotion={promotion} />
          <VSpacer size="3" />
          <Text category="title-large" fontWeight={500}>
            {promotion.name}
          </Text>
          <VSpacer size="2" />
          <PointsTag
            points={promotion.value}
            rewardsType={promotion.rewardType}
            starHeight="25px"
            starWidth="25px"
            textCategory="body-large"
            uom={promotion.uom}
          />
          {!!promotion.pointCap && (
            <>
              <VSpacer size="3" />
              <LoyaltyPointsCapProgress promotion={promotion} />
            </>
          )}
          {promotion.overridePointsEngine && (
            <>
              <VSpacer size="3" />
              <Stack bgcolor={theme.palette.background.paper} borderRadius="4px" p="8px 12px">
                <Text category="body-small" sx={{ color: theme.palette.text.secondary }}>
                  Only allow manufacturer promotion points to be granted. Retailer Rewards Program
                  points are not granted for the products included in this promotion.
                </Text>
              </Stack>
            </>
          )}
          <VSpacer size="6" />
          {!!promotion.description && (
            <>
              <Divider />
              <VSpacer size="6" />
              <Text category="title-medium">Promotion information</Text>
              <VSpacer size="3" />
              <Text category="body-medium" sx={{ color: theme.palette.text.secondary }}>
                {promotion.description}
              </Text>
              <VSpacer size="6" />
            </>
          )}
          <Divider />
          <VSpacer size="6" />
          <Text category="title-medium">
            Participating {promotion.retailer?.name} locations
          </Text>
          <VSpacer size="3" />
          <Text category="body-medium" sx={{ color: theme.palette.text.secondary }}>
            {locationNameList}
          </Text>
          <VSpacer size="6" />
          <Divider />
          <VSpacer size="6" />
          <Text category="title-medium">Products that you can earn points on</Text>
          <VSpacer size="3" />
          <Text category="body-medium" sx={{ color: theme.palette.text.secondary }}>
            {productsEarnList}
          </Text>
          <VSpacer size="6" />
          <Divider />
          <VSpacer size="6" />
          <Text category="title-medium">Products that points can be redeemed on</Text>
          <VSpacer size="3" />
          <Text category="body-medium" sx={{ color: theme.palette.text.secondary }}>
            {getProductRedeemText()}
          </Text>
          <VSpacer size="6" />
        </Stack>
      )}
    </Dialog>
  );
};
