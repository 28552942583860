import { HSpacer, TextBadge } from '@/components/DesignSystem';
import { ApiAccountGroupCustomer } from '@api/interfaces';
import { Stack, useTheme } from '@mui/material';
import { AccountGroupCustomerRole } from '@shared/enums';
import React from 'react';

interface CustomerRoleBadgeProps {
  customerRole: AccountGroupCustomerRole,
  linkedAccounts: ApiAccountGroupCustomer[],
}
export const CustomerRoleBadge = ({
  customerRole,
  linkedAccounts,
}: CustomerRoleBadgeProps) => {
  const theme = useTheme();
  const hasPartnerLinkedAccounts = linkedAccounts.some(
    (linked) => linked.customerRole === AccountGroupCustomerRole.Partner,
  );
  const hasSecondaryLinkedAccounts = linkedAccounts.some(
    (linked) => linked.customerRole === AccountGroupCustomerRole.Secondary,
  );
  const isPartnerOnlyGroup = hasPartnerLinkedAccounts && !hasSecondaryLinkedAccounts;
  const PartnerBadge = () => (
    <TextBadge
      backgroundColor="#E5D1FF"
      text="PARTNER ACCOUNT"
      textColor={theme.palette.text.primary}
    />
  );

  const PrimaryBadge = () => (
    <TextBadge
      backgroundColor="#CFE2FF"
      text="PRIMARY ACCOUNT"
      textColor={theme.palette.text.primary}
    />
  );

  if (customerRole === AccountGroupCustomerRole.Primary) {
    if (isPartnerOnlyGroup) {
      return <PartnerBadge />;
    } else if (hasPartnerLinkedAccounts) {
      return (
        <Stack direction="row">
          <PrimaryBadge />
          <HSpacer size="3" />
          <PartnerBadge />
        </Stack>
      );
    } else {
      return <PrimaryBadge />;
    }
  } else if (customerRole === AccountGroupCustomerRole.Secondary) {
    return (
      <TextBadge
        backgroundColor="#B2F2F0"
        text="SECONDARY ACCOUNT"
        textColor={theme.palette.text.primary}
      />
    );
  } else if (customerRole === AccountGroupCustomerRole.Partner) {
    return <PartnerBadge />;
  }
  return null;
};
