import { Button, Switch, Text, VSpacer } from '@/components/DesignSystem';
import { CustomerRoleChip } from '@/components/shared/CustomerRoleChip';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { AccountGroupCustomerApi } from '@/utilities/api/AccountGroupCustomerApi';
import { ApiAccountGroupCustomer } from '@api/interfaces';
import { Stack } from '@mui/system';
import { AccountGroupCustomerRole } from '@shared/enums';
import { formatPhoneNumber } from '@shared/utilities';
import { getAccountName, getFormattedAddress } from '@shared/utilities/UserUtilities';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface LinkedAccountCardProps {
  account: ApiAccountGroupCustomer,
}

export const LinkedAccountCard = ({
  account,
}: LinkedAccountCardProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  if (!account.customer) {
    return null;
  }

  const formattedAddress = getFormattedAddress(account.customer);
  const formattedPhoneNumber = account.customer.telephone
    && formatPhoneNumber(account.customer.telephone, 'paren');

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      key={account.id}
      py="16px"
    >
      <Stack>
        <Text category="title-medium">{getAccountName(account.customer)}</Text>
        <Stack direction="row">
          <Text category="body-medium">{formattedAddress}</Text>
          { !!formattedAddress && !!formattedPhoneNumber && (
            <Stack px="4px">•</Stack>
          )}
          <Text category="body-medium">{formattedPhoneNumber}</Text>
        </Stack>
        <VSpacer size="3" />
        <CustomerRoleChip customerRole={account.customerRole} />
        {account.customerRole !== AccountGroupCustomerRole.Secondary && (
          <>
            <VSpacer size="4" />
            <Switch
              checked={account.sendNotifications}
              label={
                <Text category="title-small" ml="12px">Notifications on</Text>
              }
              onClick={async () => {
                await AccountGroupCustomerApi.update(
                  account.id,
                  { sendNotifications: !account.sendNotifications },
                );
                void queryClient.invalidateQueries(QueryKeys.GET_LINKED_ACCOUNTS);
              }}
              testID={`${account.id}-notifications-switch`}
            />
          </>
        )}
      </Stack>
      <Button
        onClick={() => (
          navigate(Routes.CUSTOMER_DETAILS.replace(':id', account.customerId))
        )}
        testID={`${account.id}-view-account-button`}
        variant="outlined"
      >
        View account
      </Button>
    </Stack>
  );
};
