import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { BenefitCard } from '@/pages/CustomerDashboard/Benefits/BenefitCard';
import { HowToEarnPointsModal } from '@/pages/CustomerDashboard/Benefits/HowToEarnPointsModal';
import {
  ManufacturerPromotionsPointsEarnedCard,
} from '@/pages/CustomerDashboard/Benefits/ManufacturerPromotionsPointsEarnedCard';
import {
  ProgramPointsEarnedCard,
} from '@/pages/CustomerDashboard/Benefits/ProgramPointsEarnedCard';
import { DashboardModal } from '@/pages/CustomerDashboard/DashboardModal';
import { formatAmount } from '@/pages/CustomerDashboard/helpers';
import { TierImage } from '@/pages/CustomerDashboard/TierImage';
import { ResolvedTier } from '@api/data/RewardsProgramData';
import { ApiCategory, ApiManufacturer, ApiPromotion, ApiRetailer } from '@api/interfaces';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import { Stack, useTheme } from '@mui/material';
import { groupBy } from '@shared/utilities';
import React, { useState } from 'react';

interface TierBenefitsModalProps {
  brandColor: string,
  categories: ApiCategory[],
  hasTierGraphics: boolean,
  onClose: () => void,
  manufacturers: ApiManufacturer[],
  program: ApiRewardsProgram,
  promotions: ApiPromotion[],
  retailer: ApiRetailer,
  tier: ResolvedTier,
}

export const TierBenefitsModal = ({
  brandColor,
  categories,
  hasTierGraphics,
  manufacturers,
  onClose,
  program,
  promotions,
  retailer,
  tier,
}: TierBenefitsModalProps) => {
  const theme = useTheme();
  const [showHowToEarnModal, setShowHowToEarnModal] = useState(false);
  const categoryHurdleText = tier.minimumSegments > 0
    ? `hurdle ${tier.minimumSegments} ${tier.minimumSegments === 1 ? 'category' : 'categories'}`
    : null;
  const spendHurdleText = tier.minimumDollars > 0
    ? `spend ${formatAmount(tier.minimumDollars)}`
    : null;

  const getHurdleText = () => {
    let text = `To reach ${tier.name}`;
    if (spendHurdleText) {
      text += ` ${spendHurdleText}`;
    }
    if (spendHurdleText && categoryHurdleText) {
      text += ' &';
    }
    if (categoryHurdleText) {
      text += ` ${categoryHurdleText}`;
    }
    return text;
  };

  const groupedPromotionsByManufacturer: ApiPromotion[][] = [];

  const retailerPromotions = promotions.filter(
    (promotion) => !promotion.manufacturerId
      && promotion.showInDashboardTable,
  );

  const manufacturerIds = manufacturers.map((manufacturer) => manufacturer.id);
  const manufacturerPromotions = promotions.filter(
    (promotion) => !!promotion.manufacturerId
      && promotion.showInDashboardTable
      && manufacturerIds.includes(promotion.manufacturerId),
  );
  const promotionsByManufacturer = groupBy(
    manufacturerPromotions,
    (promotion) => promotion.manufacturerId!,
  );
  const sortByManufacturerName = (
    a: string,
    b: string,
    promotions: { [manufacturerId: string] : ApiPromotion[] },
  ): number => {
    return promotions[a][0].manufacturer!.name!.localeCompare(promotions[b][0].manufacturer!.name!);
  };
  const sortedManufacturerIdKeys = Object.keys(promotionsByManufacturer).sort(
    (a, b) => sortByManufacturerName(a, b, promotionsByManufacturer),
  );
  sortedManufacturerIdKeys.forEach((id) => {
    groupedPromotionsByManufacturer.push(promotionsByManufacturer[id]);
  });

  return (
    <DashboardModal
      onClose={onClose}
      open
      testID="tier-benefits-modal"
      title={`${tier.name} Tier Benefits`}
    >
      <Stack alignItems="center">
        {hasTierGraphics && (
          <>
            <TierImage hasTierGraphics={hasTierGraphics} tier={tier} />
            <HSpacer size="3"/>
          </>
        )}
        {(!!spendHurdleText || !!categoryHurdleText) && (
          <>
            <VSpacer size="3" />
            <Text category="body-medium">
              {getHurdleText()}
            </Text>
          </>
        )}
        <VSpacer size="5" />
      </Stack>
      {!!tier.benefits.length && (
        <Stack bgcolor="#f6f6f6" gap="16px" p="20px 16px">
          {tier.benefits.map((benefit) => (
            <BenefitCard
              benefit={benefit}
              brandColor={'white'}
              hasTierGraphics={hasTierGraphics}
              key={benefit.id}
              testID={`tier-benefits-modal-benefit-card-${benefit.id}`}
              tierName={tier.name}
            />
          ))}
        </Stack>
      )}
      <Stack bgcolor={theme.palette.background.default} p="24px 16px">
        <Text category="headline-small">Reward Points</Text>
        <VSpacer size="3" />
        <Button
          onClick={() => setShowHowToEarnModal(true)}
          sx={{
            bgcolor: brandColor,
            color: 'white',
            width: 'fit-content',
            '&:hover': { bgcolor: brandColor },
          }}
          testID="earn-points-button"
        >
          How do I earn these points?
        </Button>
        <VSpacer size="7" />
        <ProgramPointsEarnedCard
          categories={categories}
          retailer={retailer}
          retailerPromotions={retailerPromotions}
          testID={`${tier.name}-award-points-earned`}
          tier={tier}
        />
        {!!groupedPromotionsByManufacturer.length && (
          <>
            <VSpacer size="6" />
            {groupedPromotionsByManufacturer.map((promotions) => (
              <>
                <ManufacturerPromotionsPointsEarnedCard
                  key={promotions[0].manufacturerId}
                  manufacturer={manufacturers.find(
                    (manufacturer) => manufacturer.id === promotions[0].manufacturerId,
                  )!}
                  promotions={promotions}
                  testID={`${promotions[0].manufacturerId}-manufacturer-award-points-earned`}
                />
                <VSpacer size="6" />
              </>
            ))}
          </>
        )}
      </Stack>
      {showHowToEarnModal && (
        <HowToEarnPointsModal
          grantBoth={program.grantBothProgramAndPromotionPoints}
          onClose={() => setShowHowToEarnModal(false)}
          retailer={retailer}
        />
      )}
    </DashboardModal>
  );
};
