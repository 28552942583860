import { Divider, Stack, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Button, Card, HSpacer, Text, VSpacer } from "@/components/DesignSystem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  CategoryExclusionsModal,
} from "@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/CategoryExclusionsModal";
import CloseIcon from "@mui/icons-material/Close";
import { ApiCategory } from "@api/interfaces";


interface TierExclusionsSectionProps {
  categoryIdsExcludedFromSegmentSpend?: string[],
  categoryIdsExcludedFromTierSpend?: string[],
  readonly?: boolean,
  selectedCategories: ApiCategory[],
  onSave: (exclusions: {
    categoryIdsExcludedFromSegmentSpend?: string[],
    categoryIdsExcludedFromTierSpend?: string[],
  }) => void,
}

export const TierExclusionsSection = ({
  categoryIdsExcludedFromSegmentSpend,
  categoryIdsExcludedFromTierSpend,
  readonly,
  selectedCategories,
  onSave,
}: TierExclusionsSectionProps) => {
  const [showTierExclusionsModal, setShowTierExclusionsModal] = useState(false);
  const [showSegmentExclusionsModal, setShowSegmentExclusionsModal] = useState(false);

  const theme = useTheme();

  const CategoryChip = ({ categoryId, onDelete } : {
    categoryId: string,
    onDelete: () => void,
  }) => {
    const category = selectedCategories.find(({ id }) => id === categoryId);
    if (!category) {
      return null;
    }
    let parentName : string | undefined;
    if (category.parentId) {
      parentName = selectedCategories.find(({ id }) => id === category.parentId)?.name;
    }
    return (
      <Button
        color="inherit"
        endIcon={!readonly && <CloseIcon />}
        key={categoryId}
        {...(!readonly ? { onClick: onDelete } : {})}
        sx={{ borderRadius: "8px" }}
        testID={`${categoryId}-segment-spend-exclusion`}
        variant="outlined"
      >
        {parentName ? `${parentName}: ${category.name}` : category.name}
      </Button>
    );
  };

  return (
    <Stack>
      <VSpacer size="5"/>
      <Divider/>
      <VSpacer size="5"/>
      <Text category="title-medium">
        Tier exclusions (optional)
      </Text>
      <VSpacer size="5"/>
      <Card
        sx={{ backgroundColor: theme.palette.background.default, borderRadius: '8px' }}
        testID="tier-exclusions"
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack>
            <Text category="title-medium">
              Categories excluded from min. spend
            </Text>
            <VSpacer size="2"/>
            {!!categoryIdsExcludedFromTierSpend?.length && (
              <>
                <VSpacer size="3" />
                <Stack direction="row" gap="16px" sx={{ flexWrap: 'wrap' }}>
                  {categoryIdsExcludedFromTierSpend
                    .filter(id => !selectedCategories.find(({ parentId }) => parentId === id))
                    .map(categoryId => (
                      <CategoryChip
                        categoryId={categoryId}
                        key={categoryId}
                        onDelete={() => {
                          const category = selectedCategories.find(({ id }) => id === categoryId);
                          onSave({
                            categoryIdsExcludedFromTierSpend:
                              categoryIdsExcludedFromTierSpend?.filter(
                                id => id !== categoryId && id !== category?.parentId,
                              ),
                          });
                        }}
                      />
                    ))
                  }
                </Stack>
              </>
            )}
            {!categoryIdsExcludedFromTierSpend?.length && (
              <Text category="body-medium">
                None
              </Text>
            )}
          </Stack>
          <HSpacer size="7" />
          <Button
            color="inherit"
            disabled={readonly}
            endIcon={<ArrowDropDownIcon/>}
            onClick={() => setShowTierExclusionsModal(true)}
            testID="tier-spend-exclusions"
            variant="outlined"
          >
            Select
          </Button>
        </Stack>
        <VSpacer size="5"/>
        <Divider/>
        <VSpacer size="5"/>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack>
            <Text category="title-medium">
              Categories excluded from min. categories
            </Text>
            <VSpacer size="2"/>
            {!!categoryIdsExcludedFromSegmentSpend?.length && (
              <>
                <VSpacer size="3" />
                <Stack direction="row" gap="16px" sx={{ flexWrap: 'wrap' }}>
                  {categoryIdsExcludedFromSegmentSpend
                    .filter(id => !selectedCategories.find(({ parentId }) => parentId === id))
                    .map(categoryId => (
                      <CategoryChip
                        categoryId={categoryId}
                        key={categoryId}
                        onDelete={() => {
                          const category = selectedCategories.find(({ id }) => id === categoryId);
                          onSave({
                            categoryIdsExcludedFromSegmentSpend:
                              categoryIdsExcludedFromSegmentSpend?.filter(
                                id => id !== categoryId && id !== category?.parentId,
                              ),
                          });
                        }}
                      />
                    ))
                  }
                </Stack>
              </>
            )}
            {!categoryIdsExcludedFromSegmentSpend?.length && (
              <Text category="body-medium">
                None
              </Text>
            )}
          </Stack>
          <HSpacer size="7" />
          <Button
            color="inherit"
            disabled={readonly}
            endIcon={<ArrowDropDownIcon/>}
            onClick={() => setShowSegmentExclusionsModal(true)}
            testID="category-spend-exclusions"
            variant="outlined"
          >
            Select
          </Button>
        </Stack>
      </Card>
      {showTierExclusionsModal && (
        <CategoryExclusionsModal
          categories={selectedCategories}
          currentSelections={categoryIdsExcludedFromTierSpend}
          exclusionType="spend"
          onAccept={(categoryIds) => {
            onSave({
              categoryIdsExcludedFromTierSpend: categoryIds,
            });
            setShowTierExclusionsModal(false);
          }}
          onClose={() => setShowTierExclusionsModal(false)}
        />
      )}
      {showSegmentExclusionsModal && (
        <CategoryExclusionsModal
          categories={selectedCategories}
          currentSelections={categoryIdsExcludedFromSegmentSpend}
          exclusionType="categories"
          onAccept={(categoryIds) => {
            onSave({
              categoryIdsExcludedFromSegmentSpend: categoryIds,
            });
            setShowSegmentExclusionsModal(false);
          }}
          onClose={() => setShowSegmentExclusionsModal(false)}
        />
      )}
    </Stack>
  );
};
