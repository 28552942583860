import { Color } from '@/themes/MUITheme/palette';
import { Typography } from '@/themes/MUITheme/typography';
import { Category } from '@/themes/variant-interfaces/Category';
import {
  Stack,
  SxProps,
  Theme,
  Typography as MuiTypography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

export interface TextProps extends TypographyProps {
  breakWord?: boolean,
  children?: ReactNode,
  category?: Category,
  color?: Color,
  disabled?: boolean,
  fontSize?: number | string,
  fontWeight?: number | string,
  lineThrough?: boolean,
  multiline?: boolean,
  testID?: string,
  upperCase?: boolean,
}

export const Text = ({
  breakWord,
  children,
  category = "body-medium",
  color,
  disabled = false,
  fontSize,
  fontWeight,
  lineThrough = false,
  multiline = false,
  sx,
  testID,
  upperCase = false,
  ...rest
}: TextProps) => {
  const theme = useTheme();
  if (multiline && typeof children === 'string') {
    const lines = children.split('\n');
    return (
      <Stack>
        {lines.map((line, idx) => {
          if (line.trim() === '') {
            return (
              <Text
                key={idx}
                sx={{ height: Typography[category].lineHeight }}
                testID={`${testID}-line-${idx}`}
              />
            );
          }
          return (
            <Text
              breakWord={breakWord}
              category={category}
              disabled={disabled}
              fontSize={fontSize}
              fontWeight={fontWeight}
              key={idx}
              lineThrough={lineThrough}
              testID={`${testID}-line-${idx}`}
              upperCase={upperCase}
              {...rest}
            >
              {line}
            </Text>
          );
        })}
      </Stack>
    );
  }

  return (
    <MuiTypography
      color={disabled ? theme.palette.text.disabled : `${color}.main`}
      data-testid={testID}
      fontSize={fontSize ?? Typography[category]?.fontSize}
      fontWeight={fontWeight ?? Typography[category]?.fontWeight}
      letterSpacing={Typography[category]?.letterSpacing}
      lineHeight={Typography[category]?.lineHeight}
      sx={{
        textTransform: (category === "overline" || upperCase ? "uppercase" : ''),
        textDecoration: (lineThrough ? "line-through" : ""),
        ...(breakWord && { wordBreak: "break-word" }),
        ...sx,
      } as SxProps<Theme>}
      {...rest}
    >
      {children}
    </MuiTypography>
  );
};
