import { AppConfig } from '@/constants/AppConfig';
import { AuthApi } from '@/utilities/api/AuthApi';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { UserApi } from '@/utilities/api/UserApi';
import { getUserToken } from '@/utilities/Cookies';
import { LocalAccountList } from '@/utilities/LocalAccountList';
import { UserEndpoint } from '@api/endpoints';
import { ApiRetailer } from '@api/interfaces';
import { ApiUser } from '@api/interfaces/ApiUser';
import { isInternalUser, isRetailerUser, isValidPhoneNumber } from '@shared/utilities';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

export enum SessionState {
  UNKNOWN,
  LOGGED_OUT,
  LOGGED_IN,
}

interface LogoutOptions {
  removeFromAccountSwitcher?: boolean
}

export interface IAuthenticationContext {
  authenticate: (
    email: string,
    password: string,
  ) => Promise<void>,
  isInternal: boolean,
  isRetailer: boolean,
  logout: (options?: LogoutOptions) => Promise<void>,
  preferredLocationId?: string,
  refreshUser: () => Promise<ApiUser | undefined>,
  retailer: ApiRetailer | undefined,
  sessionState: SessionState,
  setPreferredLocationId: (id: string) => void
  updateUser: (userData: Partial<UserEndpoint.Update.Request>) => Promise<ApiUser | undefined>,
  user?: ApiUser,
  validateOtp: (
    username: string,
    code: string,
  ) => Promise<void>,
}

export const AuthenticationContext = React.createContext<IAuthenticationContext>(
  {} as IAuthenticationContext,
);

const { Provider } = AuthenticationContext;

export const AuthenticationProvider = (props: { children: ReactNode }) => {
  const [user, setUser] = useState<ApiUser>();
  const [retailer, setRetailer] = useState<ApiRetailer>();
  const [isInternal, setIsInternal] = useState(false);
  const [isRetailer, setIsRetailer] = useState(false);
  const [sessionState, setSessionState] = useState(SessionState.UNKNOWN);

  // We need to store this in memory in case a user navigates to other pages
  // before creating their account. Arguably this is a good candidate for some
  // sort of AppContext, but storing it here for now to save time.
  const [preferredLocationId, setPreferredLocationId] = useState<string>();

  const clearUserContext = () => {
    setIsRetailer(false);
    setIsInternal(false);
    setUser(undefined);
    setRetailer(undefined);
    setSessionState(SessionState.LOGGED_OUT);
  };

  const updateUserContext = async (user: ApiUser) => {
    setUser(user);
    setIsInternal(isInternalUser(user));
    if (isRetailerUser(user) && user.retailerId) {
      const retailer = await HierarchyOfRetailersApi.getRetailerById(user.retailerId);
      setIsRetailer(true);
      setRetailer(retailer);
    } else {
      setIsRetailer(false);
      setRetailer(undefined);
    }
    const userToken = getUserToken();
    if (userToken) {
      LocalAccountList.set(user, userToken);
    }
    setSessionState(SessionState.LOGGED_IN);
  };

  const checkLoggedInStatus = async () => {
    try {
      const user = await UserApi.me();
      await updateUserContext(user);
      return user;
    } catch (error) {
      if (error instanceof DetailedApiError && error.code === '401') {
        await AuthApi.logout();
      }
      clearUserContext();
    }
  };

  useEffect(() => {
    void checkLoggedInStatus();
  }, []);

  const authenticate = async (email: string, password: string) => {
    const user = await AuthApi.login({ email, password });
    await updateUserContext(user);
  };

  const updateUser = async (userData: UserEndpoint.Update.Request) => {
    if (!user?.id) {
      return;
    }
    const updatedUser = await UserApi.update(user.id, {
      ...userData,
      postal: userData.postal || undefined,
    });
    setUser(updatedUser);
    return updatedUser;
  };

  const logout = async (options: LogoutOptions = {}) => {
    const {
      removeFromAccountSwitcher = true,
    } = options;
    await AuthApi.logout();
    if (AppConfig.env.test && removeFromAccountSwitcher) {
      LocalAccountList.remove(user!.id);
    }
    clearUserContext();
  };

  const validateOtp = async (
    username: string,
    code: string,
  ) => {
    const isTelephone = isValidPhoneNumber(username.replace(/[() -]/g, ""), AppConfig.env.test);
    let validateOtpRequest: UserEndpoint.ValidateOtp.Request;
    if (isTelephone) {
      validateOtpRequest = {
        code,
        telephone: username.replace(/[() -]/g, ""),
      };
    } else {
      validateOtpRequest = {
        email: username,
        code,
      };
    }
    const user = await AuthApi.validateOtp(validateOtpRequest);
    if (user) {
      await updateUserContext(user);
    } else {
      throw new Error('Error logging in');
    }
  };

  if (sessionState === SessionState.UNKNOWN ||
    (sessionState === SessionState.LOGGED_IN && isRetailer && !retailer)
  ) {
    return null;
  }

  return (
    <Provider value={{
      authenticate,
      isInternal,
      isRetailer,
      logout,
      preferredLocationId,
      refreshUser: checkLoggedInStatus,
      retailer,
      sessionState,
      setPreferredLocationId,
      updateUser,
      user,
      validateOtp,
    }}
    >
      {props.children}
    </Provider>
  );
};

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error('useAuthentication must be used within an AuthenticationProvider');
  }
  return context as IAuthenticationContext;
};
