import { formatCurrency } from './StringUtils';

export const getLoyaltyPointsText = (
  promotion: { dollarCap: number | null, pointCap: number | null },
) => {
  return promotion.dollarCap
    ? `${formatCurrency(promotion.dollarCap)} total liability is ${promotion.pointCap} points`
    : `${promotion.pointCap} points`;
};

export const getPointsCapProgressText = (
  promotion: { pointsAwarded: number | null, pointCap: number | null },
) => {
  if (!promotion.pointCap) {
    return '';
  }
  const percent = ((promotion.pointsAwarded ?? 0) / promotion.pointCap) * 100;
  const formattedPercent = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(percent);
  return `${formattedPercent}% of points cap reached`;
};
