import { Card, Chip, HSpacer, SideSheet, Text, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { RetailerLocationApi } from '@/utilities/api/RetailerLocationApi';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { ExpandMore, Star } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { formatDateOnly, roundToFixed } from '@shared/utilities';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

interface PromotionsSideSheetProps {
  onClose: () => void,
  promotions: ApiPromotion[],
  promotionTargetUserType: PromotionTargetUserType,
}

export const PromotionsSideSheet = ({
  onClose,
  promotions,
  promotionTargetUserType,
}: PromotionsSideSheetProps) => {
  const locationIds = [...new Set(promotions.map(({ locationIds }) => locationIds).flat())];
  const { data: locationIdToName } = useQuery(
    [QueryKeys.GET_LOCATIONS, locationIds],
    async () => {
      const locations = await RetailerLocationApi.list({
        id: locationIds,
        limit: 100,
      });
      return new Map(locations.data.map((location) => [location.id, location.name]));
    },
  );

  const showLocations = !!locationIdToName
    && promotionTargetUserType === PromotionTargetUserType.Farmer;

  return (
    <SideSheet
      hideScrollbar
      isBorderless
      onClose={onClose}
      onOpen={() => null}
      open
      testID="promotions-side-sheet"
      title={promotionTargetUserType === PromotionTargetUserType.Farmer
        ? 'Farmer Rewards' : 'Active Sales Incentives'}
      width="425px"
    >
      <Stack gap="16px" px="16px">
        {promotions?.map((promotion) => (
          <PromotionCard
            key={promotion.id}
            locationNames={showLocations ? (
              promotion.locationIds.map((id) => locationIdToName.get(id)) as string[]
            ) : undefined}
            promotion={promotion}
          />
        ))}
      </Stack>
    </SideSheet>
  );
};

interface PromotionCardProps {
  locationNames?: string[],
  promotion: ApiPromotion,
}

const PromotionCard = ({
  locationNames,
  promotion,
}: PromotionCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card testID={`promotion-card-${promotion.id}`}>
      <Stack>
        <Text category="body-large">
          {promotion.name}
        </Text>
        <VSpacer size="3" />
        <Stack direction="row">
          <Star color="primary" sx={{ height: '16px', width: '16px' }} />
          <HSpacer size="2" />
          <Text category="label-medium">
            {Number(roundToFixed(promotion.value, 2))} points
          </Text>
        </Stack>
        <VSpacer size="5" />
        {locationNames && (
          <>
            <Stack direction="row" flexWrap="wrap" gap="8px" rowGap="8px">
              {locationNames?.map((name) => (
                <Chip
                  key={name}
                  label={name}
                  sx={{
                    backgroundColor: '#FFFFFFCC',
                    color: '#141414',
                  }}
                  testID={`promotion-card-${promotion.id}-location-${name}`}
                />
              ))}
            </Stack>
            <VSpacer size="6" />
          </>
        )}
        <Accordion
          disableGutters
          expanded={isExpanded}
          sx={{ backgroundColor: 'inherit' }}
          variant="outlined"
        >
          <AccordionSummary expandIcon={<ExpandMore />} onClick={() => setIsExpanded(!isExpanded)}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-start"
            >
              <Text category="body-medium">
                Description
              </Text>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Text category="body-medium">
              {promotion.description}
            </Text>
          </AccordionDetails>
        </Accordion>
        <VSpacer size="4" />
        <Stack alignItems="center">
          <Text category="label-medium">
            {promotion.endDate < formatDateOnly(new Date()) ? 'Expired' : 'Expires'}
            {' '}
            {DateTime.fromISO(promotion.endDate).toLocaleString(DateTime.DATE_MED)}
          </Text>
        </Stack>
      </Stack>
    </Card>
  );
};

