import { Button, HSpacer, NumericInput, Text, VSpacer } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { CreateFarmerAccountEventType, logEvent } from '@/utilities/Analytics';
import { AuthApi } from '@/utilities/api/AuthApi';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { UserApi } from '@/utilities/api/UserApi';
import { UserEndpoint } from '@api/endpoints';
import { Stack } from '@mui/material';
import { ApiErrors } from '@shared/enums';
import { isValidPhoneNumber } from '@shared/utilities';
import React, { KeyboardEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface OtpFormProps {
  isRegister?: boolean,
  lastFour: string,
  onCancel?: () => void,
  onError?: (error: DetailedApiError) => void,
  sendOtp?: boolean,
  userToCreate?: Omit<UserEndpoint.Create.FarmerUserCreate, 'code'>,
  username: string,
}

const OtpForm = ({
  isRegister = false,
  lastFour,
  onCancel,
  onError,
  sendOtp = true,
  username,
  userToCreate,
}: OtpFormProps) => {
  const [otpValues, setOtpValues] = useState<(number | undefined)[]>([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const otpFieldsRef: RefObject<(HTMLDivElement | null)[]> = useRef([]);
  const [errorMessage, setErrorMessage] = useState<{ title?: string, message: string }>();
  const { refreshUser, validateOtp } = useAuthentication();
  const navigate = useNavigate();
  const [otpResendCounter, setOtpResendCounter] = useState(0);
  const [hideResend, setHideResend] = useState(false);
  const isTelephone = isValidPhoneNumber(username.replace(/[() -]/g, ""), AppConfig.env.test);

  useEffect(() => {
    if (sendOtp) {
      sendOtpCode();
    } else {
      setOtpResendCounter(30);
    }
    resetForm();
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (otpResendCounter > 0) {
      timer = setInterval(() => setOtpResendCounter(otpResendCounter - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [otpResendCounter]);

  const resetForm = () => {
    setTimeout(() => {
      otpFieldsRef?.current?.[0]?.focus();
    }, 100);
    setOtpValues([undefined, undefined, undefined, undefined, undefined, undefined]);
  };

  const { mutate: authenticateUser, isLoading: isAuthenticating } = useMutation(
    ({ otpValue }: { otpValue: string }) => (
      validateOtp(username, otpValue)
    ),
    {
      onError: (err: { message: string }) => {
        if (err.message === ApiErrors.inactiveAccount) {
          setErrorMessage({ title: 'Inactive account',  message: err.message });
        } else {
          setErrorMessage({ message: "Incorrect OTP" });
        }
        resetForm();
      },
      onSuccess: async () => {
        await refreshUser();
        navigate(Routes.HOMEPAGE, { replace: true });
      },
      retry: false,
    },
  );

  const { mutate: createUser, isLoading: isCreatingUser } = useMutation(
    async (code: string) => {
      if (!userToCreate) {
        throw new DetailedApiError('', 'Nothing to Create', {});
      }
      return UserApi.create({
        businessName: userToCreate.businessName,
        code,
        countyId: userToCreate.countyId,
        email: userToCreate.email,
        firstName: userToCreate.firstName,
        lastName: userToCreate.lastName,
        // preferredRetailerId: userToCreate.preferredRetailerId,
        state: userToCreate.state,
        telephone: userToCreate.telephone,
      });
    },
    {
      onError: (error: DetailedApiError) => {
        if (error.message === ApiErrors.inactiveAccount) {
          setErrorMessage({ message: "Incorrect OTP" });
        } else {
          onError?.(error);
          setErrorMessage({ message: error.message || "An error has occurred" });
        }
      },
      onSuccess: async () => {
        logEvent(CreateFarmerAccountEventType.CompleteCreateAccount);
        await refreshUser();
        navigate(Routes.HOMEPAGE, { replace: true });
      },
    },
  );

  const { mutate: sendOtpCode, isLoading: isSendingOtp } = useMutation(
    () => AuthApi.sendOtpCode(
      isTelephone ? { telephone: username.replace(/[() -]/g, "") } : { email: username },
    ),
    {
      onError: (err: DetailedApiError) => {
        setErrorMessage({ message: err.message });
        if (
          err.message === ApiErrors.userWithPhoneExists
          || err.message === ApiErrors.userWithPhoneDoesNotExist
        ) {
          setHideResend(true);
        }
        onError?.(err);
      },
      onSuccess: () => {
        setErrorMessage(undefined);
        setOtpResendCounter(30);
      },
      retry: false,
    },
  );

  const handleKeyboardEvent = async (
    event: KeyboardEvent,
    index: number,
  ) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      const isFirstInput = index === 0;
      if (!isFirstInput && otpFieldsRef?.current) {
        otpFieldsRef.current[index - 1]?.focus();
      }
    } else if (isFinite(Number(event.key))) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = Number(event.key);
      setOtpValues(newOtpValues);
      const isLastInput = (index + 1) === newOtpValues.length;
      if (isLastInput) {
        const otpValue = newOtpValues.join('');
        await isRegister ? createUser(otpValue) : authenticateUser({ otpValue });
      } else {
        if (otpFieldsRef?.current) {
          otpFieldsRef.current[index + 1]?.focus();
        }
      }
    }
  };

  return (
    <Stack alignItems="center" flexDirection="column">
      <Text category="headline-large">Verification Code</Text>
      <VSpacer size="3" />
      <Text category="body-large">
        A verification code has been sent to (XXX) XXX-{lastFour}
      </Text>
      <VSpacer size="7" />
      <VSpacer size="2" />
      {!!errorMessage && (
        <>
          <Text category="body-medium" color="error">
            {errorMessage.message}
          </Text>
          <VSpacer size="6" />
        </>
      )}
      {!hideResend &&
        <>
          <Stack flexDirection="row">
            {otpValues.map((value, index) => (
              <Stack flexDirection="row" key={index}>
                <NumericInput
                  decimals={0}
                  disabled={isAuthenticating || isSendingOtp}
                  error={!!errorMessage}
                  hiddenLabel
                  inputRef={(ref) => {
                    if (otpFieldsRef.current) {
                      otpFieldsRef.current[index] = ref;
                    }
                  }}
                  maxLength={1}
                  maxValue={9}
                  minValue={0}
                  onKeyUp={(event) => handleKeyboardEvent(event, index)}
                  showFixedDecimals={false}
                  sx={{ height: '56px', p: 0, textAlign: 'center', width: '56px' }}
                  testID={`${index}-otp-input`}
                  value={value}
                  variant="outlined"
                />
                <HSpacer size="3" />
              </Stack>
            ))}
          </Stack>
        </>
      }
      {!isSendingOtp && otpResendCounter > 0 && !hideResend &&
        <>
          <VSpacer size="7" />
          <Text category="p2">
            Resend SMS in {otpResendCounter}s
          </Text>
        </>
      }
      <VSpacer size="8" />
      <Stack direction="row">
        <Button
          onClick={onCancel}
          testID="otp-form-cancel-button"
          variant="outlined"
        >
          Cancel
        </Button>
        {!isSendingOtp && otpResendCounter === 0 && !hideResend &&
          <>
            <HSpacer size="4" />
            <Button
              disabled={isAuthenticating || isCreatingUser}
              onClick={() => {
                sendOtpCode();
              }}
              testID="resend-otp-counter"
            >
              Resend
            </Button>
          </>
        }
      </Stack>
    </Stack>
  );
};

export default OtpForm;
