import { version } from './version.json';

export const SharedConfig = {
  deployed: {
    aws: process.env.DEPLOYED === 'aws',
    local: !process.env.DEPLOYED,
  },
  maxPageLimit: Number(process.env.MAX_PAGE_LIMIT) || 1000,
  settings: {
    pricingRequestMaxExpirationDays: 7,
    pricingRequestMinExpirationDays: 1,
    pricingRequestStorefrontExpirationDays: 3,
    rewardsMaxRewardPercent: .1,
    rewardsMaxTiers: 4,
    rewardsMinProgramDurationInDays: 365,
  },
  shortUrlDomain: process.env.SHORT_URL_DOMAIN ?? 'loyalty.ag',
  sql: {
    database: process.env.SQL_DATABASE ?? 'bidding',
    host: process.env.SQL_HOST ?? 'localhost',
    password: process.env.SQL_PASSWORD ?? 'dev-password',
    port: Number(process.env.SQL_PORT) ?? 5432,
    readOnlyUser: process.env.RO_USER ?? 'readonlyuser',
    superDatabase: process.env.SQL_SUPER_DATABASE ?? 'postgres',
    superPassword: process.env.SQL_SUPER_PASSWORD ?? 'dev-password',
    superUser: process.env.SQL_SUPER_USER ?? 'devuser',
    user: process.env.SQL_USER ?? 'devuser',
  },
  version,
};
