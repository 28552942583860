import React, { useState } from "react";
import { Button, Checkbox, HSpacer, Modal, Text, VSpacer } from "@/components/DesignSystem";
import { ApiCategory } from "@api/interfaces";
import { Stack } from "@mui/system";
import { Divider } from "@mui/material";

interface CategoryExclusionsModalProps {
  categories: ApiCategory[],
  currentSelections?: string[],
  exclusionType: 'spend' | 'categories',
  onAccept: (categoryIds: string[]) => void,
  onClose: () => void,
}

export const CategoryExclusionsModal = ({
  categories,
  currentSelections,
  exclusionType,
  onAccept,
  onClose,
}: CategoryExclusionsModalProps) => {
  const parentCategories = categories?.filter(({ parentId }) => !parentId);

  const [selectedCategories, setSelectedCategories] = useState<Set<string>>(
    new Set(currentSelections),
  );
  return (
    <Modal
      acceptButton={() => (
        <Button
          onClick={() => {
            onAccept(Array.from(selectedCategories));
          }}
          testID="accept-exclusions-button"
        >
          Done
        </Button>
      )}
      onClose={onClose}
      open
      subline={`Select the categories or subcategories that are not eligible to
        count towards earning the 'minimum ${exclusionType}' goal for each tier.`}
      testID='exclude-from'
      title={`Exclude from minimum ${exclusionType}`}
      width={516}
    >
      {parentCategories.map(category => {
        const subcategories = categories.filter(({ parentId }) => parentId === category.id);
        return (
          <Stack key={category.id}>
            <Divider />
            <VSpacer size="5"/>
            <Stack direction="row">
              <HSpacer size="3" />
              <Checkbox
                checked={selectedCategories.has(category.id)}
                onChangeChecked={(checked) => {
                  setSelectedCategories((prevSet) => {
                    const newSet = new Set(prevSet);
                    const subcategoryIds = subcategories.map(s => s.id);
                    if (checked) {
                      subcategoryIds.forEach(id => {
                        newSet.add(id);
                      });
                      newSet.add(category.id);
                    } else {
                      subcategoryIds.forEach(id => {
                        newSet.delete(id);
                      });
                      newSet.delete(category.id);
                    }
                    return newSet;
                  });
                }}
                testID={`exclude-category-${exclusionType}-${category.id}`}
              >
                <Text category="body-large">
                  {category.name}
                </Text>
              </Checkbox>
            </Stack>
            {subcategories.map(subcategory => (
              <Stack key={subcategory.id} sx={{ marginLeft: '28px' }}>
                <VSpacer size="5"/>
                <Divider />
                <VSpacer size="5"/>
                <Stack direction="row">
                  <HSpacer size="3" />
                  <Checkbox
                    checked={selectedCategories.has(subcategory.id)}
                    onChangeChecked={(checked) => {
                      setSelectedCategories((prevSet) => {
                        const newSet = new Set(prevSet);
                        if (checked) {
                          newSet.add(subcategory.id);
                          if (subcategories.every(subcategory => newSet.has(subcategory.id))) {
                            newSet.add(category.id);
                          }
                        } else {
                          if (subcategory.parentId) {
                            newSet.delete(subcategory.parentId);
                          }
                          newSet.delete(subcategory.id);
                        }
                        return newSet;
                      });
                    }}
                    testID={`exclude-category-${exclusionType}-${subcategory.id}`}
                  >
                    <Text category="body-large">
                      {subcategory.name}
                    </Text>
                  </Checkbox>
                </Stack>
              </Stack>
            ))}
            <VSpacer size="5"/>
          </Stack>
        );
      })}
      <Divider />
    </Modal>
  );
};
