import {
  BottomSheet,
  Button,
  HSpacer,
  IconButton,
  MenuItem,
  SideSheet,
  SideSheetProps,
  Text,
  VSpacer,
} from "@/components/DesignSystem";

import { FilterSelector } from "@/components/DesignSystem/Toolbar/FilterSelector";
import {
  Filter,
  FilterSelection,
  FilterSelections,
} from "@/components/DesignSystem/Toolbar/interfaces";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { MobileOnly } from "@/components/shared/MobileOnly";
import { Box, Divider, ListItemText, Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface FilterSheetProps extends Omit<SideSheetProps, 'onOpen' | 'onClose'> {
  filters: Filter[],
  onApply?: (selections: FilterSelections) => void,
  onClose: () => void,
  selections: FilterSelections,
  title?: string,
  themeColor?: string,
}

export const FilterSheet = ({
  filters,
  onApply,
  onClose,
  open,
  selections,
  testID,
  title,
  themeColor,
}: FilterSheetProps) => {
  const [currentSelections, setCurrentSelections] =
    useState<FilterSelections>(selections);

  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});
  const [showMoreSections, setShowMoreSections] = useState<Record<string, boolean>>({});

  const toggleSection = (id: string) => {
    setOpenSections((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const onShowMoreClicked = (id: string) => {
    setShowMoreSections((prev) => ({ ...prev, [id]: true }));
  };

  useEffect(() => {
    setCurrentSelections(selections);
  }, [selections]);

  const handleSelectionChange = useCallback((filterId: string, selection: FilterSelection) => {
    const newSelections = _.cloneDeep(currentSelections);
    newSelections.set(filterId, selection);
    setCurrentSelections(newSelections);
  }, [currentSelections]);

  const handleClose = useCallback(() => {
    onClose?.();
    setOpenSections({});
    setShowMoreSections({});
    setCurrentSelections(selections);
  }, [onClose, selections]);

  const clearSelections = useCallback(() => {
    setCurrentSelections((prevSelections) => {
      const newSelections = new Map(prevSelections);
      for (const filter of filters) {
        newSelections.set(filter.id, new Set());
      }
      return newSelections;
    });
  }, [filters]);

  const renderContent = () => {
    const CollapsableFilter = ({ children, filter, isOpen, toggleOpen, totalSelected } : {
      children: ReactNode,
      filter: Filter,
      isOpen: boolean,
      toggleOpen: (open: boolean) => void,
      totalSelected: number,
    }) => {
      return <>
        <MenuItem
          onClick={() => toggleOpen(!isOpen)}
          sx={{ height: "56px", width: "100%" }}
          testID={`${testID}-collapsable-filter-${filter.id}`}
        >
          <ListItemText>{filter.label}</ListItemText>
          <Stack alignItems="center" direction="row">
            {!!totalSelected && (
              <Stack alignItems="center" sx={{
                backgroundColor: "#1890FF",
                borderRadius: 100,
                color: "white",
                height: "16px",
                width: "16px",
              }}>
                <Text category="label-small" >
                  {totalSelected}
                </Text>
              </Stack>
            )}
            {!!totalSelected && <HSpacer size="3" />}
            <IconButton
              style={{
                height: "32px",
                width: "32px",
                ...(isOpen && { backgroundColor: "#efefef" }),
              }}
              testID={`${testID}-${filter.id}-${isOpen ? "collapse" : "expand"}`}
            >
              {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
            </IconButton>
          </Stack>
        </MenuItem>
        {isOpen && children}
        {isOpen && <VSpacer size="3" />}
      </>;
    };

    return <Stack overflow="auto">
      {filters.map((filter) => {
        const selection = currentSelections.get(filter.id);

        const showMore =
          filters.length > 1 &&
          !showMoreSections[filter.id] &&
          (filter.selectionMethod === 'single-select'
            || filter.selectionMethod === 'multi-select') &&
          filter.options.length > 6;

        const filterSelector = <FilterSelector
          filter={filter}
          key={filter.id}
          onChange={(selection) => handleSelectionChange(filter.id, selection)}
          onShowMoreClicked={showMore ? () => onShowMoreClicked(filter.id) : undefined}
          selection={selection}
          testID={`${testID}-${filter.id}-selector`}
          themeColor={themeColor}
        />;
        if (filters.length > 1) {
          return <>
            <Divider style={{ margin: 0 }}/>
            <CollapsableFilter
              filter={filter}
              isOpen={openSections[filter.id] || false}
              key={filter.id}
              toggleOpen={() => toggleSection(filter.id)}
              totalSelected={selection?.size ?? 0}
            >
              {filterSelector}
            </CollapsableFilter>
          </>;
        }
        return filterSelector;
      })}
    </Stack>;
  };

  const MobileFooter = () => (
    <Box
      alignItems="center"
      display="flex"
      gap={2}
      justifyContent="space-between"
      minHeight={79}
      px={2}
    >
      <Button
        fullWidth
        onClick={clearSelections}
        testID={`${testID}-clear-button`}
        variant="outlined"
      >
        <Text category="label-large">
          {filters.length === 1 && filters[0].selectionMethod !== "multi-select"
            ? "Clear"
            : "Clear all"
          }
        </Text>
      </Button>
      <Button
        fullWidth
        onClick={() => {
          onApply?.(currentSelections);
          handleClose();
        }}
        {...(themeColor && { sx: { backgroundColor: themeColor } })}
        testID={`${testID}-apply-button`}
        variant="contained"
      >
        <Text category="label-large">
          Apply
        </Text>
      </Button>
    </Box>
  );

  return (
    <>
      <DesktopOnly>
        <SideSheet
          actionButton={(props) => (
            <Button
              {...props}
              onClick={() => {
                onApply?.(currentSelections);
                handleClose();
              }}
              testID={`${testID}-apply-button`}
            >
              Apply
            </Button>
          )}
          hideScrollbar
          onClose={handleClose}
          onOpen={() => setCurrentSelections(selections)}
          open={open}
          secondaryActionButton={(props) => (
            <Button
              {...props}
              onClick={clearSelections}
              testID={`${testID}-clear-button`}
            >
              Clear
            </Button>
          )}
          testID={testID}
          title="Filters"
        >
          {renderContent()}
        </SideSheet>
      </DesktopOnly>
      <MobileOnly>
        <BottomSheet
          footer={<MobileFooter />}
          onClose={handleClose}
          onOpen={() => setCurrentSelections(selections)}
          open={open}
          showPuller={true}
          testID={testID}
          title={title}
        >
          {renderContent()}
        </BottomSheet>
      </MobileOnly>
    </>
  );
};
