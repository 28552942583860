import { Chip } from '@/components/DesignSystem';
import { Color } from '@/themes/MUITheme/palette';
import { getStatuses, PromotionStatus } from '@/utilities/api/PromotionUtilities';
import { ApiPromotion } from '@api/interfaces';
import { Stack } from '@mui/material';
import React from 'react';
import { PromotionProcessingStatus } from '@shared/enums/PromotionProcessingStatus';

interface PromotionStatusChipProps {
  promotion: ApiPromotion,
}

const statusColors: { [key: string]: Color | undefined } = {
  [PromotionStatus.FUTURE]: 'info',
  [PromotionStatus.RUNNING]: 'success',
  [PromotionStatus.EXPIRED]: undefined,
  [PromotionProcessingStatus.Processing]: 'warning',
  [PromotionProcessingStatus.Failed]: 'error',
  [PromotionProcessingStatus.Timeout]: 'error',
};

export const PromotionStatusChip = ({
  promotion,
}: PromotionStatusChipProps) => {
  const statuses: (PromotionStatus | PromotionProcessingStatus)[] = getStatuses(promotion);
  if (promotion.processingStatus === PromotionProcessingStatus.Processing) {
    statuses.push(PromotionProcessingStatus.Processing);
  }
  if (promotion.processingStatus === PromotionProcessingStatus.Failed) {
    statuses.push(PromotionProcessingStatus.Failed);
  }
  if (promotion.processingStatus === PromotionProcessingStatus.Timeout) {
    statuses.push(PromotionProcessingStatus.Timeout);
  }
  return (
    <Stack direction="row" gap="8px">
      {statuses.map((status) => (
        <Chip
          color={statusColors[status]}
          key={status}
          label={status.toUpperCase()}
          rounded
          sx={{ fontWeight: 500, height: 20 }}
          testID={`promotion-status-${status}`}
        />
      ))}
    </Stack>
  );
};
