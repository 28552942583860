import { Button, Card, Chip, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';
import { AppConfig } from '@/constants/AppConfig';
import { useGetRetailerById } from '@/hooks/useHierarchyOfRetailers';
import EditIcon from '@mui/icons-material/Edit';
import Store from '@mui/icons-material/Store';
import { Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AddRetailer from './RetailerFormDialog';

const RetailerDetailsCard = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [isEditRetailer, setIsEditRetailer] = useState(false);

  const editRetailer = () => {
    setIsEditRetailer(true);
  };

  const { retailer } = useGetRetailerById(id ?? '');

  const imageUrl = retailer?.image && `${AppConfig.staticImageHost}/${retailer.image}`;

  return (
    <Card testID="retailer-details-card">
      <Stack direction="row">
        <Avatar
          alt={`${retailer?.name ?? 'retailer'} logo`}
          height={56}
          src={imageUrl ?? undefined}
          width={56}
        >
          {imageUrl ? null : (
            <Store sx={{ height: 42, width: 42 }} />
          )}
        </Avatar>
        <HSpacer size="5" />
        <Stack direction="column" spacing={0.7} width="100%">
          <Text category="overline" sx={{ color: theme.palette.text.secondary }}>
            ID# {retailer?.id}
          </Text>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Text category="headline-medium">
              {retailer?.name}
            </Text>
            <Button
              onClick={editRetailer}
              startIcon={<EditIcon />}
              testID='edit-details'
              variant='outlined'
            >
              Edit
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {!!retailer?.isErpEnabled && (
        <>
          <VSpacer size="3" />
          <Chip color="info" label="ERP Imported" testID="erp-imported-chip" />
        </>
      )}
      {isEditRetailer && (
        <AddRetailer
          initialValue={retailer}
          isAddRetailerOpen={isEditRetailer}
          setIsAddRetailerOpen={setIsEditRetailer}
        />
      )}
    </Card>
  );
};

export default RetailerDetailsCard;
