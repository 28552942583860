import { Button, Card, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { LocalOffer } from '@mui/icons-material';
import { Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { ProductPromotionsModal } from './ProductPromotionsModal';

type ProductListData = {
  externalId: string | null,
  id: string,
  manufacturerName?: string,
  name: string
}

interface ProductListItemProps {
  productListData: ProductListData,
  promotions: ApiPromotion[],
}

const ProductListItem = ({
  productListData,
  promotions,
}: ProductListItemProps) => {
  const { externalId, id, manufacturerName, name } = productListData;
  const [showViewProductPromotionsModal, setShowViewProductPromotionsModal] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Card elevation={4} testID={`product-list-item-${id}`}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            {!!manufacturerName && (
              <>
                <Text
                  category="overline"
                  sx={{ color: theme.palette.text.secondary }}
                  testID="product-manufacturer-name"
                >
                  {manufacturerName.toUpperCase()}
                </Text>
                <VSpacer size="1" />
              </>
            )}
            <Text category="body-xlarge" testID="product-name" >
              {name}
            </Text>
            <VSpacer size="1" />
            <Text
              category="body-medium"
              sx={{ color: theme.palette.text.secondary }}
              testID="product-id"
            >
              {externalId ? `ERP ID: ${externalId}` : `ID: ${id}`}
            </Text>
          </Stack>
          <Stack alignItems="center" direction="row" gap="24px">
            {!!promotions.length && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowViewProductPromotionsModal(true);
                }}
                size="small"
                square
                sx={{
                  boxShadow: 'none',
                  height: '24px',
                  padding: '14px 8px',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                testID="rewards-button"
                variant="outlined"
              >
                <LocalOffer
                  sx={{ color: theme.palette.text.primary, height: '12px', width: '12px' }}
                />
                <HSpacer size="2"/>
                <Text
                  category="label-small"
                  sx={{ color: theme.palette.text.primary }}
                >
                  View Promotions
                </Text>
              </Button>
            )}
          </Stack>
        </Stack>
      </Card>
      {showViewProductPromotionsModal && (
        <ProductPromotionsModal
          onClose={() => setShowViewProductPromotionsModal(false)}
          promotions={promotions}
        />
      )}
    </>
  );
};
export default ProductListItem;
