import { Toolbar } from "@/components/DesignSystem";
import { Filter, FilterResult } from "@/components/DesignSystem/Toolbar/interfaces";
import { useRetailerLocationFilter } from "@/hooks/useRetailerLocationFilter";

export const PromotionsToolbar = ({
  onChange,
  totalItems,
} : {
  onChange: (result: FilterResult) => void,
  totalItems?: number,
}) => {
  const retailerLocationFilter = useRetailerLocationFilter();
  const filters: Filter[] = [
    retailerLocationFilter,
    {
      id: 'show-expired',
      label: 'Show Expired',
      options: [
        { id: 'show-expired', label: 'Show Expired' },
      ],
      selectionMethod: 'boolean',
    },
  ];

  return (
    <Toolbar
      filters={filters}
      onChange={onChange}
      testID="promotions-toolbar-generic"
      totalItems={totalItems}
      totalUnit="promotion"
    />
  );
};