import { Button, Text } from '@/components/DesignSystem';
import { stringifyCount } from "@/constants/HierarchyOfRetailers";
import { QueryKeys } from '@/constants/QueryKeys';
import { useUserList } from '@/hooks/useUserList';
import {
  AddRecipientsModal,
} from '@/pages/Admin/HierarchyOfRetailers/Recipients/AddRecipientsModal';
import { RetailerRecipientApi } from '@/utilities/api/RetailerReceipientApi';
import { Stack } from '@mui/material';
import { UserType } from '@shared/enums';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { SharedConfig } from "@shared/SharedConfig";

interface AddRecipientsSectionProps {
  retailerId: string,
}

export const AddRecipientsSection = ({
  retailerId,
}: AddRecipientsSectionProps) => {
  const [showAddRecipientsModal, setShowAddRecipientsModal] = useState(false);

  const { users: retailerAdmins } = useUserList({
    limit: SharedConfig.maxPageLimit,
    retailerId,
    userType: [UserType.RetailerAdmin],
  });
  const { data: recipients } = useQuery(
    [QueryKeys.LIST_RETAILER_RECIPIENTS, retailerId],
    () => RetailerRecipientApi.list(retailerId),
  );

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{
        margin: '2rem 0px',
      }}
    >
      <Stack spacing={2}>
        <Text category='h6'>Admin Email Recipients</Text>
        <Text>{stringifyCount(recipients?.length, 'recipient', 'recipients')}</Text>
      </Stack>
      <Button
        disabled={!retailerAdmins?.data.length}
        onClick={() => setShowAddRecipientsModal(true)}
        testID="manage-recipients-button"
        variant="outlined"
      >
        Manage Recipients
      </Button>
      {showAddRecipientsModal && retailerAdmins && (
        <AddRecipientsModal
          addedRecipients={recipients ?? []}
          onClose={() => setShowAddRecipientsModal(false)}
          retailerAdminUsers={retailerAdmins.data}
          retailerId={retailerId}
        />
      )}
    </Stack>
  );
};
