import { Box, Stack } from "@mui/material";
import { Card, IconButton, Text, VSpacer } from "@/components/DesignSystem";
import Edit from "@mui/icons-material/Edit";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Add from "@mui/icons-material/Add";
import React from "react";

interface BrandAssetsCardProps {
  color?: string | null,
  onAddClicked: () => void,
  onEditClicked?: () => void,
  onRemoveClicked?: () => void,
  statusText?: string | null,
  title: string,
}

export const BrandAssetsCard = ({
  color,
  statusText,
  onAddClicked,
  onEditClicked,
  onRemoveClicked,
  title,
}: BrandAssetsCardProps) => {
  return (
    <Card
      cardContentStyle={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        padding: '16px',
        width: '100%',
      }}
      sx={{ height: '140px' }}
      testID={`${title}-card`}
    >
      <Stack>
        <Text category="title-medium">
          {title}
        </Text>
        {!!statusText && (
          <>
            <VSpacer size="2" />
            <Stack alignItems="center" direction="row" gap="4px">
              {!!color && (
                <Stack alignItems="center" height="24px" justifyContent="center" width="24px">
                  <Box bgcolor={color} borderRadius="2px" height="14px" width="14px" />
                </Stack>
              )}
              <Text
                category="body-medium"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {statusText}
              </Text>
            </Stack>
          </>
        )}
      </Stack>
      <Stack alignItems="center" direction="row" justifyContent="end">
        {(statusText && !!onEditClicked) && (
          <IconButton color="primary" onClick={onEditClicked} testID={`${title}-edit-button`}>
            <Edit />
          </IconButton>
        )}
        {(statusText && !!onRemoveClicked) && (
          <IconButton color="primary" onClick={onRemoveClicked} testID={`${title}-remove-button`}>
            <DeleteOutline />
          </IconButton>
        )}
        {!statusText && (
          <IconButton color="primary" onClick={onAddClicked} testID={`${title}-add-button`} variant="filled">
            <Add />
          </IconButton>
        )}
      </Stack>
    </Card>
  );
};
