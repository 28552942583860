import { QueryKeys } from '@/constants/QueryKeys';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { ProductApi } from '@/utilities/api/ProductApi';
import { useQuery } from 'react-query';
import { CategoryEndPoint, ManufacturerEndpoint, RetailerEndpoint } from "@api/endpoints";

export const useCategoryList = (query?: CategoryEndPoint.List.Query) => {
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_CATEGORY, query],
    () => ProductApi.getCategories(query),
  );
  const categoryList = response?.data;
  return {
    isLoading,
    categoryList,
  };
};

export const usePrimaryNutrient = () => {
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_PRIMARY_NUTRIENT],
    ProductApi.getPrimaryNutrient,
  );

  const primaryNutrientList = response?.data;
  return {
    isLoading,
    primaryNutrientList,
  };
};

export const useActiveIngredients = () => {
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_ACTIVE_INGREDIENTS],
    ProductApi.getActiveIngredients,
  );

  const activeIngredientsDataList = response;
  return {
    isLoading,
    activeIngredientsDataList,
  };
};

export const useManufacturerList = (query?: ManufacturerEndpoint.List.Query, options?: {
  enabled: boolean,
}) => {
  const { data: manufacturerList, isLoading } = useQuery(
    [QueryKeys.GET_MANUFACTURERS_LIST, query],
    () => ProductApi.getManufacturers(query),
    options,
  );
  return {
    isLoading,
    manufacturerList,
  };
};


export const useCropList = () => {
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_CROP],
    ProductApi.getCrop,
  );

  const cropsList = response?.data;
  return {
    isLoading,
    cropsList,
  };
};

export const usePracticeList = () => {
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_PRACTICE],
    ProductApi.getPractice,
  );

  const PracticeList = response?.data;
  return {
    isLoading,
    PracticeList,
  };
};


export const useUoMList = () => {
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_UOM],
    ProductApi.getUoM,
  );

  const UoMList = response?.data;
  return {
    isLoading,
    UoMList,
  };
};

export const usePackageSizeList = () => {
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_PACKAGE_SIZE],
    ProductApi.getPackageSize,
  );

  const PackageSizeList = response?.data;
  return {
    isLoading,
    PackageSizeList,
  };
};

export const useGetRetailerList = (
  query?: RetailerEndpoint.RetailerList.Query,
  options?: {
    enabled?: boolean,
    onSuccess?: (data: RetailerEndpoint.RetailerList.Response) => void,
  },
) => {
  const { data: retailerList, isLoading } = useQuery(
    [QueryKeys.ACTIVE_RETAILERS, query],
    () => HierarchyOfRetailersApi.getActiveRetailerLists(query ?? {}),
    options,
  );
  return {
    isLoading,
    retailerList,
  };
};
