import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { ApiPromotion } from '@api/interfaces';
import { Stack } from '@mui/material';
import { getLoyaltyPointsText, getPointsCapProgressText } from '@shared/utilities';
import React from 'react';

interface LoyaltyPointsCapProgressProps {
  promotion: ApiPromotion,
}

export const LoyaltyPointsCapProgress = ({
  promotion,
}: LoyaltyPointsCapProgressProps) => {
  if (!promotion.pointCap) {
    return null;
  }
  return (
    <>
      <Text category="title-small">
        Loyalty points cap
      </Text>
      <VSpacer size="2" />
      <Stack alignItems="center" direction="row">
        <Text category="body-small">
          {getLoyaltyPointsText(promotion)}
        </Text>
        <HSpacer size="2" />
        •
        <HSpacer size="2" />
        <Text category="body-small">
          {getPointsCapProgressText(promotion)}
        </Text>
      </Stack>
    </>
  );
};
