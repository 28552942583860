import { Button, HSpacer, Modal, Radio, Text, VSpacer } from '@/components/DesignSystem';
import { FileUpload } from '@/components/shared/FileUpload';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ImportApi } from '@/utilities/api/ImportApi';
import { downloadCsv } from '@/utilities/Export';
import { Divider, RadioGroup } from '@mui/material';
import { ImportType } from '@shared/enums';
import { Fragment, useState } from 'react';
import { useQueryClient } from 'react-query';

interface ImportErpModalProps {
  onClose: () => void,
  open: boolean,
  retailerId: string,
}

const radioOptions = [
  'Products',
  'Customers',
  'Locations',
  'Salespersons',
  'Orders',
  'Linked Accounts',
  'Tier Overrides',
];

export const ImportErpModal = ({
  onClose,
  open,
  retailerId,
}: ImportErpModalProps) => {
  const [selectedOption, setSelectedOption] = useState<ImportType>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isImporting, setIsImporting] = useState(false);
  const [isUpdatedAfterError, setIsUpdatedAfterError] = useState(true);
  const { openSnackbar } = useSnackbar();
  const isFormValid = !!(selectedOption && selectedFile);
  const queryClient = useQueryClient();

  return (
    <Modal
      acceptButton={() => (
        <Button
          disabled={!isFormValid || !isUpdatedAfterError}
          loading={isImporting}
          onClick={async () => {
            if (selectedFile && selectedOption) {
              try {
                setIsImporting(true);
                await ImportApi.import(retailerId, selectedFile, selectedOption);
                openSnackbar('File import started');
                void queryClient.invalidateQueries(QueryKeys.GET_IMPORT_HISTORY_LIST);
                void queryClient.invalidateQueries(QueryKeys.GET_IMPORT_PROCESSING_RESULT);
                void queryClient.invalidateQueries(QueryKeys.GET_IMPORT_IS_IMPORTING);
                onClose();
              } catch (error) {
                openSnackbar('File import failed');
                setIsUpdatedAfterError(false);
              }
              setIsImporting(false);
            }
          }}
          sx={{ marginLeft: '10px' }}
          testID="import-erp-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={() => (
        <Button
          disabled={isImporting}
          onClick={onClose}
          testID="import-erp-cancel-button"
          variant="text"
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open={open}
      testID="import-erp-modal"
      title="ERP Data Import"
      width={560}
    >
      <Text category="title-medium">Select the type of data import</Text>
      <RadioGroup
        onChange={(e) => setSelectedOption(e.target.value as ImportType)}
        value={selectedOption}
      >
        {radioOptions.map((option) => (
          <Fragment key={option}>
            <VSpacer size="3" />
            <Radio testID={`${option}-radio`} value={option.toLowerCase()}>
              <HSpacer size="5" />
              {option}
              <HSpacer size="5" />
              <Button
                color="info"
                onClick={async () => {
                  const csv = await ImportApi.getTemplate(option.toLowerCase() as ImportType);
                  downloadCsv(csv, `${option}-template`);
                }}
                size="small"
                testID={`${option}-template-link`}
                variant="text"
              >
                <Text category="body-small">template</Text>
              </Button>
            </Radio>
            <VSpacer size="3" />
          </Fragment>
        ))}
      </RadioGroup>
      <VSpacer size="5" />
      <Divider />
      <VSpacer size="5" />
      <Text category="title-medium">Upload</Text>
      <VSpacer size="4" />
      <Text category="body-small">Upload CSV file using the template provided</Text>
      <VSpacer size="5" />
      <FileUpload
        acceptedFiles=".csv"
        onChangeFile={(file) => {
          setSelectedFile(file);
          setIsUpdatedAfterError(true);
        }}
      />
    </Modal>
  );
};
