import { Button, HSpacer, SplitTabs, VSpacer } from '@/components/DesignSystem';
import { GlobalBanner } from '@/components/DesignSystem/Banner/GlobalBanner';
import { productRequestConstants } from '@/constants/ProductRequest';
import { QueryKeys } from '@/constants/QueryKeys';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Card, Container, Divider, Stack, Tab } from '@mui/material';
import { PricingRequestStatus, PricingRequestStatusNew } from '@shared/enums';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ExpirationPanel from './ExpirationPanel';
import PricingRequestDetailsCard from './PricingRequestDetailsCard';
import ProductListTab from './ProductListTab';
import SalespersonList from './SalespersonList';
import UserProfile from './UserProfile';

const excludeExpirationOnStatus = [
  PricingRequestStatusNew.Draft,
  PricingRequestStatus.Rejected,
  PricingRequestStatus.Closed,
  PricingRequestStatus.Accepted,
  PricingRequestStatusNew.OrderShipped,
  PricingRequestStatusNew.AwaitingFulfillment,
  PricingRequestStatusNew.AwaitingPickup,
  PricingRequestStatusNew.AwaitingDelivery,
  PricingRequestStatusNew.OnAccountRequested,
  PricingRequestStatusNew.FinancingRequested,
];

const PricingRequest = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { id = '' } = useParams();

  const { data: pricingRequest, isLoading } = useQuery(
    [QueryKeys.GET_PRICING_REQUEST, id],
    () => PricingRequestsApi.getPricingRequest(id as string, false, true),
  );

  const promotionSnapshotDate = pricingRequest?.offers?.[0]?.payment?.paidDate ?? undefined;
  const isOfferRead = !!pricingRequest?.offers?.some(({ isRead }) => isRead);

  const navigate = useNavigate();
  return (
    <>
      {!!pricingRequest?.deletedAt && (
        <GlobalBanner
          message="This product request has been deleted by the farmer.
          No further action is required."
          status="error"
          testID="pricing-request-deleted-banner"
        />
      )}
      <Container maxWidth='xl' sx={{ paddingBottom: '24px' }}>
        <VSpacer size='10' />
        <Button
          onClick={() => {
            navigate('/admin/orders');
          }}
          startIcon={<ArrowBackIcon />}
          testID='back-to-orders-list'
          variant='text'
        >
          {productRequestConstants.backToAllOrders}
        </Button>
        <Stack sx={{ display: 'flex', flexDirection: 'revert' }}>
          <Stack sx={{ minWidth: '325px' }}>
            <VSpacer size='5' />
            <PricingRequestDetailsCard
              isOfferRead={isOfferRead}
              orderCompletedOn={pricingRequest?.orderCompletedOn}
              publicId={pricingRequest?.publicId}
              status={pricingRequest?.status}
            />
            <VSpacer size='5' />
            <UserProfile pricingRequestById={pricingRequest} />
          </Stack>
          <HSpacer size='7' />
          <Stack flexGrow={1}>
            {pricingRequest?.status && (
              !excludeExpirationOnStatus.includes(
                pricingRequest?.status as
                  | PricingRequestStatus
                  | PricingRequestStatusNew,
              )) && (
                <ExpirationPanel pricingListRequestData={pricingRequest} />
            )}
            <VSpacer size='5' />
            <Card sx={{ padding: '20px' }}>
              <SplitTabs
                onChange={(_, value) => setSelectedTab(value)}
                testID='review-select-offers-tabs'
                value={selectedTab}
              >
                <Tab label='Products' />
                <Tab label='Salespersons' />
              </SplitTabs>
              <VSpacer size='5' />
              <Divider />
              {selectedTab === 0 && (
                <ProductListTab
                  bundles={pricingRequest?.bundles ?? []}
                  products={pricingRequest?.products ?? []}
                  promotionSnapshotDate={promotionSnapshotDate}
                />
              )}
              {selectedTab === 1 && (
                <SalespersonList
                  chatEssentials={{
                    userId: pricingRequest?.userId ?? '',
                    publicId: pricingRequest?.publicId ?? 0,
                  }}
                  isPricingRequestByIdLoading={isLoading}
                  pricingRequestById={pricingRequest}
                  pricingRequestStatus={pricingRequest?.status}
                />
              )}
            </Card>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default PricingRequest;
