import {
  Button,
  Checkbox,
  Dialog,
  HSpacer,
  IconButton,
  Search,
  Text,
  Toolbar,
  VSpacer,
} from '@/components/DesignSystem';
import { FilterResult } from '@/components/DesignSystem/Toolbar/interfaces';
import { ADD_LOCATION_POP_UP, getCountsText } from '@/constants/HierarchyOfRetailers';
import {
  useGetAllRetailerLocationsPreferred,
  useGetRetailerLocations,
} from '@/hooks/useRetailerLocations';
import { useSearch } from '@/hooks/useSearch';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { ApiRetailerLocation } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { Box, Pagination, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { filterOptions, filters as locationFilters } from '../filters';
import AddLocation from './AddLocation';
import LocationInfoCard from './LocationOverviewCard';

type FilterKeys = 'isActive' | 'isInactive' | 'all';

type LocationCardProps = {
  isRetailerActive: boolean,
  onAddLocation: () => void,
  retailerId: string,
  show: boolean,
};

const LocationOverviewSection = ({
  isRetailerActive,
  onAddLocation,
  retailerId,
  show,
}: LocationCardProps) => {
  const [filterResult, setFilterResult] = useState<FilterResult>({});
  const [currentPage, setPage] = useState(0);
  const [toggleActive, setToggleActive] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const {
    areAllRetailerLocationsPreferred,
    invalidateAreAllRetailerLocationsPreferred,
  } = useGetAllRetailerLocationsPreferred(retailerId);

  const { search, setSearch, debouncedSearch } = useSearch();

  const selectedFilters: Record<string, string | boolean> = Array.from(
    filterResult?.selections?.entries() ?? [],
  )
    .map(([filterId, selection]) => {
      return {
        filterId,
        value: Array.from(selection).join(', '),
      };
    })
    .reduce((obj, filter) => {
      if (filter?.filterId === 'sortDesc') {
        obj['sortDesc'] = filter.value === 'desc' ? true : false;
        return obj;
      } else {
        obj[filter?.filterId] = filterOptions[filter.value as FilterKeys];
        return obj;
      }
    }, {} as Record<string, string | boolean>);

  useEffect(() => {
    setPage(0);
  }, [selectedFilters?.isActive, debouncedSearch]);

  const { id } = useParams();
  const {
    retailerLocations,
    invalidateRetailerLocations,
    isLoading,
  } = useGetRetailerLocations(
    id ?? '',
    {
      ...selectedFilters,
      limit: 20,
      page: currentPage,
      search: debouncedSearch,
    },
  );

  const onAddLocationClick = () => {
    if (!isRetailerActive) {
      return setToggleActive(true);
    }
    onAddLocation();
  };

  return (
    <div>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
      >
        <Stack spacing={2}>
          <Text category='h6'>Locations</Text>
          <Text category='body-medium'>
            {getCountsText({
              activeCount: retailerLocations?.activeCount ?? 0,
              isActive: selectedFilters?.isActive,
              selectedTabText: 'location',
              total: retailerLocations?.total ?? 0,
            })}
          </Text>
        </Stack>
        <Box>
          {showLocations
            ? <IconButton
                disabled={!retailerLocations?.total}
                onClick={() => setShowLocations(!showLocations)}
                testID='view-locations'
                variant="outlined"
              >
                <ArrowDropUp />
              </IconButton>
            : <Button
                disabled={!retailerLocations?.total}
                onClick={() => setShowLocations(!showLocations)}
                testID='view-locations'
                variant="outlined"
              >
                View Locations
              </Button>
          }
          <HSpacer size='4' />
          <Button
            onClick={onAddLocationClick}
            startIcon={<AddIcon />}
            testID='add-location'
            variant="outlined"
          >
            Add Location
          </Button>
        </Box>
      </Stack>
      <VSpacer size='4' />
      <div style={{ display: showLocations ? 'block' : 'none' }}>
        <Search
          disabled={retailerLocations?.total === 0}
          onChangeText={setSearch}
          testID='search-retailer'
          value={search}
          width={350}
        />
        <Stack
          alignItems='left'
          direction='row'
          justifyContent='left'
          sx={{
            margin: '25px 0px',
          }}
        >
          <VSpacer size='10' />
          <div
            style={{
              marginTop: '20px',
            }}
          >
            <Toolbar
              filters={locationFilters}
              hideAllFilter={false}
              hideSearch={true}
              onChange={setFilterResult}
              testID='filter-retailer'
            />
          </div>
        </Stack>
        <VSpacer size='5' />
        <Stack direction="row" justifyContent="space-between">
          <Checkbox
            checked={areAllRetailerLocationsPreferred}
            onChangeChecked={async () => {
              await HierarchyOfRetailersApi.setRetailerLocationsPreferred(
                retailerId,
                !areAllRetailerLocationsPreferred,
              );
              await invalidateAreAllRetailerLocationsPreferred();
              await invalidateRetailerLocations();
            }}
            testID="all-locations-preferred-checkbox"
          >
            Set all Locations as Preferred
          </Checkbox>
        </Stack>
        <VSpacer size='8' />
        {!retailerLocations?.total && !isLoading ? (
          <Stack alignItems='center' direction='row' justifyContent='center'>
            <Stack alignItems='center'>
              <Text category='h5'>No locations to display</Text>
              <Text
                mt={'10px'}
                sx={{ wordSpacing: '2px', color: 'rgba(255,255,255,0.6)' }}
              >
                Click + Add Location to get started
              </Text>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2}>
            {retailerLocations?.data.map(
              (location: ApiRetailerLocation) => (
                <LocationInfoCard
                  isRetailerActive={isRetailerActive}
                  key={location?.id}
                  location={location}
                />
              ),
            )}
          </Stack>
        )}
        <VSpacer size='8' />
        {retailerLocations && !!retailerLocations?.lastPage && (
          <Pagination
            count={retailerLocations?.lastPage + 1}
            onChange={(_, page) => {
              setPage(page - 1);
            }}
            page={retailerLocations?.page + 1}
          />
        )}
        {show && isRetailerActive && <AddLocation onClose={onAddLocation} show={show} />}
        {!isRetailerActive && toggleActive && (
          <Dialog
            acceptButton={(props) => (
              <Button
                {...props}
                onClick={() => setToggleActive(false)}
                variant="contained"
              >
                Close
              </Button>
            )}
            onClose={() => setToggleActive(false)}
            open={toggleActive}
            showCloseButton={false}
            testID="active-warning-confirm-dialog"
          >
            {ADD_LOCATION_POP_UP}
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default LocationOverviewSection;
