import { Toolbar } from '@/components/DesignSystem';
import { useCountyList } from '@/hooks/useCountyList';
import { useGetRetailers } from '@/hooks/useHierarchyOfRetailers';
import { SharedConfig } from '@shared/SharedConfig';
import { useEffect, useMemo, useState } from 'react';
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { groupBy } from '@shared/utilities';
import { LocationDetails } from '@api/interfaces';

interface RetailerListToolbarProps {
  filterSelections?: FilterSelections,
  onChangeSearch: (search: string) => void,
  onChangeSelections: (selections: FilterSelections) => void,
  testID: string,
  totalItems?: number,
  totalUnit: string,
}

export const RetailerListToolbar = ({
  filterSelections,
  onChangeSearch,
  onChangeSelections,
  testID,
  totalItems,
  totalUnit,
}: RetailerListToolbarProps) => {
  const [didSelectCountyFirst, setDidSelectCountyFirst] = useState<boolean>(false);

  const selectedCountyIds = Array.from(filterSelections?.get('state-county-filter') ?? []);
  const selectedLocationIds = Array.from(filterSelections?.get('location-filter') ?? []);

  const { counties } = useCountyList();
  const { getRetailers: retailers } = useGetRetailers(
    {
      ...(selectedCountyIds ? { countyId: selectedCountyIds } : {}),
      isActive: true,
      limit: SharedConfig.maxPageLimit,
      sort: 'name',
    },
    false, true, true,
  );

  const retailersWithLocations = useMemo(() => (
    retailers?.data.filter((retailer) => (
      !!retailer.locationDetails?.length
      && retailer.locationDetails?.some(({ salespersons }) => !!salespersons.length)
    ))
  ), [retailers]);

  const retailerOptions = useMemo(() => {
    return retailersWithLocations
      ?.map((retailer) => (
        {
          id: retailer.id,
          label: retailer.name,
          subOptionLabel: 'Locations',
          subOptions: retailer.locationDetails
            ?.filter((location) => !!location.salespersons.length && !!location.name)
            .map((location) => (
              {
                id: location.id,
                label: location.name,
              }
            )).sort((a, b) => a.label.localeCompare(b.label)) ?? [],
        }
      )) ?? [];
  }, [retailersWithLocations]);

  const selectedLocations = retailersWithLocations
    ?.map(({ locationDetails }) => locationDetails).flat()
    ?.filter((locationDetails) => (
      !!locationDetails && selectedLocationIds.includes(locationDetails.id)
    )) as LocationDetails[];
  const isSelectedServeAllCounties = selectedLocations?.some(
    ({ serveAllCounties }) => serveAllCounties,
  );
  const countiesMatchingSelectedLocations = useMemo(() => {
    if (isSelectedServeAllCounties) {
      return counties.map(({ id }) => id);
    }
    const countyIds = selectedLocations
      ?.map((locationDetails) => locationDetails?.countyDetail).flat()
      .map((countyDetail) => countyDetail?.countyId)
      .filter(Boolean) as string[];
    return [...new Set(countyIds?.concat(selectedCountyIds) ?? [])];
  }, [counties, isSelectedServeAllCounties, selectedCountyIds, selectedLocations]);
  const filteredCounties =
    (!didSelectCountyFirst && selectedLocationIds.length && !isSelectedServeAllCounties)
      ? counties.filter((county) => countiesMatchingSelectedLocations.includes(county.id))
      : counties;
  const stateAndCounties = groupBy(filteredCounties, (county) => county.state);
  const stateAndCountyOptions = Object.keys(stateAndCounties).map((state) => (
    {
      id: state,
      label: state,
      subOptionLabel: 'Counties',
      subOptions: stateAndCounties[state].map((county) => (
        {
          id: county.id,
          label: county.county,
        }
      )),
    }
  )).sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (
      !filterSelections?.get('state-county-filter')
      || !filterSelections?.get('location-filter')
    ) {
      return;
    }
    if (selectedLocationIds.length && !selectedCountyIds.length) {
      const countySelection = filterSelections.get('state-county-filter')!;
      const locationSelection = filterSelections.get('location-filter')!;
      const newCountySelection = new Set(Array.from(countySelection).filter((countyId) => (
        countiesMatchingSelectedLocations.includes(countyId)
      )));
      onChangeSelections(new Map([
        ['state-county-filter', newCountySelection],
        ['location-filter', locationSelection],
      ]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationIds]);

  const filters: Filter[] = [
    {
      id: 'state-county-filter',
      label: 'State & Counties',
      options: stateAndCountyOptions,
      selectionMethod: 'multi-select',
    },
    {
      helperText: 'Showing retailers with \'preferred\' status',
      id: 'location-filter',
      label: 'Retailer & Locations',
      options: retailerOptions,
      selectionMethod: 'multi-select',
    },
  ];

  return (
    <Toolbar
      filters={filters}
      onChange={({ search, selections }) => {
        onChangeSearch(search ?? '');
        if (selections) {
          onChangeSelections(selections);
          if (!selectedLocationIds.length && !selectedCountyIds.length) {
            if (selections.get('state-county-filter')?.size) {
              setDidSelectCountyFirst(true);
            } else {
              setDidSelectCountyFirst(false);
            }
          }
        }
      }}
      retainSelectionsOnFilterChange
      testID={testID}
      totalItems={totalItems}
      totalUnit={totalUnit}
    />
  );
};
