import { Button, Card, HSpacer, IconButton, Text, VSpacer } from '@/components/DesignSystem';
import {
  AddAllProductsConfirmDialog,
} from '@/components/shared/ListSelector/AddAllProductsConfirmDialog';
import { Action } from '@/components/shared/ListSelector/helper';
import { SelectType } from '@/components/shared/ListSelector/RetailersList';
import { AppConfig } from '@/constants/AppConfig';
import { ApiRetailer } from '@api/interfaces';
import { KeyboardArrowRight } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Store from '@mui/icons-material/Store';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';

interface RetailerCardProps {
  onAction: (action: Action) => void,
  onSelectRetailer: (retailer: ApiRetailer) => void,
  retailer: ApiRetailer,
  selectedIds: string[],
  selectType: SelectType,
}

export const RetailerCard = ({
  onAction,
  onSelectRetailer,
  retailer,
  selectedIds,
  selectType,
}: RetailerCardProps) => {
  const [showAddAllProductsDialog, setShowAddAllProductsDialog] = useState(false);
  const imageUrl = retailer?.image && `${AppConfig.staticImageHost}/${retailer.image}`;
  const getSubline = () => {
    const locationsCount = retailer.locationDetails?.length ?? 0;
    const salespersonsCount = getAvailableSelectionCount();
    const productsCount = retailer.productRetailers?.length ?? 0;
    let locationsSublineText = `${locationsCount} location`;
    if (locationsCount !== 1) {
      locationsSublineText += 's';
    }
    let salespersonsSublineText = `${salespersonsCount} salesperson`;
    if (salespersonsCount !== 1) {
      salespersonsSublineText += 's';
    }
    let productsSublineText = `${productsCount} product`;
    if (productsCount !== 1) {
      productsSublineText += 's';
    }
    return (
      <Stack direction="row">
        {(selectType === 'salespersons' || selectType === 'locations') && (
          <>
            <Text category="body-medium">{locationsSublineText}</Text>
            <HSpacer size="2" />
          </>
        )}
        {selectType === 'salespersons' && (
          <>
            •
            <HSpacer size="2" />
            <Text category="body-medium">{salespersonsSublineText}</Text>
          </>
        )}
        {selectType === 'products' && (
          <>
            <HSpacer size="2" />
            <Text category="body-medium">{productsSublineText}</Text>
          </>
        )}
      </Stack>
    );
  };

  const getAddedSelectionCount = () => {
    if (selectType === 'salespersons') {
      const salespersons = retailer.locationDetails?.reduce((acc: { id: string }[], location) => {
        location.salespersons.forEach((salesperson) => acc.push(salesperson));
        return acc;
      }, []) ?? [];
      return salespersons.filter(
        (salesperson) => selectedIds.includes(salesperson.id),
      ).length ?? 0;
    } else if (selectType === 'locations') {
      return retailer.locationDetails?.filter(
        (location) => selectedIds.includes(location.id),
      ).length ?? 0;
    } else {
      return retailer.productRetailers?.filter(
        (productRetailer) => selectedIds.includes(productRetailer.productId),
      ).length ?? 0;
    }
  };

  const getAddedSelectionLabel = () => {
    switch (selectType) {
      case 'salespersons':
        return 'recipient';
      case 'locations':
        return 'location';
      case "products":
        return 'product';
    }
  };

  const getAvailableSelectionCount = () => {
    if (selectType === 'salespersons') {
      return retailer.locationDetails?.reduce((acc, location) => {
        acc += location.salespersons.length;
        return acc;
      }, 0) ?? 0;
    } else if (selectType === 'locations') {
      return retailer.locationDetails?.length ?? 0;
    } else {
      return retailer.productRetailers?.length ?? 0;
    }
  };

  const addedSelectionCount = getAddedSelectionCount();
  const selectionCount = getAvailableSelectionCount();
  const showAddAllButton = selectionCount > 0 && addedSelectionCount !== selectionCount;
  const showRemoveAllButton = addedSelectionCount > 0;
  const showNavigateButton = selectionCount > 0;

  return (
    <Card testID={`retailer-${retailer.id}-salesperson-recipient-card`}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack direction="row">
          <Avatar
            alt={`${retailer?.name ?? 'retailer'} logo`}
            height={56}
            src={imageUrl ?? undefined}
            width={56}
          >
            {imageUrl ? null : (
              <Store sx={{ height: 42, width: 42 }} />
            )}
          </Avatar>
          <HSpacer size="4" />
          <Stack justifyContent="flex-end">
            <Text category="title-small">{retailer.name}</Text>
            <VSpacer size="2" />
            <Text category="body-small">
              {getSubline()}
            </Text>
          </Stack>
        </Stack>
        <Stack alignItems="center" flexDirection="row">
          {showRemoveAllButton && (
            <>
              <Text category="body-medium">
                {addedSelectionCount}&nbsp;
                {getAddedSelectionLabel()}{addedSelectionCount > 1 ? 's' : ''}
              </Text>
              <HSpacer size="4" />
              <Button
                onClick={() => onAction(Action.RemoveAll)}
                testID={`retailer-${retailer.id}-salesperson-recipient-remove-button`}
                variant="text"
              >
                Remove all
              </Button>
            </>
          )}
          {showRemoveAllButton && showAddAllButton && (
            <HSpacer size="4" />
          )}
          {showAddAllButton && (
            <Button
              onClick={() => {
                if (selectType === 'products') {
                  setShowAddAllProductsDialog(true);
                } else {
                  onAction(Action.AddAll);
                }
              }}
              startIcon={<AddIcon />}
              testID={`retailer-${retailer.id}-salesperson-recipient-add-button`}
              variant="outlined"
            >
              Add all
            </Button>
          )}
          {showNavigateButton && (
            <>
              <HSpacer size="4" />
              <IconButton
                onClick={() => onSelectRetailer(retailer)}
                testID={`${retailer.id}-location-navigate-button`}
              >
                <KeyboardArrowRight />
              </IconButton>
            </>
          )}
        </Stack>
      </Stack>
      {showAddAllProductsDialog && (
        <AddAllProductsConfirmDialog
          onCancel={() => setShowAddAllProductsDialog(false)}
          onConfirm={() => {
            setShowAddAllProductsDialog(false);
            onAction(Action.AddAll);
          }}
          open
        />
      )}
    </Card>
  );
};
