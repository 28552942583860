import { UserType } from '../enums';

export function isFarmerUser (user?: { userType: UserType }) {
  return userHasTypes(user, [UserType.Farmer]);
}

export function isInternalUser (user?: { userType: UserType }) {
  return userHasTypes(user, [UserType.Internal]);
}

export function isRetailerUser (user?: { userType: UserType }) {
  return userHasTypes(user, [UserType.RetailerAdmin, UserType.SalesPerson]);
}

export function isRetailerAdminUser (user?: { userType: UserType }) {
  return userHasTypes(user, [UserType.RetailerAdmin]);
}

export function isSalespersonUser (user?: { userType: UserType }) {
  return userHasTypes(user, [UserType.SalesPerson]);
}

function userHasTypes (user: { userType: UserType } | undefined, types: UserType[]) {
  return !!user && types.includes(user.userType);
}
