import { Stack, useTheme } from "@mui/material";
import { Card, IconButton, Text, VSpacer } from "@/components/DesignSystem";
import Add from "@mui/icons-material/Add";
import React from "react";
import Edit from "@mui/icons-material/Edit";
import DeleteOutline from "@mui/icons-material/DeleteOutline";

interface BrandAssetsCardGroupItemProps {
  onClick: (asset: string, mode: "add" | "edit" | "delete") => void,
  statusText?: string | null,
  title: string,
}

export const BrandAssetsCardGroupItem = ({
  statusText,
  onClick,
  title,
}: BrandAssetsCardGroupItemProps) => {
  const theme = useTheme();
  return (
    <Card
      cardContentStyle={{
        backgroundColor: theme.palette.surface2,
        height: "100%",
        padding: '16px 12px 16px 16px',
      }}
      style={{ boxShadow: "none", display: "flex", flexDirection: "column", height: "100%" }}
      testID={`${title}-card`}
    >
      {!statusText && (
        <Stack direction="row" justifyContent="space-between" sx={{ height: "100%" }}>
          <Stack justifyContent="flex-start" sx={{ marginTop: "6px" }}>
            <Text category="title-medium">
              {title}
            </Text>
          </Stack>
          <Stack justifyContent="end">
            <IconButton
              onClick={() => onClick(title, "add")}
              testID={`${title}-add-button`}
              variant="filled"
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>
      )}
      {!!statusText && (
        <>
          <Stack>
            <Text category="title-medium">
              {title}
            </Text>
            {!!statusText && (
              <>
                <VSpacer size="2" />
                <Stack alignItems="center" direction="row" gap="4px">
                  <Text
                    category="body-medium"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {statusText}
                  </Text>
                </Stack>
              </>
            )}
          </Stack>
          <Stack alignItems="center" direction="row" justifyContent="end">
            <IconButton onClick={() => onClick(title, "edit")} testID={`${title}-edit-button`}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => onClick(title, "delete")} testID={`${title}-remove-button`}>
              <DeleteOutline />
            </IconButton>
          </Stack>
        </>
      )}
    </Card>
  );
};