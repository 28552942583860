import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { ColorIndex, PieChart } from '@/components/shared/PieChart';
import { QueryKeys } from '@/constants/QueryKeys';
import { formatDate } from '@/pages/CustomerDashboard/helpers';
import { UserApi } from '@/utilities/api/UserApi';
import { ApiCategory } from '@api/interfaces';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import { Box, Divider, Stack } from '@mui/material';
import {
  CategoryReward,
  formatAmount,
  formatCurrency,
  getJsDateFromString,
  shouldHideZeroDollarTotalSpendForCategory,
} from '@shared/utilities';
import { Fragment } from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';

interface ProgramOverviewProps {
  categories: ApiCategory[],
  customerId: string,
  farmerRewards: ApiFarmerRewards,
  program: ApiRewardsProgram,
}

const formatPoints = (amount: number) => {
  const formattedPoints = amount.toLocaleString(
    'en-US',
    { minimumFractionDigits: 1, maximumFractionDigits: 2 },
  ).replace(/\.?0+$/, '');
  return formattedPoints;
};

export const ProgramOverview = ({
  categories,
  customerId,
  farmerRewards,
  program,
}: ProgramOverviewProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken') ?? params.accessToken;

  const formattedProgramStartDate = formatDate(getJsDateFromString(program.startDate));
  const formattedProgramEndDate = formatDate(getJsDateFromString(program.endDate));
  const yearText = `${formattedProgramStartDate} - ${formattedProgramEndDate}`;

  const { data: programPoints } = useQuery({
    queryKey: [QueryKeys.LIST_POINTS_FOR_PROGRAM_PERIOD, customerId, program.id, accessToken],
    queryFn: async () => UserApi.listPointsForProgramPeriod(customerId, program.id, accessToken),
  });

  const categoryRewards: CategoryReward[] = program.tiers?.[0].segments?.map((segment) => {
    const categoryName = categories.find(
      (category) => category.id === segment.categoryId,
    )?.name ?? '';
    const segmentTotal = farmerRewards.totalPerSegment[segment.categoryId];
    return {
      id: segment.categoryId,
      name: categoryName,
      points: programPoints?.[segment.categoryId] ?? 0,
      totalSpend: segmentTotal?.totalSpend ?? 0,
    };
  }) ?? [];
  categoryRewards.sort((a, b) => a.name.localeCompare(b.name));

  const totalSpendValues = categoryRewards.map((rewards) => rewards.totalSpend);

  return (
    <Stack px="16px">
      <VSpacer size="5" />
      <Text category="body-medium">
        Total loyalty spending for the current {yearText} program period.
      </Text>
      <VSpacer size="6" />
      <Stack
         border="1px solid rgba(0, 0, 0, 0.04)"
         borderRadius="8px"
         boxShadow={"0px 6px 12px -6px rgba(24, 39, 75, 0.12),"
           + " 0px 8px 24px -4px rgba(24, 39, 75, 0.08)"}
        p="16px"
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack>
            <Text category="display-small">
              {formatAmount(farmerRewards.totalSpend)}
            </Text>
            <Text category="overline">
              Current Total Spend
            </Text>
          </Stack>
          {farmerRewards.totalSpend !== 0 && !!totalSpendValues.length && (
            <>
              <HSpacer size="5" />
              <Stack height="72px" width="72px">
                <PieChart heightAndWidth={72} values={totalSpendValues} />
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <VSpacer size="5" />
      <VSpacer size="3" />
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="title-small">
          Categories
        </Text>
        <Text category="title-small">
          Total spend
        </Text>
      </Stack>
      <VSpacer size="4" />
      <Divider />
      {categoryRewards.map((category, index) => (
        <Fragment key={category.id}>
          <VSpacer size="3" />
          <Stack direction="row" justifyContent="flex-start">
            <Box
              bgcolor={ColorIndex[index]}
              borderRadius="3px"
              height="12px"
              margin="6px"
              width="12px"
            />
            <HSpacer size="3" />
            <Stack flexGrow={1} flexShrink={1}>
              <Stack direction="row">
                <Text
                  category="title-small"
                  flexShrink={1}
                  sx={{ wordBreak: 'balanced' }}
                >
                  {category.name}
                </Text>
                <HSpacer size="5" />
                {!shouldHideZeroDollarTotalSpendForCategory(category) && (
                  <Text
                    category="title-small"
                    flexGrow={1}
                    justifyContent="flex-end"
                    textAlign="right"
                  >
                    {formatCurrency(category.totalSpend)}
                  </Text>
                )}
              </Stack>
              <Text category="body-medium">
                {formatPoints(category.points)} point{category.points === 1 ? '' : 's'} earned
              </Text>
              <VSpacer size="2" />
            </Stack>
          </Stack>
          <VSpacer size="3" />
          <Divider />
          <VSpacer size="3" />
        </Fragment>
      ))}
    </Stack>
  );
};
