import { Button, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { SuccessCheckmark } from '@/components/shared/SuccessCheckmark';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Stack } from "@mui/material";
import { formatDate, ISO8601String, isRetailerUser } from '@shared/utilities';
import { GrowersContactInfo } from '@shared/enums';

type PlacedOfferDetails = {
  offerPublicId: string,
  priceRequestExpiration: ISO8601String,
}

export const PlaceOfferSuccess = () => {
  const location = useLocation();
  const offerDetails = location.state as PlacedOfferDetails;
  const navigate = useNavigate();
  const { user } = useAuthentication();

  const priceRequestExpiration = formatDate(offerDetails.priceRequestExpiration);

  return (
    <Container maxWidth="xs">
      <Stack alignItems="center" textAlign="center">
        <VSpacer mobileSize="1" size="11"/>
        <SuccessCheckmark/>
        <Text category="h2">
          Your offer #{offerDetails.offerPublicId} has been shared with the farmer.
        </Text>
        <VSpacer size="8"/>
        <Text category="h3">What happens next?</Text>
        <VSpacer size="8" />
        <Text category="body-large">
          The farmer will review the offer once the
          window closes on {priceRequestExpiration} and if they accept it,
          we'll notify you and connect you directly with the farmer to complete the sale.
        </Text>
        <VSpacer size="8"/>
        <Text category="body-large">
          Thanks for using The GROWERS App - we're excited to
          support relationships between retailers and farmers!
        </Text>
        <VSpacer size="8"/>
        <Text category="body-large">
          If you have any questions about the product request offer process, please contact GROWERS
          support.
          &nbsp;
        </Text>
        <VSpacer size="3" />
        <Text category="body-large">
          <TextLink
            category="body-large"
            href={`mailto:${GrowersContactInfo.supportEmail}`}
            testID="support-email-link"
          >
            Email
          </TextLink>
          &nbsp;
          |
          &nbsp;
          <TextLink
            category="body-large"
            href={`tel:${GrowersContactInfo.supportPhone}`}
            testID="support-phone-link"
          >
            Phone
          </TextLink>
        </Text>
        {isRetailerUser(user) &&
          <>
            <VSpacer size="10"/>
            <VSpacer size="12"/>
            <Stack direction="row" textAlign="center">
              <Button
                onClick={() => navigate(Routes.VIEW_OFFERS)}
                size="giant"
                testID="place-offer-success-view-button"
                variant="outlined"
              >
                Done
            </Button>
            </Stack>
          </>
        }
      </Stack>
    </Container>
  );
};
