import { ApiPromotion } from '@api/interfaces';
import { Button, Card, Text, VSpacer } from '@/components/DesignSystem';
import { PointsTag } from '@/components/shared/PointsTag';
import { Stack } from '@mui/system';
import { useTheme } from '@mui/material';
import { getJsDateFromString } from '@shared/utilities';
import { DateTime } from 'luxon';


interface PromotionCardProps {
  onClickDetails: () => void,
  promotion: ApiPromotion,
}

export const PromotionCard = ({
  onClickDetails,
  promotion,
}: PromotionCardProps) => {
  const theme = useTheme();

  const formattedEndDate = DateTime.fromJSDate(getJsDateFromString(promotion.endDate))
    .toFormat('MMM d, yyyy');

  return (
    <Card testID={`promotion-card-${promotion.id}`}>
      {!!promotion.manufacturer?.name && (
        <>
          <Text category="overline" sx={{ color: theme.palette.text.secondary }}>
            {promotion.manufacturer.name.toUpperCase()} PROMOTION
          </Text>
          <VSpacer size="1" />
        </>
      )}
      <Text fontSize="20px" fontWeight={500}>
        {promotion.name}
      </Text>
      <VSpacer size="3" />
      <PointsTag
        labelStyle={{ color: 'black' }}
        points={promotion.value}
        rewardsType={promotion.rewardType}
        variant="filled"
      />
      <VSpacer size="2" />
      <Stack alignItems="flex-end" direction="row" justifyContent="space-between">
        <Text sx={{ color: theme.palette.text.secondary }}>
          Expires {formattedEndDate}
        </Text>
        <Button
          onClick={onClickDetails}
          sx={{ width: "102px" }}
          testID="details-button"
          variant="outlined"
        >
          Details
        </Button>
      </Stack>
    </Card>
  );
};
