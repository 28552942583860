import { Button, Card, IconButton, Text, VSpacer } from '@/components/DesignSystem';
import { LoyaltyPointsCapProgress } from '@/pages/Promotions/LoyaltyPointsCapProgress';
import { PromotionStatusChip } from '@/pages/Promotions/PromotionStatusChip';

import { getStatuses, PromotionStatus } from '@/utilities/api/PromotionUtilities';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { Stack, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { PromotionProcessingStatus } from '@shared/enums/PromotionProcessingStatus';
import { DeleteOutline, Loop } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

interface PromotionCardProps {
  onDelete: () => void,
  onEdit: () => void,
  onRestartProcessing: () => void,
  onView: () => void,
  promotion: ApiPromotion,
  readonly?: boolean,
  testID: string,
}

function formatDate (date: string) {
  return DateTime.fromISO(date).toFormat('MMM d, yyyy');
}

export const PromotionCard = ({
  onDelete,
  onEdit,
  onRestartProcessing,
  onView,
  promotion,
  readonly,
  testID,
}: PromotionCardProps) => {
  const theme = useTheme();
  const statuses = getStatuses(promotion);
  const isRestartable = [
    PromotionProcessingStatus.Failed,
    PromotionProcessingStatus.Timeout,
  ].includes(promotion.processingStatus);
  const isFuture = statuses.some(status => status === PromotionStatus.FUTURE);

  const CardContent = (
    <Stack
      alignItems='top'
      direction='row'
      justifyContent={'space-between'}
    >
      <Stack>
        {!!promotion.manufacturer?.name && (
          <Text category="overline" sx={{ color: theme.palette.text.secondary }}>
            {promotion.manufacturer.name}
          </Text>
        )}
        <VSpacer size="1" />
        <Text category="body-xlarge">
          {promotion.name}
        </Text>
        <VSpacer size="1" />
        <Text category="body-medium" sx={{ color: theme.palette.text.secondary }}>
          Runs {formatDate(promotion.startDate)} – {formatDate(promotion.endDate)}
        </Text>
      </Stack>
    </Stack>
  );

  return (
    <Card
      elevation={4}
      onClick={onView}
      sx={{ cursor: 'pointer' }}
      testID={testID}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack>
          {CardContent}
          {!!promotion.pointCap && (
            <>
              <VSpacer size="3" />
              <LoyaltyPointsCapProgress promotion={promotion} />
            </>
          )}
          <VSpacer size="2" />
          <PromotionStatusChip promotion={promotion} />
        </Stack>
        <Stack>
          <Stack alignItems='top' direction='row' spacing={2}>
            {(!readonly && isFuture) && (
              <>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                  testID={`${testID}-delete-button`}
                >
                  <DeleteOutline />
                </IconButton>
                <Button
                  disabled
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit();
                  }}
                  size="medium"
                  startIcon={<EditIcon />}
                  sx={{ padding: '10px 20px' }}
                  testID={`${testID}-view-button`}
                  variant="outlined"
                >
                  Edit
                </Button>
              </>
            )}
            {(!readonly && isRestartable) && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onRestartProcessing();
                }}
                size="medium"
                startIcon={<Loop />}
                sx={{ padding: '10px 20px' }}
                testID={`${testID}-restart-button`}
                variant="outlined"
              >
                Restart Processing
              </Button>
            )}
            <Button
              testID={`promotion-card-details-button-${promotion.id}`}
              variant="outlined"
            >
              Details
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
