import { State, StateAbbreviations } from '../enums';
import { formatPhoneNumber } from './StringUtils';

export const getAccountName = (
  user: {
    firstName?: string | null;
    lastName?: string | null;
    businessName?: string | null;
    telephone?: string | null;
    email?: string | null;
    externalId?: string | null;
  },
) => {
  const options = [
    (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : undefined,
    user.firstName,
    user.lastName,
    user.businessName,
    user.telephone ? formatPhoneNumber(user.telephone, 'paren') : undefined,
    user.email,
    user.externalId,
  ];
  return options.find((option) => !!option) ?? 'Customer';
};

export const getCustomerName = (
  user: {
    firstName?: string | null;
    lastName?: string | null;
  },
) => {
  const options = [
    (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : undefined,
    user.firstName,
    user.lastName,
  ];
  return options.find((option) => !!option);
};

export const getFormattedAddress = (
  user: { city: string | null, state: State | null },
): string => {
  let formattedAddress = '';
  const parsedState = user.state && StateAbbreviations[user.state];
  if (user.city) {
    formattedAddress = user.city;
  }
  if (user.city && parsedState) {
    formattedAddress += ', ';
  }
  if (parsedState) {
    formattedAddress += parsedState;
  }
  return formattedAddress;
};
