import ProductIcon from '@/assets/icons/product-icon.svg';
import { Button, Dialog, Logo, Text, VSpacer } from '@/components/DesignSystem';
import { UserSwitcher } from '@/components/shared/UserSwitcher';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from '@/constants/Routes';
import { SessionState, useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { Dashboard, HelpOutline, LocalOffer, People } from '@mui/icons-material';
import Email from '@mui/icons-material/Email';
import MenuIcon from '@mui/icons-material/Menu';
import Phone from '@mui/icons-material/Phone';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material';
import { GrowersContactInfo } from '@shared/enums';
import { formatPhoneNumber, getFarmerName, isInternalUser, ISO8601String } from '@shared/utilities';
import { DateTime } from 'luxon';
import React, { ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const AppHeader = () => {
  const { isInternal, logout, retailer, sessionState, user } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const theme = useTheme();
  const retailerImageUrl = retailer?.image && `${AppConfig.staticImageHost}/${retailer.image}`;

  const formatDate = (date: Date | ISO8601String) => {
    const dateTime = (typeof date === 'string')
      ? DateTime.fromISO(date.toString())
      : DateTime.fromJSDate(date);

    return dateTime.toFormat("LLL dd, hh:mm a")
      .replace('AM', 'am')
      .replace('PM', 'pm');
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    void logout();
    setOpen(false);
    navigate(Routes.LOGIN, { replace: true });
  };

  const handleNavigation = (route: Routes) => {
    navigate(route);
    setOpen(false);
  };

  const isSelected = (route: Routes) => {
    return location.pathname === route;
  };

  interface MenuItem {
    icon?: ReactNode,
    label: string,
    route: Routes,
  }

  const AdminMenuItems: MenuItem[] = [
    {
      label: 'Customers',
      route: Routes.CUSTOMERS,
    },
    {
      label: 'Retailers',
      route: Routes.ADMIN_HIERARCHY_OF_RETAILERS,
    },
    {
      label: 'Manufacturers',
      route: Routes.ADMIN_LIST_MANUFACTURERS,
    },
    {
      label: 'Products',
      route: Routes.PRODUCTS,
    },
    {
      label: 'Promotions',
      route: Routes.PROMOTIONS,
    },
    {
      label: 'Orders',
      route: Routes.ADMIN_LIST_ORDERS,
    },
    {
      label: 'Manage Notifications',
      route: Routes.ADMIN_MANAGE_NOTIFICATIONS,
    },
    {
      label: 'Test Notifications',
      route: Routes.ADMIN_LIST_NOTIFICATIONS,
    },
    {
      label: 'Admin Users',
      route: Routes.ADMIN_LIST_ADMINS,
    },
  ];

  if (isInternal && (AppConfig.env.local || AppConfig.env.devel)) {
    AdminMenuItems.push({
      label: 'UX Sandbox',
      route: Routes.UX_SANDBOX,
    });
  }

  const RetailerMenuItems: MenuItem[] = [
    {
      icon: <Dashboard />,
      label: 'Overview',
      route: Routes.HOMEPAGE,
    },
    {
      icon: <People />,
      label: 'Customers',
      route: Routes.CUSTOMERS,
    },
    {
      icon: <LocalOffer />,
      label: 'Promotions',
      route: Routes.PROMOTIONS,
    },
    {
      icon: <img src={ProductIcon} />,
      label: 'Products',
      route: Routes.PRODUCTS,
    },
  ];

  const formatVersion = (version: string) =>
    version.match(/\d+\.\d+\.\d+/) ? 'v' + version : version;
  const version = (AppConfig.env.test || isInternalUser(user)) && (
    <Text>{formatVersion(AppConfig.version)}</Text>
  );

  return (
    <Stack
      alignItems="center"
      bgcolor={theme.palette.background.default}
      borderBottom="1px solid rgba(0, 0, 0, 0.04)"
      direction="row"
      height="64px"
      justifyContent="space-between"
      p="8px 32px 8px 24px"
      position="fixed"
      width="100%"
      zIndex={999}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        {sessionState === SessionState.LOGGED_IN && user && (
          <>
            <IconButton edge="start" onClick={handleClick}>
              <MenuIcon />
            </IconButton>
            {retailerImageUrl ? (
              <img
                alt={`${retailer.name} logo`}
                height={32}
                onClick={() => navigate(Routes.HOMEPAGE)}
                src={retailerImageUrl}
              />
            ) : (
              <Logo
                onClick={() => navigate(Routes.HOMEPAGE)}
                size="tiny"
              />
            )}
          </>
        )}
        <UserSwitcher />
      </Stack>
      <Stack alignItems="center" direction="row" spacing={2}>
        {!!retailer?.pointsLastUpdatedAt && (
          <Text category="body-small">
            Last updated: {formatDate(retailer.pointsLastUpdatedAt)}
          </Text>
        )}
        {isInternal && (
          <Stack alignItems='center' direction='row' spacing={2}>
            {version}
            <Text>{getFarmerName(user, true)}</Text>
          </Stack>
        )}
        <Button
          onClick={() => setShowHelpDialog(true)}
          startIcon={<HelpOutline />}
          testID="help-button" variant="text"
        >
          Help
        </Button>
      </Stack>
      <Drawer
        anchor="left"
        elevation={5}
        onClose={handleClose}
        open={open}
        sx={{ borderRadius: '0px 24px 24px 0px', padding: '32px 0px 24px 0px' }}
      >
        <Stack flexGrow={1} paddingBottom="24px" paddingTop="32px" width="316px">
          <List sx={{ py: 0, width: '100%' }}>
            {(isInternal ? AdminMenuItems : RetailerMenuItems).map((menuItem) => (
              <ListItem key={menuItem.label}>
                <ListItemButton
                  onClick={() => handleNavigation(menuItem.route)}
                  selected={isSelected(menuItem.route)}
                >
                  {!!menuItem.icon && (
                    <ListItemIcon>
                      {menuItem.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText primary={menuItem.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Stack alignItems="center" flexGrow={1} justifyContent="flex-end">
            <Button
              onClick={handleLogout}
              testID="logout-button"
              variant="text"
            >
              Logout
            </Button>
          </Stack>
        </Stack>
      </Drawer>
      {showHelpDialog && (
        <Dialog
          onClose={() => setShowHelpDialog(false)}
          open
          testID="help-dialog"
          title="Contact Growers support"
        >
          <Divider />
          <VSpacer size="5" />
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Text category="overline">PHONE NUMBER</Text>
              <Text category="body-large">
                {formatPhoneNumber(GrowersContactInfo.supportPhone, 'paren')}
              </Text>
            </Stack>
            <Button
              onClick={() => document.location.href = `tel:${GrowersContactInfo.supportPhone}`}
              startIcon={<Phone />}
              testID="call-support-button"
            >
              Call
            </Button>
          </Stack>
          <VSpacer size="5" />
          <Divider />
          <VSpacer size="5" />
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Text category="overline">EMAIL</Text>
              <Text category="body-large">
                {GrowersContactInfo.supportEmail}
              </Text>
            </Stack>
            <Button
              onClick={() => document.location.href = `mailto:${GrowersContactInfo.supportEmail}`}
              startIcon={<Email />}
              testID="email-support-button"
            >
              Email
            </Button>
          </Stack>
          <VSpacer size="5" />
          <Divider />
        </Dialog>
      )}
    </Stack>
  );
};
