import { Button, HSpacer, Text } from '@/components/DesignSystem';
import {
  ProgramExclusionsBottomSheet,
} from '@/pages/CustomerDashboard/ProgramExclusionsBottomSheet';
import { ApiCategory } from '@api/interfaces';
import { Stack } from '@mui/material';
import { useState } from 'react';

interface ProgramExclusionsDisclaimerProps {
  brandColor: string,
  categories: ApiCategory[],
  segmentSpendCategoryExclusionIds: string[],
  tierSpendCategoryExclusionIds: string[],
}

export const ProgramExclusionsDisclaimer = ({
  brandColor,
  categories,
  segmentSpendCategoryExclusionIds,
  tierSpendCategoryExclusionIds,
}: ProgramExclusionsDisclaimerProps) => {
  const [showCategoryExclusions, setShowCategoryExclusions] = useState(false);
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Text category="label-medium">
          Program exclusions
        </Text>
        <Text category="body-xsmall" sx={{ color: '#525252' }}>
          Some categories and subcategories are not eligible towards earning program tiers.
        </Text>
      </Stack>
      <HSpacer size="4" />
      <Button
        onClick={() => setShowCategoryExclusions(true)}
        sx={{ color: brandColor }}
        testID="program-exclusions-detail-button"
        variant="text"
      >
        Details
      </Button>
      {showCategoryExclusions && (
        <ProgramExclusionsBottomSheet
          categories={categories}
          onClose={() => setShowCategoryExclusions(false)}
          segmentSpendCategoryExclusionIds={segmentSpendCategoryExclusionIds}
          tierSpendCategoryExclusionIds={tierSpendCategoryExclusionIds}
        />
      )}
    </Stack>
  );
};
