import { Client } from '@/utilities/api/Client';
import { RetailerRecipientEndpoint } from '@api/endpoints';

export class RetailerRecipientApi {
  static list (
    retailerId: string,
  ): Promise<RetailerRecipientEndpoint.List.Response> {
    return Client(`retailers/${retailerId}/recipients`);
  }

  static sync (
    retailerId: string,
    userIds: string[],
  ): Promise<RetailerRecipientEndpoint.List.Response> {
    return Client(`retailers/${retailerId}/recipients/sync`, {
      body: { userIds },
      method: 'PUT',
    });
  }
}
