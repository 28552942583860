import { VSpacer } from '@/components/DesignSystem';
import { AppHeader } from '@/components/shared/AppHeader';
import { Routes } from '@/constants/Routes';
import { LoginPage } from '@/pages/Auth/LoginPage';
import { BenefitsPage } from '@/pages/CustomerDashboard/BenefitsPage';
import { CustomerDashboard } from '@/pages/CustomerDashboard/CustomerDashboard';
import { HowItWorksPage } from '@/pages/CustomerDashboard/HowItWorksPage';
import { PromotionsPage } from '@/pages/CustomerDashboard/Promotions/PromotionsPage';
import { WalletPage } from '@/pages/CustomerDashboard/WalletPage';
import { SplashPage } from '@/pages/RedirectToOs/SplashPage';
import { WebDeliverable } from '@/pages/WebDeliverable/WebDeliverable';
import { GetAppPage } from '@/views/GetAppPage';
import { RetailerAppFooter } from '@/views/RetailerAppFooter';
import React, { ReactNode } from 'react';
import { Navigate, Route, Routes as AppRoutes } from "react-router-dom";

export const LoginView = () => {
  const createLayout = (element: ReactNode) => {
    return (
      <>
        <AppHeader />
        <VSpacer size="11" />
        {element}
        <RetailerAppFooter />
      </>
    );
  };

  return (
    <AppRoutes>
      <Route element={createLayout(<LoginPage />)} path={Routes.LOGIN} />
      <Route element={<WebDeliverable />}
        path={Routes.WEB_DELIVERABLE}
      />
      {
        [
          Routes.APP_RECOMMENDATION,
          Routes.APP_REQUEST,
          Routes.RETAILER_APP_RECOMMENDATION,
          Routes.RETAILER_APP_REQUEST,
        ].map((path) => (
          <Route element={<SplashPage />} key={path} path={path} />
        ))
      }
      <Route element={<GetAppPage />} path={Routes.GET_APP} />
      <Route element={<Navigate to={Routes.LOGIN} />} path={"*"} />
      <Route element={<CustomerDashboard />}
        path={Routes.CUSTOMER_DASHBOARD}
      />
      <Route
        element={<BenefitsPage />}
        path={Routes.CUSTOMER_DASHBOARD_BENEFITS}
      />
      <Route
        element={<HowItWorksPage />}
        path={Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS}
      />
      <Route
        element={<WalletPage />}
        path={Routes.CUSTOMER_DASHBOARD_WALLET}
      />
      <Route
        element={<PromotionsPage />}
        path={Routes.CUSTOMER_DASHBOARD_PROMOTIONS}
      />
    </AppRoutes>
  );
};
