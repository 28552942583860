import { Button, HSpacer, Input, Text, VSpacer } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { AuthApi } from '@/utilities/api/AuthApi';
import { Stack, useTheme } from '@mui/material';
import { ApiErrors } from '@shared/enums';
import { SharedConfig } from '@shared/SharedConfig';
import { isValidPhoneNumber, validateEmail } from '@shared/utilities';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

interface LoginFormProps {
  onConfirmSuccess: ({ lastFour, username }: { lastFour: string, username: string }) => void,
}

export const LoginForm = ({
  onConfirmSuccess,
}: LoginFormProps) => {
  const { state } = useLocation();
  const { authenticate } = useAuthentication();
  const navigate = useNavigate();
  const theme = useTheme();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [username, setUsername] = useState(state?.username ?? "");
  const [errorMessage, setErrorMessage] = (
    useState<{ title?: string, message: string } | undefined>(state?.errorMessage)
  );

  const { mutate: sendOtpCode, isLoading: isSendingOtp } = useMutation(
    () => {
      const isTelephone = isValidPhoneNumber(username.replace(/[() -]/g, ""), AppConfig.env.test);
      return AuthApi.sendOtpCode(
        isTelephone
          ? { telephone: username.replace(/[() -]/g, ""), type: 'Login' }
          : { email: username, type: 'Login' },
      );
    },
    {
      onError: () => {
        setErrorMessage({ message: 'Invalid user' });
      },
      onSuccess: (data) => {
        setErrorMessage(undefined);
        onConfirmSuccess({ username, lastFour: data.lastFour });
      },
      retry: false,
    },
  );

  const isLoyaltyProgramDomain = window.location.hostname.includes(SharedConfig.shortUrlDomain);

  const handleInputChange = useCallback(
    debounce((username: string) => {
      if (isValidPhoneNumber(username.replace(/[() -]/g, ""), AppConfig.env.test)) {
        setErrorMessage(undefined);
        setShowPasswordField(false);
      } else if (validateEmail(username)) {
        setErrorMessage(undefined);
        if (!isLoyaltyProgramDomain) {
          setShowPasswordField(true);
        }
      } else if (username) {
        setErrorMessage({ message: 'Invalid Phone Number or Email' });
      } else {
        setErrorMessage(undefined);
      }
    }, 1000),
    [],
  );

  const { mutate: authenticateUser, isLoading: isAuthenticating } = useMutation(
    () => authenticate(username, password),
    {
      onError: (err: { message: string }) => {
        if (err.message === ApiErrors.inactiveAccount) {
          setErrorMessage({ title: 'Inactive account',  message: err.message });
        } else {
          setErrorMessage({ message: "Incorrect username or password" });
        }
      },
      onSuccess: () => {
        navigate(Routes.HOMEPAGE, { replace: true });
      },
      retry: false,
    },
  );

  const handleOnContinue = async () => {
    if (isValidPhoneNumber(username.replace(/[() -]/g, ""), AppConfig.env.test)) {
      setErrorMessage(undefined);
      setShowPasswordField(false);
      sendOtpCode();
    } else if (validateEmail(username)) {
      setErrorMessage(undefined);
      if (isLoyaltyProgramDomain) {
        sendOtpCode();
      } else {
        setShowPasswordField(true);
        if (password) {
          authenticateUser();
        }
      }
    } else if (username) {
      setErrorMessage({ message: 'Invalid Phone Number or Email' });
    }
  };

  return (
    <>
      <Text category="headline-large">Login to your account</Text>
      <VSpacer size="3" />
      <Text category="body-large" sx={{ color: theme.palette.text.secondary }} textAlign="center">
        Please enter your phone number or email address to continue
      </Text>
      <VSpacer size="7" />
      <VSpacer size="2" />
      {!!errorMessage && (
        <>
          <Text category="body-medium" color="error">
            {errorMessage.message}
          </Text>
          <VSpacer size="6" />
        </>
      )}
      <Input
        error={!!errorMessage}
        label="Phone number or email"
        onChangeText={(username) => {
          setUsername(username);
          handleInputChange(username);
        }}
        onEnterPressed={handleOnContinue}
        testID="login-form-username"
        value={username}
        variant="outlined"
        width="375px"
      />
      {showPasswordField &&
        <>
          <VSpacer size="5" />
          <Stack direction="row" width="375px">
            <Input
              error={!!errorMessage}
              label="Password"
              onChangeText={setPassword}
              onEnterPressed={handleOnContinue}
              testID="farmer-login-form-password"
              type={showPassword ? undefined : 'password'}
              value={password}
              variant="outlined"
            />
            <HSpacer size="2" />
            <Stack>
              <Button
                onClick={() => setShowPassword(!showPassword)}
                size="small"
                square
                sx={{
                  borderRadius: 1,
                  flex: 1,
                  width: 56,
                }}
                testID="show-password-button"
              >
                {showPassword ? 'Hide' : 'Show'}
              </Button>
            </Stack>
          </Stack>
        </>
      }
      <VSpacer size="8" />
      <Button
        disabled={!validateEmail(username)
          && !isValidPhoneNumber(username.replace(/[() -]/g, ""), AppConfig.env.test)
        }
        loading={isSendingOtp || isAuthenticating}
        onClick={handleOnContinue}
        testID="login-form-button"
      >
        Continue
      </Button>
    </>
  );
};
