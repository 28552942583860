import { alpha, PaletteOptions } from '@mui/material';

export type Color = (
  'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
);

declare module "@mui/material/styles" {
  interface Palette {
    primaryContainer: string,
    rewardsYellow: string,
    surface2: string,
  }

  interface PaletteOptions {
    primaryContainer?: string,
    rewardsYellow?: string,
    surface2?: string,
  }
}

export const DarkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#EDF354',
  },
  secondary: {
    main: '#EDF354',
  },
  background: {
    default: '#181817',
  },
  error: {
    main: '#FFA198',
  },
  success: {
    main: '#8FD460',
  },
  warning: {
    main: '#FFA940',
  },
  info: {
    main: '#8DCFF8',
  },
  divider: '#3F3E38',
};

export const LightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#67790F',
  },
  secondary: {
    main: '#141414',
  },
  background: {
    default: '#FCFCFC',
    paper: '#FFFFFF',
  },
  error: {
    main: '#D70D09',
  },
  success: {
    main: '#2F8F07',
  },
  warning: {
    main: '#FF9900',
  },
  info: {
    main: '#016FD5',
  },
  divider: '#0000001F',
  text: {
    disabled: '#BFBFBF',
    primary: '#141414',
    secondary: '#525252',
  },
  /** Custom Colors **/
  primaryContainer: alpha('#67790F', .18),
  rewardsYellow: '#FFDD56',
  surface2: '#F7F7F7',
};
