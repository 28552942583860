export interface CategoryReward {
  id: string,
  name: string,
  points: number,
  totalSpend: number,
}

export const buildRequirementTextForTier = (
  tier: { name: string, minimumDollars: number, minimumSegments: number },
  isFinalTier: boolean,
  includeNextTierText = true,
) => {
  const nextTierText = includeNextTierText
    ? `To ${isFinalTier ? 'maintain' : 'reach'} ${tier.name}: ` : '';

  const spendText = formatAmount(tier.minimumDollars);
  const spendHurdleText = tier.minimumDollars > 0 ? `Spend ${spendText}` : null;

  const categoryText = `${tier.minimumSegments} ${tier.minimumSegments === 1
    ? 'category'
    : 'categories'}`;
  const categoryHurdleText = tier.minimumSegments > 0 ? `Hurdle ${categoryText}` : null;

  const spendAndCategoryHurdleText = `${spendHurdleText} & hurdle ${categoryText}`;

  if (!categoryHurdleText && !spendHurdleText) {
    return '';
  }
  if (!categoryHurdleText && spendHurdleText) {
    return `${nextTierText}${spendHurdleText}`;
  }
  if (!spendHurdleText && categoryHurdleText) {
    return `${nextTierText}${categoryHurdleText}`;
  }
  return `${nextTierText}${spendAndCategoryHurdleText}`;
};

export const formatAmount = (amount: number, uom = "$") => {
  let formattedAmount;
  if (amount < 1e3) {
    formattedAmount = uom === "$" ? amount.toFixed(2) : amount.toFixed(4);
  } else if (amount < 1e6) {
    formattedAmount = (amount / 1e3).toFixed(1).replace(/\.?0+$/, '');
    formattedAmount = `${formattedAmount}k`;
  } else if (amount < 1e9) {
    formattedAmount = (amount / 1e6).toFixed(1).replace(/\.?0+$/, '');
    formattedAmount = `${formattedAmount}M`;
  } else {
    formattedAmount = amount.toFixed(2).replace(/\.?0+$/, '');
  }
  return uom === "$" ? `$${formattedAmount}` : `${formattedAmount} ${uom}`;
};

export const shouldHideZeroDollarTotalSpendForCategory = (categoryReward: CategoryReward) => {
  if (categoryReward.totalSpend !== 0) {
    return false;
  }
  const ZeroTotalSpendToHideForCategories = [
    'soybean',
    'corn',
    'grain',
  ];
  const regexPattern = new RegExp(ZeroTotalSpendToHideForCategories.join('|'), 'i');
  return regexPattern.test(categoryReward.name);
};
