import { Card, HSpacer, Text } from "@/components/DesignSystem";
import PricingRequestStatusChip from "@/components/shared/PricingRequestStatusChip";
import { ApiPricingRequest } from "@api/interfaces";
import { Stack } from "@mui/material";
import { getFarmerName } from '@shared/utilities';
import { ViewedBadge } from '@/components/shared/ViewedBadge';
import { PricingRequestStatus } from '@shared/enums';

interface PricingRequestItemProps {
  pricingRequest: ApiPricingRequest,
  onSelect: () => void,
}

const PricingRequestItem = (
  { onSelect, pricingRequest }: PricingRequestItemProps,
) => {
  const isOfferRead = !!pricingRequest.offers?.some(({ isRead }) => isRead);
  const showViewedBadge = (
    isOfferRead && [
      PricingRequestStatus.Review,
      PricingRequestStatus.Accepted,
      PricingRequestStatus.FinancingRequested,
      PricingRequestStatus.OnAccountRequested,
    ].includes(pricingRequest.status)
  );

  return (
    <Card testID={`pricing-request-${pricingRequest.id}`}>
      <Stack alignItems="center" direction="row" justifyContent="space-between" onClick={onSelect}>
        <Stack spacing={1}>
          <Text category="overline">{getFarmerName(pricingRequest.user, true)}</Text>
          <Text category="body-xlarge">Order #{pricingRequest.publicId}</Text>
        </Stack>
        <Stack direction="row">
          <PricingRequestStatusChip
            orderCompletedOn={pricingRequest.orderCompletedOn}
            status={pricingRequest.status}
          />
          <HSpacer size="3" />
          {showViewedBadge && (
            <>
              <HSpacer size="3" />
              <ViewedBadge
                testID={`pricing-request-${pricingRequest.publicId}-viewed-indicator`}
              />
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default PricingRequestItem;
