import {
  Search,
  SideSheet as ResponsiveSideSheet,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { ConfirmDialog } from '@/components/shared/ConfirmDialog';
import { ConfirmationDialog } from '@/constants/constant';
import { useViewFarmers } from '@/hooks/useViewFarmers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Divider, IconButton, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const ViewFarmers = ({
  isViewFarmers,
  setIsViewFarmers,
}: {
  isViewFarmers: boolean;
  setIsViewFarmers: (args: boolean) => void;
}): JSX.Element => {
  const { locationId = '' } = useParams();

  const {
    search,
    setSearch,
    setDeleteFarmerId,
    allFarmersData,
    showSearch,
    deleteFarmer,
    isDeleteFarmerLoading,
    deleteFarmerId,
  } = useViewFarmers(locationId);

  const [isDeleteFarmer, setIsDeleteFarmer] = useState(false);

  const getConstant = (): string => {
    if (allFarmersData?.total === 1 || allFarmersData?.total === 0) {
      return 'farmer';
    }
    return 'farmers';
  };

  const handleDeleteFarmer = (farmerId: string): void => {
    setDeleteFarmerId(farmerId);
    setIsDeleteFarmer(true);
  };

  const handleConfirmDelete = (): void => {
    deleteFarmer({ userId: deleteFarmerId, locationId });
    setIsDeleteFarmer(false);
  };

  return (
    <ResponsiveSideSheet
      hideScrollbar
      onClose={() => setIsViewFarmers(false)}
      onOpen={() => setIsViewFarmers(true)}
      open={isViewFarmers}
      testID='sidesheet'
      title='Farmers'
    >
      <Stack p='4px 12px 20px 24px'>
        {showSearch && (
          <Typography variant='body1'>
            Currently the Preferred Retailers for {allFarmersData?.total}{' '}
            {getConstant()}.
          </Typography>
        )}
        <VSpacer size='8' />
        <Stack spacing={2}>
          {showSearch && (
            <Search
              onChangeText={setSearch}
              testID='counties-modal-search'
              value={search}
            />
          )}
          {!!allFarmersData?.total && (
            <Text paddingTop={'24px'}>
              {allFarmersData?.data?.length} {getConstant()}
            </Text>
          )}
        </Stack>
      </Stack>
      {allFarmersData?.total === 0 && (
        <Typography
          sx={{
            fontSize: '16px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          variant='body1'
        >
          {showSearch
            ? 'Search result not found'
            : 'No Farmers available for this retailer'}
        </Typography>
      )}
      {allFarmersData?.data?.map((farmer) => (
        <React.Fragment key={farmer.id}>
          <Stack p='6px 2px 6px 14px'>
            <ListItem
              secondaryAction={
                <IconButton
                  data-testid='delete-farmer-icon'
                  onClick={() => handleDeleteFarmer(farmer.id)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              }
              sx={{ background: 'rgba(33,33,32,1)' }}
            >
              <ListItemText
                primary={`${farmer.firstName} ${farmer.lastName}`}
                sx={{ textTransform: 'capitalize', maxWidth: '90%' }}
              />
            </ListItem>
          </Stack>
          <Divider />
          {isDeleteFarmer && (
            <ConfirmDialog
              isLoading={isDeleteFarmerLoading}
              onCancel={() => setIsDeleteFarmer(false)}
              onConfirm={handleConfirmDelete}
              open={isDeleteFarmer}
              testID="delete-farmer-confirm-dialog"
              title={ConfirmationDialog.deleteFarmerTitle}
            >
              {ConfirmationDialog.deleteFarmerMessage}
            </ConfirmDialog>
          )}
        </React.Fragment>
      ))}
    </ResponsiveSideSheet>
  );
};

export default ViewFarmers;
