import {
  Button,
  Card,
  HSpacer,
  IconButton,
  InfoTooltip,
  Input,
  MenuItem,
  Modal,
  NumericInput,
  Select,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { RewardsLoyaltyValueInput } from '@/components/shared/RewardsLoyaltyValueInput';
import {
  Segment,
  Tier,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramModal';
import { ApiCategory } from '@api/interfaces';
import Add from '@mui/icons-material/Add';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import {
  CardActionArea,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material';
import { RewardsType } from '@shared/enums/RewardsType';
import { formatRewardsUoM, RewardsUom } from '@shared/enums/RewardsUom';
import { SharedConfig } from '@shared/SharedConfig';
import { Modify } from '@shared/utilities/UtilityTypes';
import { round } from 'lodash';
import React, { useMemo, useState } from 'react';

const { rewardsMaxTiers, rewardsMaxRewardPercent } = SharedConfig.settings;

type EditableSubcategoryRewards = Record<
  string,
  {
    hideOnDashboard?: boolean,
    loyaltyUom?: RewardsUom,
    rewardsType?: RewardsType,
    rewardsValue?: number,
  }
>;

type EditableSegment = Modify<
  Segment,
  {
    hideOnDashboard?: boolean,
    loyaltyUom?: RewardsUom | null,
    rewardsType?: RewardsType,
    rewardsValue?: number,
    subcategoryRewards?: EditableSubcategoryRewards,
  }
>;

type EditableTier = Modify<Tier,
    {
      minimumSegments?: number,
      segments: EditableSegment[],
    }
  > & {
    isDefault?: boolean,
  };

interface RewardsTiersModalProps {
  categories: ApiCategory[],
  existingTiers?: Tier[],
  onClose: () => void,
  onSave: (tiers: Tier[]) => void,
}

const isSegmentValid = (segment: EditableSegment) => {
  const { subcategoryRewards } = segment;

  if (!subcategoryRewards) {
    const isRewardsValueValid = segment.rewardsValue !== undefined && segment.rewardsValue >= 0
      && (segment.rewardsType !== RewardsType.Percent
        || segment.rewardsValue <= rewardsMaxRewardPercent * 100
      );
    const isRewardsUomValid = segment.uom === RewardsUom.Dollars
      || segment.loyaltyUom === RewardsUom.Dollars
      || segment.uom === segment.loyaltyUom;
    return isRewardsValueValid && isRewardsUomValid;
  }

  return Object.values(subcategoryRewards).every((rewards) => {
    const { rewardsValue } = rewards;
    const isRewardsValueValid = rewardsValue !== undefined
      && rewardsValue >= 0
      && (rewards.rewardsType !== RewardsType.Percent
        || rewardsValue <= rewardsMaxRewardPercent * 100
      );
    const isRewardsUomValid = segment.uom === RewardsUom.Dollars
      || rewards.loyaltyUom === RewardsUom.Dollars
      || segment.uom === rewards.loyaltyUom;
    return isRewardsValueValid && isRewardsUomValid;
  });
};

export const RewardsTiersModal = ({
  categories,
  existingTiers,
  onClose,
  onSave,
}: RewardsTiersModalProps) => {
  const theme = useTheme();
  const parentCategories = categories.filter(({ parentId }) => !parentId);
  const defaultSegments: EditableSegment[] = useMemo(() => parentCategories.map((category) => {
    const subcategories = categories.filter(({ parentId }) => parentId === category.id);
    const subcategoryRewards = subcategories.length
      ? Object.fromEntries(subcategories.map((subcategory) => ([
        subcategory.id,
        { rewardsType: RewardsType.Dollars, rewardsValue: undefined },
      ])))
      : undefined;

    return {
      categoryId: category.id,
      minimumHurdle: 0,
      rewardsType: subcategoryRewards ? undefined : RewardsType.Dollars,
      subcategoryRewards,
      uom: RewardsUom.Dollars,
    };
  }), [categories, parentCategories]);

  const defaultTier: EditableTier = {
    isDefault: true,
    minimumDollars: 0,
    minimumSegments: undefined,
    name: '',
    segments: defaultSegments,
  };

  const defaultNewTiers = [{ ...defaultTier, minimumSegments: 0 }];

  let initialTiers: EditableTier[] = existingTiers?.map((tier) => ({
    ...tier,
    isDefault: false,
    segments: defaultSegments.map((segment) => {
      const existingSegment = tier.segments.find((tierSegment) => (
        tierSegment.categoryId === segment.categoryId
      ));
      const hasSubcategories = (
        !!existingSegment?.subcategoryRewards && !!segment.subcategoryRewards
      );
      const isPercent = existingSegment?.rewardsType === RewardsType.Percent;
      return {
        ...segment,
        hideOnDashboard: existingSegment?.hideOnDashboard ?? segment.hideOnDashboard,
        loyaltyUom: existingSegment?.loyaltyUom ?? segment.loyaltyUom,
        minimumHurdle: existingSegment?.minimumHurdle ?? segment.minimumHurdle,
        rewardsType: existingSegment?.rewardsType ?? segment.rewardsType,
        rewardsValue: typeof existingSegment?.rewardsValue === 'number'
          && existingSegment.rewardsValue >= 0
          ? round(isPercent ? existingSegment.rewardsValue * 100 : existingSegment.rewardsValue, 4)
          : undefined,
        subcategoryRewards: hasSubcategories ? Object.fromEntries(
          Object.entries(segment.subcategoryRewards!).map(([categoryId, rewards]) => {
            const existingRewards = existingSegment?.subcategoryRewards?.[categoryId];
            const isPercent = existingRewards?.rewardsType === RewardsType.Percent;
            return [
              categoryId,
              {
                ...rewards,
                hideOnDashboard: existingRewards?.hideOnDashboard ?? rewards.hideOnDashboard,
                loyaltyUom: existingRewards?.loyaltyUom ?? rewards.loyaltyUom,
                rewardsType: existingRewards?.rewardsType ?? rewards.rewardsType,
                rewardsValue: typeof existingRewards?.rewardsValue === 'number'
                  && existingRewards.rewardsValue >= 0
                  ? round(
                    isPercent ? existingRewards.rewardsValue * 100 : existingRewards.rewardsValue,
                    4,
                  )
                  : undefined,
              },
            ];
          })) : segment.subcategoryRewards,
        uom: existingSegment?.uom ?? segment.uom,
      };
    }),
  })) ?? [];
  if (initialTiers.length === 0) {
    initialTiers = defaultNewTiers;
  }
  const [tiers, setTiers] = useState<EditableTier[]>(initialTiers);
  const [selectedTierIndex, setSelectedTierIndex] = useState<number>(0);

  const selectedTier = tiers.at(selectedTierIndex)!;

  let minimumSegmentsError: string = '';
  if (
    !!selectedTier.minimumSegments
    && selectedTier.minimumSegments > parentCategories.length
  ) {
    minimumSegmentsError = `Cannot exceed the total number of categories (${parentCategories.length})`;
  }

  const isDuplicateName = tiers.some((tier, i) => (
    i !== selectedTierIndex && tier.name === selectedTier.name
  ));

  const handleMoveTierUp = (index: number) => {
    if (index === 0) {
      return;
    }
    const newTiers = [...tiers];
    const selectedTier = newTiers[index];
    newTiers[index] = newTiers[index - 1];
    newTiers[index - 1] = selectedTier;
    setTiers(newTiers);

    setSelectedTierIndex(index - 1);
  };

  const handleMoveTierDown = (index: number) => {
    if (index === tiers.length - 1) {
      return;
    }
    const newTiers = [...tiers];
    const selectedTier = newTiers[index];
    newTiers[index] = newTiers[index + 1];
    newTiers[index + 1] = selectedTier;
    setTiers(newTiers);

    setSelectedTierIndex(index + 1);
  };

  const isValid = tiers.every((tier) => (
    !!tier.name
    && tier.minimumSegments !== undefined
    && !minimumSegmentsError
    && tier.segments.every(isSegmentValid)
  ));

  const handleAddTier = () => {
    const newTiers = [...tiers, { ...defaultTier, isDefault: false }];
    setTiers(newTiers);
    setSelectedTierIndex(tiers.length);
  };

  const handleDeleteTier = (index: number) => {
    setTiers(tiers.filter((_, i) => i !== index));
    if (selectedTierIndex >= index) {
      setTiers(tiers.filter((_, i) => i !== index));
      setSelectedTierIndex(selectedTierIndex - 1);
    }
  };

  const handleUpdateSelectedTier = (updates: Partial<EditableTier>) => {
    const newTiers = [...tiers];
    newTiers[selectedTierIndex] = {
      ...newTiers[selectedTierIndex],
      ...updates,
    };
    setTiers(newTiers);
  };

  const handleChangeSegment = (updated: EditableSegment) => {
    const newSegments = selectedTier.segments.map((segment) => {
      if (segment.categoryId === updated.categoryId) {
        return updated;
      } else {
        return segment;
      }
    });
    handleUpdateSelectedTier({ segments: newSegments });
  };

  const handleSave = () => {
    const tiersToSave = tiers.map((tier) => ({
      ...tier,
      segments: tier.segments.map((segment) => {
        const isPercent = segment.rewardsType === RewardsType.Percent;
        const rewardsValue = typeof segment.rewardsValue === 'number'
          && segment.rewardsValue >= 0
          && (
            isPercent ? round(segment.rewardsValue / 100, 5) : round(segment.rewardsValue, 4)
          );
        return ({
          ...segment,
          hideOnDashboard: segment.subcategoryRewards?.length ? null : segment.hideOnDashboard,
          loyaltyUom: segment.subcategoryRewards?.length ? null : segment.loyaltyUom,
          rewardsType: (segment.rewardsType && !segment.subcategoryRewards)
            ? segment.rewardsType
            : null,
          rewardsValue: (typeof segment.rewardsValue === 'number'
            && segment.rewardsValue >= 0
            && !segment.subcategoryRewards)
            ? rewardsValue
            : null,
          subcategoryRewards: segment.subcategoryRewards && Object.fromEntries(
            Object.entries(segment.subcategoryRewards)
              .map(([id, rewards]) => {
                const isPercent = rewards.rewardsType === RewardsType.Percent;
                const rewardsValue = rewards.rewardsValue && (
                  isPercent ? round(rewards.rewardsValue / 100, 5) : round(rewards.rewardsValue, 4)
                );
                return [
                  id,
                  {
                    hideOnDashboard: rewards.hideOnDashboard,
                    loyaltyUom: rewards.loyaltyUom,
                    rewardsType: rewards.rewardsType ?? null,
                    rewardsValue: rewardsValue ?? null,
                  },
                ];
              }),
          ),
        });
      }),
    })) as Tier[];
    onSave(tiersToSave);
  };

  const TierSelector = (
    <Stack minWidth="380px">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="body-large">
          {tiers.length}/{rewardsMaxTiers} tiers
        </Text>
        <Button
          disabled={tiers.length >= rewardsMaxTiers}
          onClick={handleAddTier}
          startIcon={<Add />}
          testID="tiers-modal-add-tier-button"
          variant="text"
        >
          Add
        </Button>
      </Stack>
      <List sx={{ paddingX: '1px' }}>
        {tiers.map((tier, i) => (
          <>
            <ListItem
              key={i}
              onClick={() => setSelectedTierIndex(i)}
              secondaryAction={(tier.isDefault || tiers.length === 1) ? (
                <Text category="label-medium">
                  Default
                </Text>
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    handleDeleteTier(i);
                    e.stopPropagation();
                  }}
                  testID={`tiers-modal-delete-tier-button-${i}`}
                >
                  <DeleteOutline />
                </IconButton>
              )}
              sx={[
                {
                  bgcolor: theme.palette.background.paper,
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                  height: '56px',
                },
                i === selectedTierIndex
                  ? { outline: `1px solid ${theme.palette.primary.main}` }
                  : { cursor: 'pointer' },
              ]}
            >
              <Stack alignItems="center" direction="row">
                <Stack>
                  <IconButton
                    color="inherit"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMoveTierUp(i);
                    }}
                    sx={{
                      height: "20px",
                      width: "20px",
                    }}
                    testID="move-tier-up"
                  >
                    <ArrowDropUp />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMoveTierDown(i);
                    }}
                    sx={{
                      height: "20px",
                      width: "20px",
                    }}
                    testID="move-tier-down"
                  >
                    <ArrowDropDown />
                  </IconButton>
                </Stack>
                <HSpacer size="3" />
                <ListItemText
                  primary={tier.name || 'Untitled'}
                />
              </Stack>
            </ListItem>
            {i < tiers.length - 1 && (
              <VSpacer size="3" />
            )}
          </>
        ))}
      </List>
    </Stack>
  );

  const TierEditor = (
    <Card sx={{ maxHeight: 570, overflowY: 'auto', width: '100%' }} testID="tiers-modal-editor">
      <Stack alignItems="center" direction="row">
        <Text category="headline-small">
          Details
        </Text>
        <HSpacer size="3"/>
        <InfoTooltip>
          <>
            <Text category='title-small'>
              The 'Tier name' must be unique to this retailer rewards program.
              The 'Minimum Total Spend' will require a customer to spend the specified amount
              before they can access the rewards tier and begin earning loyalty points.
              The ‘Minimum Total Segments’ will require a customer to reach the category hurdle
              for this specified number of segments before they can access the rewards tier
              and begin earning loyalty points.
            </Text>
            <br/>
            <Text category='title-small'>
              The category hurdle uom and its associated loyalty values must have the same uom
              or be based on 'dollars'.
            </Text>
          </>
        </InfoTooltip>
      </Stack>
      <VSpacer size="5"/>
      <Input
        error={isDuplicateName}
        helperText={isDuplicateName && 'Tier name must be unique'}
        label="Tier Name"
        onChangeText={(name) => handleUpdateSelectedTier({ name })}
        testID="tiers-modal-name-input"
        value={selectedTier.name}
      />
      <VSpacer size="7"/>
      <Stack direction="row">
        <NumericInput
          decimals={2}
          label="Minimum total spend ($)"
          minValue={0}
          onChangeNumber={(minimumDollars) => handleUpdateSelectedTier({ minimumDollars })}
          prefix="$"
          showFixedDecimals
          testID="tiers-modal-minimum-dollars-input"
          value={selectedTier.minimumDollars}
        />
        <HSpacer size="5"/>
        <NumericInput
          decimals={0}
          error={!!minimumSegmentsError}
          helperText={minimumSegmentsError}
          label="Minimum total segments"
          minValue={0}
          onChangeNumber={(minimumSegments) => handleUpdateSelectedTier({ minimumSegments })}
          testID="tiers-modal-minimum-segments-input"
          value={selectedTier.minimumSegments}
        />
      </Stack>
      <VSpacer size="5"/>
      <Divider/>
      <VSpacer size="5"/>
      <Stack alignItems="center" direction="row">
        <Text category="headline-small">
          Segments
        </Text>
        <HSpacer size="3"/>
        <InfoTooltip>
          Each of the retailer's product categories are listed below.
          The minimum hurdle and loyalty percentage must be set for each product category.
          The only exception is that a minimum hurdle value is not allowed to be entered
          for the default tier within the program
        </InfoTooltip>
      </Stack>
      <VSpacer size="5"/>
      <Stack gap="16px">
        {selectedTier.segments.map((segment) => {
          const category = categories.find(({ id }) => id === segment.categoryId)!;
          const subcategories = categories.filter(({ parentId }) => parentId === category.id);
          return (
            <SegmentEditor
              category={category}
              key={category.id + selectedTier.name}
              onChangeSegment={handleChangeSegment}
              segment={segment}
              subcategories={subcategories}
              testID={`tiers-modal-segment-${category.id}`}
            />
          );
        })}
      </Stack>
    </Card>
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={() => {
            onClose();
            handleSave();
          }}
          testID="tiers-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
          testID="tiers-modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      largeModal
      onClose={onClose}
      open
      testID="tiers-modal"
      title="Add/Edit Tiers"
    >
      <Text>
        Create the rewards tiers that will be offered to the customers
      </Text>
      <VSpacer size="7" />
      <Text>
        A minimum of 1 tier is required. All fields are required.
      </Text>
      <VSpacer size="7" />
      <Stack direction="row">
        {TierSelector}
        <HSpacer size="7" />
        {TierEditor}
      </Stack>
    </Modal>
  );
};

interface SegmentEditorProps {
  category: ApiCategory,
  onChangeSegment: (segment: EditableSegment) => void,
  segment: EditableSegment,
  subcategories: ApiCategory[],
  testID: string,
}

const SegmentEditor = ({
  category,
  onChangeSegment,
  segment,
  subcategories,
  testID,
}: SegmentEditorProps) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isValid = isSegmentValid(segment);

  const handleUpdate = (updates: Partial<EditableSegment>) => {
    onChangeSegment({ ...segment, ...updates });
  };

  const handleUpdateSubcategory = (
    categoryId: string,
    updates: {
      hideOnDashboard?: boolean,
      loyaltyUom?: RewardsUom,
      rewardsType?: RewardsType,
      rewardsValue?: number,
    },
  ) => {
    const subcategoryRewards = {
      ...segment.subcategoryRewards!,
      [categoryId]: {
        ...segment.subcategoryRewards![categoryId],
        ...updates,
      },
    };
    handleUpdate({ subcategoryRewards });
  };

  return (
    <Card
      sx={{ backgroundColor: theme.palette.background.default, borderRadius: '8px' }}
      testID={testID}
    >
      <>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <CardActionArea
            disableRipple
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ '.MuiCardActionArea-focusHighlight': { backgroundColor: 'transparent' } }}
          >
            <Stack alignItems="center" direction="row" onClick={() => setIsExpanded(!isExpanded)}>
              <IconButton testID={`${testID}-expand-button`}>
                {isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
              <HSpacer size="3" />
              <Text category="headline-small">
                {category.name}
              </Text>
              {isValid && (
                <>
                  <HSpacer size="3" />
                  <CheckCircleOutline
                    sx={{ color: theme.palette.success.main, height: '24px', width: '24px' }}
                  />
                </>
              )}
            </Stack>
          </CardActionArea>
          <Stack direction="row">
            <NumericInput
              decimals={segment.uom === RewardsUom.Dollars ? 2 : 4}
              label="Minimum hurdle"
              minValue={0}
              onChangeNumber={(minimumHurdle) => handleUpdate(
                { minimumHurdle: minimumHurdle || 0 },
              )}
              prefix={segment.uom === RewardsUom.Dollars ? '$' : undefined}
              sx={{ width: 230 }}
              testID={`${testID}-minimum-hurdle`}
              value={segment.minimumHurdle}
            />
            <HSpacer size="5" />
            <Select
              label="uom"
              onChangeValue={(uom) => {
                const updates: Partial<EditableSegment> = { uom: uom as RewardsUom };
                if (segment.uom !== uom) {
                  if (segment.subcategoryRewards) {
                    updates.subcategoryRewards = Object.fromEntries(
                      Object.entries(segment.subcategoryRewards).map(([id, rewards]) => [
                        id,
                        {
                          ...rewards,
                          rewardsType: RewardsType.Dollars,
                          ...(updates.uom !== RewardsUom.Dollars && { loyaltyUom: updates.uom }),
                        },
                      ]),
                    );
                  } else {
                    updates.rewardsType = RewardsType.Dollars;
                    if (updates.uom !== RewardsUom.Dollars) {
                      updates.loyaltyUom = updates.uom;
                    }
                  }
                }
                handleUpdate(updates);
              }}
              testID={`${testID}-uom-select`}
              value={segment.uom}
              width={120}
            >
              {Object.values(RewardsUom).map((uom) => (
                <MenuItem key={uom} testID={`${testID}-uom-select-item-${uom}`} value={uom}>
                  {formatRewardsUoM(1, uom)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
        {isExpanded && (
          <>
            <VSpacer size="7" />
            <Stack gap="16px">
              {subcategories.length > 0 ? (
                subcategories.map((subcategory) => (
                  <RewardsLoyaltyValueInput
                    categoryName={subcategory.name}
                    disableUomSelector={segment.uom !== RewardsUom.Dollars}
                    hideOnDashboard={
                      segment.subcategoryRewards?.[subcategory.id].hideOnDashboard ?? false
                    }
                    innerContainerWidth="73%"
                    key={subcategory.id}
                    onClickHideOnDashboard={(value) => (
                      handleUpdateSubcategory(
                        subcategory.id,
                        { ...segment.subcategoryRewards?.[subcategory.id], hideOnDashboard: value },
                      )
                    )}
                    onUpdate={(updates) => (
                      handleUpdateSubcategory(
                        subcategory.id,
                        {
                          hideOnDashboard: updates.hideOnDashboard,
                          loyaltyUom: updates.uom,
                          rewardsType: updates.rewardType,
                          rewardsValue: updates.value,
                        },
                      )
                    )}
                    rewardType={segment.subcategoryRewards?.[subcategory.id].rewardsType}
                    testID={`${testID}-category-input-${subcategory.id}`}
                    uom={segment.subcategoryRewards?.[subcategory.id].loyaltyUom ?? segment.uom}
                    value={segment.subcategoryRewards?.[subcategory.id].rewardsValue}
                  />
                ))
              ) : (
                <RewardsLoyaltyValueInput
                  disableUomSelector={segment.uom !== RewardsUom.Dollars}
                  hideOnDashboard={segment.hideOnDashboard ?? false}
                  innerContainerWidth="73%"
                  onClickHideOnDashboard={(value) => (
                    handleUpdate({ ...segment, hideOnDashboard: value })
                  )}
                  onUpdate={(updates) => (
                    handleUpdate(
                      {
                        hideOnDashboard: updates.hideOnDashboard,
                        loyaltyUom: updates.uom,
                        rewardsType: updates.rewardType,
                        rewardsValue: updates.value,
                      },
                    )
                  )}
                  rewardType={segment.rewardsType}
                  testID={`${testID}-category-input-${category.id}`}
                  uom={segment.loyaltyUom ?? segment.uom}
                  value={segment.rewardsValue}
                />
              )}
            </Stack>
          </>
        )}
      </>
    </Card>
  );
};
