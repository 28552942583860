import { Card, HSpacer, Switch, Text } from '@/components/DesignSystem';
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';
import { ConfirmDialog } from '@/components/shared/ConfirmDialog';
import { AppConfig } from '@/constants/AppConfig';
import { ConfirmationDialog } from '@/constants/constant';
import { stringifyCount } from '@/constants/HierarchyOfRetailers';
import { Routes } from '@/constants/Routes';
import { useEditRetailer } from '@/hooks/useHierarchyOfRetailers';
import { ApiRetailer } from '@api/interfaces';
import Store from '@mui/icons-material/Store';
import { Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RetailerOverviewCard = ({ retailer }: { retailer: ApiRetailer }) => {
  const navigate = useNavigate();
  const [changeIsActive, setChangeIsActive] = useState(false);
  const detailsNavigator = () => {
    navigate(`${Routes.ADMIN_HIERARCHY_OF_RETAILERS}/${retailer?.id}`, {
      state: { retailer },
    });
  };
  const theme = useTheme();

  const { editRetailer, isLoading } = useEditRetailer(retailer?.id, setChangeIsActive);

  const logoSrc = retailer.image && `${AppConfig.staticImageHost}/${retailer.image}`;

  return (
    <Card
      elevation={4}
      onClick={detailsNavigator}
      sx={{ cursor: 'pointer' }}
      testID="retailer-overview-card"
    >
      <Stack
        alignItems={'center'}
        direction='row'
        justifyContent={'space-between'}
      >
        <Stack direction="row">
          <Avatar
            alt={`${retailer?.name ?? 'retailer'} logo`}
            height={40}
            src={logoSrc ?? undefined}
            width={40}
          >
            {logoSrc ? null : (
              <Store sx={{ height: 30, width: 30 }} />
            )}
          </Avatar>
          <HSpacer size="5" />
          <Stack spacing={0.5}>
            <Text category="overline" sx={{ color: theme.palette.text.secondary }}>
              ID: {retailer?.id}
            </Text>
            <Text category="body-xlarge">{retailer?.name}</Text>
            <Text category="body-medium" sx={{ color: theme.palette.text.secondary }}>
              {stringifyCount(retailer?.locationDetails?.length, 'location', 'locations')} •{' '}
              {stringifyCount(retailer?.memberDetails?.length, 'member', 'members')}
            </Text>
          </Stack>
        </Stack>
        <Switch
          checked={retailer?.isActive}
          label={retailer?.isActive ? 'Active' : 'Inactive'}
          labelPlacement='start'
          onClick={(e) => {
            e.stopPropagation();
            setChangeIsActive(true);
          }}
          sx={{ marginRight: '0px' }}
          testID='active-toggle'
        />
      </Stack>
      {changeIsActive && (
        <ConfirmDialog
          isLoading={isLoading}
          onCancel={() => setChangeIsActive(false)}
          onConfirm={() => {
            editRetailer({ isActive: !retailer?.isActive });
            setChangeIsActive(false);
          }}
          open={changeIsActive}
          testID="change-active-confirm-dialog"
          title={
            retailer?.isActive
              ? ConfirmationDialog.inActivateRetailerTitle
              : ConfirmationDialog.activateRetailerTitle
          }
        >
          {retailer?.isActive
            ? ConfirmationDialog.inActivateRetailerMessage
            : ConfirmationDialog.activateRetailerMessage}
        </ConfirmDialog>
      )}
    </Card>
  );
};

export default RetailerOverviewCard;
