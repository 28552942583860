import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import PricingRequestStatusChip from '@/components/shared/PricingRequestStatusChip';
import { ViewedBadge } from '@/components/shared/ViewedBadge';
import { productRequestConstants } from '@/constants/ProductRequest';
import { Card, Stack } from '@mui/material';
import { PricingRequestStatus } from '@shared/enums';

interface PricingRequestDetailsCardProps {
  isOfferRead?: boolean,
  orderCompletedOn?: Date | null,
  publicId?: number,
  status?: PricingRequestStatus,
}

const PricingRequestDetailsCard = ({
  isOfferRead,
  orderCompletedOn,
  publicId,
  status,
}: PricingRequestDetailsCardProps) => {
  const showViewedBadge = (
    isOfferRead && [
      PricingRequestStatus.Review,
      PricingRequestStatus.Accepted,
      PricingRequestStatus.FinancingRequested,
      PricingRequestStatus.OnAccountRequested,
    ].includes(status as PricingRequestStatus)
  );

  return (
    <Card sx={{ padding: '20px' }}>
      <Text
        category='overline'
        testID='card-text'
      >
        {productRequestConstants.pricingRequest}
      </Text>
      <VSpacer size='5' />
      <Text fontSize='28px' testID='card-text'>
        {`#${publicId}`}
      </Text>
      <VSpacer size='5' />
      <Stack direction="row">
        <PricingRequestStatusChip
          orderCompletedOn={orderCompletedOn}
          status={status ?? PricingRequestStatus.Open}
        />
        {showViewedBadge && (
          <>
            <HSpacer size="3" />
            <ViewedBadge testID={`pricing-request-${publicId}-viewed-indicator`} />
          </>
        )}
      </Stack>
    </Card>
  );
};

export default PricingRequestDetailsCard;
