import { Toolbar, VSpacer } from '@/components/DesignSystem';
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import ERPRetailerSelector from "@/components/shared/ListSelector/ERPRetailerSelector";
import { QueryKeys } from '@/constants/QueryKeys';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useCategoryList, useGetRetailerList, useManufacturerList } from '@/hooks/useProductQuery';
import { useSearch } from "@/hooks/useSearch";
import { ProductApi } from '@/utilities/api/ProductApi';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { RetailerEndpoint } from '@api/endpoints';
import { ApiCategory, ApiManufacturer, ApiPromotion, ApiRetailer } from '@api/interfaces';
import { Alert, Container, Pagination, Stack } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { formatDateOnly } from '@shared/utilities';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import ProductListItem from './ProductListItem';

export interface ProductData {
  activeIngredientsIds: string[],
  alternativeProducts: object[],
  categoryId: string,
  companionProducts?: object[],
  description?: string,
  id: string,
  image: string,
  isFeatured: boolean,
  isRestrictedUse: boolean,
  keywords: string[],
  manufacturer: string,
  name: string,
  packageSize: string[],
  productCrops: string[],
  productPractices: string[],
  productPrimaryNutrients?: string[],
  productRetailers: string[],
  productSubcategories: string[],
  purchaseUom: string[],
  retailersId: string[],
  sellSheet: string,
  subcategoryA1?: string,
}

const getAllData =
  (data: (
    ApiCategory[] | ApiManufacturer[] | RetailerEndpoint.RetailerList.Response | undefined)) => {
    const list: { id: string; label: string }[] = [];
    data && data?.map((item: { name: string | null ; id: string }) => {
      list.push({
        id: item.id,
        label: item.name ?? '',
      });
    });
    return list;
  };

const filterData = (
  categoryList?: ApiCategory[],
  partnerRetailerList?: RetailerEndpoint.RetailerList.Response,
  manufacturerList?: ApiManufacturer[],
  selectedRetailer?: ApiRetailer,
  isInternal?: boolean,
) => {
  const filters: Filter[] = [
    {
      id: 'sortDesc',
      label: 'Sort By',
      options: [
        { id: 'false', label: 'A-Z', default: false },
        { id: 'true', label: 'Z-A', default: false },
      ],
      selectionMethod: 'single-select',
    },
    {
      id: 'categoryId',
      label: 'Category',
      options: getAllData(categoryList),
      selectionMethod: 'single-select',
    },
    {
      id: 'manufacturerId',
      label: 'Manufacturer',
      options: getAllData(manufacturerList),
      selectionMethod: 'single-select',
    },
  ];
  if (!selectedRetailer && isInternal) {
    filters.push({
      id: 'retailerId',
      label: 'Retailer',
      options: getAllData(partnerRetailerList),
      selectionMethod: 'single-select',
    });
  }
  return filters;
};

const ProductList = () => {
  const [errorMessage] = useState('');
  const { isInternal, retailer } = useAuthentication();
  const { retailerList } = useGetRetailerList({ hasErpProducts: false }, { enabled: isInternal });
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>(retailer);
  const { manufacturerList } = useManufacturerList({
    ...(selectedRetailer && { retailerId: selectedRetailer.id }),
    hasErpProducts: !!selectedRetailer,
  });
  const { categoryList } = useCategoryList({
    ...(selectedRetailer && { retailerId: selectedRetailer.id }),
  });
  const [page, setPage] = useState(0);
  const { setSearch, debouncedSearch } = useSearch(3);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const currentDate = formatDateOnly(new Date());
  const getPromotions = useCallback((productId: string, promotions: ApiPromotion[]) => (
    promotions.filter(
      (promotion) => (
        promotion.targetUserType === PromotionTargetUserType.Farmer
        && promotion.productIdsForEarningPoints.includes(productId)
        && promotion.endDate >= currentDate
      ),
    ) ?? []
  ), [currentDate]);

  const filtersList: Record<string, string | boolean> = Array.from(
    filterSelections?.entries() ?? [],
  )
    .map(([filterId, selection]) => {
      return {
        filterId,
        value: Array.from(selection).join(', '),
      };
    })
    .reduce((obj, filter) => {
      obj[filter.filterId] = filter.value;
      return obj;
    }, {} as Record<string, string | boolean>);

  const query = {
    ...filtersList,
    hasExternalId: !!selectedRetailer,
    page,
    ...(selectedRetailer && { retailerId: [selectedRetailer.id] }),
    search: debouncedSearch,
  };

  const { data: productListData } = useQuery(
    [QueryKeys.GET_PRODUCT, query],
    () => ProductApi.productListData(query),
  );

  const productIds = productListData?.data.map(
    (product) => product.id,
  ).filter(Boolean) as string[] ?? [];

  const { data: promotions } = useQuery(
    [QueryKeys.GET_PROMOTIONS, productIds],
    async () => PromotionApi.list({
      isActive: true,
      productIds: productIds,
    }),
    {
      enabled: !!productIds.length,
    },
  );

  const memoizedFilters = useMemo(
    () => filterData(categoryList, retailerList, manufacturerList, selectedRetailer, isInternal),
    [categoryList, isInternal, retailerList, manufacturerList, selectedRetailer],
  );

  useEffect(() => {
    setPage(0);
  }, [debouncedSearch, filterSelections]);

  const onRetailerSelected = (retailer?: ApiRetailer) => {
    if (retailer?.id !== selectedRetailer?.id) {
      setFilterSelections(new Map());
      setSelectedRetailer(retailer);
    }
  };

  return (
    <Container maxWidth='lg'>
      <VSpacer size="8" />
      <ERPRetailerSelector
        hasErpProducts={true}
        header='Products'
        onSelect={onRetailerSelected}
      />
      <VSpacer size="6" />
      <Stack alignItems='center' direction='row' justifyContent='space-between'>
        <Toolbar
          filterSelections={filterSelections}
          filters={memoizedFilters}
          hideAllFilter={true}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            setFilterSelections(selections);
          }}
          retainSelectionsOnFilterChange
          testID='searchAndFilter'
          totalItems={productListData?.total ?? 0}
          totalUnit="product"
        />
      </Stack>
      {!!errorMessage && (
        <>
          <Alert color='error' icon={false}>
            {errorMessage}
          </Alert>
          <VSpacer mobileSize='5' size='8' />
        </>
      )}
      <VSpacer size='5' />
      {productListData?.data?.map((item) => (
        <Fragment key={item.id}>
          <ProductListItem
            productListData={{
              externalId: item.externalId,
              id: item.id,
              manufacturerName: (item.manufacturer?.name ?? item.manufacturer?.id),
              name: item.name ?? '',
            }}
            promotions={getPromotions(item.id, promotions?.data ?? [])}
          />
          <VSpacer size="5" />
        </Fragment>
      ))}
      <VSpacer size='3' />
      {productListData && productListData.lastPage !== 0 && (
        <Stack alignItems='center'>
          <Pagination
            count={productListData.lastPage + 1}
            onChange={(_, page) => {
              setPage(page - 1);
            }}
            page={productListData.page + 1}
          />
        </Stack>
      )}
      <VSpacer size='14' />
      <Stack>
        {productListData?.data && productListData?.data.length <= 0 && (
          <Stack sx={{ textAlign: 'center' }}>
            No products yet
            <VSpacer size='14' />
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default ProductList;
