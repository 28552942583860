import { Text, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { Routes } from '@/constants/Routes';
import AdminList from '@/pages/Admin/AdminList/AdminList';
import FeatureFlags from '@/pages/Admin/FeatureFlags';
import {
  LocationDetailsOverviewPage,
  RetailerDetailsOverviewPage,
  RetailerListPage,
} from '@/pages/Admin/HierarchyOfRetailers';
import AdminHomePage from '@/pages/Admin/HomePage/AdminHomePage';
import { ManageNotifications } from '@/pages/Admin/ManageNotifications/ManageNotifications';
import ManufacturerDetails from '@/pages/Admin/Manufacturers/ManufacturerDetails';
import ManufacturerList from '@/pages/Admin/Manufacturers/ManufacturerList';
import { Notifications } from '@/pages/Admin/Notifications/Notifications';
import OrdersOverview from "@/pages/Admin/PricingRequestList/OrdersOverview";
import PricingRequest from '@/pages/Admin/PricingRequestList/PricingRequest';
import ViewDetails from '@/pages/Admin/PricingRequestList/ViewDetails';
import { ProductEvents } from '@/pages/Admin/ProductEvents';
import ProductRecommendation from '@/pages/Admin/ProductRecommendation/ProductRecommendation';
import ProductRecommendationView
  from '@/pages/Admin/ProductRecommendation/ProductRecommendationView';
import { BenefitsPage } from '@/pages/CustomerDashboard/BenefitsPage';
import { CustomerDashboard } from '@/pages/CustomerDashboard/CustomerDashboard';
import { HowItWorksPage } from '@/pages/CustomerDashboard/HowItWorksPage';
import { PromotionsPage } from '@/pages/CustomerDashboard/Promotions/PromotionsPage';
import { WalletPage } from '@/pages/CustomerDashboard/WalletPage';
import CustomerDetails from '@/pages/FarmerList/CustomerDetails';
import CustomerList from '@/pages/FarmerList/CustomerList';
import ProductList from '@/pages/Product/ProductList';
import { Promotions } from '@/pages/Promotions/Promotions';
import { UIShellDemo } from '@/pages/UXSandbox';
import { UXSandbox } from '@/pages/UXSandbox/UXSandbox';
import { AppHeader } from '@/components/shared/AppHeader';
import { RetailerAppFooter } from '@/views/RetailerAppFooter';
import React, { ReactNode } from 'react';
import { Navigate, Route, Routes as AppRoutes } from 'react-router-dom';

export const InternalView = () => {
  const createLayout = (element: ReactNode) => {
    return (
      <>
        <AppHeader />
        <VSpacer size="11" />
        <DesktopOnly>
          {element}
        </DesktopOnly>
        <MobileOnly>
          <Text>Please switch to desktop to use the admin console</Text>
        </MobileOnly>
        <VSpacer size="11" />
        <RetailerAppFooter />
      </>
    );
  };

  const createUXLayout = (element: ReactNode) => {
    return (
      <>
        <AppHeader />
        <VSpacer size="11" />
        {element}
      </>
    );
  };

  return (
    <AppRoutes>
      <Route
        element={createLayout(<AdminList />)}
        path={Routes.ADMIN_LIST_ADMINS}
      />
      <Route
        element={createLayout(<AdminHomePage />)}
        path={Routes.ADMIN_HOME_PAGE}
      />
      <Route
        element={createLayout(<CustomerList />)}
        path={Routes.CUSTOMERS}
      />
      <Route
        element={createLayout(<FeatureFlags />)}
        path={Routes.FEATURE_FLAGS}
      />
      <Route
        element={createLayout(<ManufacturerList />)}
        path={Routes.ADMIN_LIST_MANUFACTURERS}
      />
      <Route
        element={createLayout(<ManufacturerDetails />)}
        path={`${Routes.ADMIN_LIST_MANUFACTURERS}/:id`}
      />
      <Route
        element={createLayout(<OrdersOverview />)}
        path={Routes.ADMIN_LIST_ORDERS}
      />
      <Route
        element={createLayout(<ProductEvents />)}
        path={Routes.ADMIN_PRODUCT_EVENTS}
      />
      <Route
        element={createLayout(<ProductRecommendation />)}
        path={Routes.ADMIN_PRODUCT_RECOMMENDATION}
      />
      <Route
        element={createLayout(<ManageNotifications />)}
        path={Routes.ADMIN_MANAGE_NOTIFICATIONS}
      />
      <Route
        element={createLayout(<ProductRecommendationView />)}
        path={Routes.ADMIN_PRODUCT_RECOMMENDATION_VIEW}
      />
      <Route
        element={createLayout(<RetailerListPage />)}
        path={Routes.ADMIN_HIERARCHY_OF_RETAILERS}
      />
      <Route
        element={createLayout(
            <RetailerDetailsOverviewPage />,
        )}
        path={`${Routes.ADMIN_HIERARCHY_OF_RETAILERS}/:id`}
      />
      <Route
        element={createLayout(
            <LocationDetailsOverviewPage />,
        )}
        path={`${Routes.ADMIN_HIERARCHY_OF_RETAILERS}/:id/location/:locationId`}
      />
      <Route element={createUXLayout(<UXSandbox />)} path={Routes.UX_SANDBOX}>
        <Route element={createUXLayout(<UXSandbox />)} path=':componentName' />
      </Route>
      <Route element={<UIShellDemo showDemo />} path={Routes.UI_SHELL_DEMO} />
      <Route
        element={<Navigate to={Routes.CUSTOMERS} />}
        path={'*'}
      />
      <Route
        element={createUXLayout(<PricingRequest />)}
        path={`${Routes.PRICING_REQUEST}/:id`}
      />
      <Route
        element={createUXLayout(<ViewDetails />)}
        path={`${Routes.VIEW_DETAIL}/:id`}
      />
      <Route element={createLayout(<ProductList />)} path={Routes.PRODUCTS} />
      <Route
        element={createLayout(<Promotions />)}
        path={Routes.PROMOTIONS}
      />
      <Route
        element={createLayout(<Notifications />)}
        path={Routes.ADMIN_LIST_NOTIFICATIONS}
      />
      <Route
        element={<CustomerDashboard />}
        path={Routes.CUSTOMER_DASHBOARD}
      />
      <Route
        element={<BenefitsPage />}
        path={Routes.CUSTOMER_DASHBOARD_BENEFITS}
      />
      <Route
        element={<HowItWorksPage />}
        path={Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS}
      />
      <Route
        element={<WalletPage />}
        path={Routes.CUSTOMER_DASHBOARD_WALLET}
      />
      <Route
        element={<PromotionsPage />}
        path={Routes.CUSTOMER_DASHBOARD_PROMOTIONS}
      />
      <Route element={createLayout(<CustomerDetails />)} path={Routes.CUSTOMER_DETAILS} />
    </AppRoutes>
  );
};
