import { Divider, Stack } from "@mui/material";
import { Button, Checkbox, HSpacer, Modal, Text, VSpacer } from "@/components/DesignSystem";
import React from "react";
import { PromotionInputs } from "@/pages/Promotions/PromotionModal/PromotionModal";
import { ApiRetailer, ApiRetailerLocation } from "@api/interfaces";

interface EditLocationsModalProps {
  onClose: () => void;
  onPromotionChanged: (promotion: PromotionInputs) => void;
  promotion: PromotionInputs;
  retailerLocations?: ApiRetailerLocation[];
  selectedRetailer: ApiRetailer,
}

export const EditLocationsModal = ({
  onClose,
  onPromotionChanged,
  promotion,
  retailerLocations,
  selectedRetailer,
} : EditLocationsModalProps) => {
  return <Modal
    disableBackdropClick
    onClose={onClose}
    open
    testID='farmer-license-modal'
    title={`${selectedRetailer.name} locations`}
  >
    {!!retailerLocations && retailerLocations.map(location => (
      <Stack key={location.id}>
        <Divider />
        <VSpacer size="5" />
        <Stack alignItems="center" direction="row">
          <HSpacer size="5" />
          <Checkbox
            checked={promotion.locationIds?.includes(location.id)}
            onChangeChecked={(checked) => {
              const updatedSet = new Set(promotion.locationIds ?? []);
              if (checked) {
                updatedSet.add(location.id);
              } else {
                updatedSet.delete(location.id);
              }
              onPromotionChanged({ ...promotion, locationIds: [...updatedSet] });
            }}
            testID={`set-selected-location-checkbox-id-${location.id}`}
          >
            <Text>
              {location.name}
            </Text>
          </Checkbox>
        </Stack>
        <VSpacer size="5" />
      </Stack>
    ))}
    <Divider />
    <VSpacer size="7" />
    <Stack direction="row" justifyContent="space-between">
      <Button
        disabled={retailerLocations?.length === 1}
        onClick={() => {
          onPromotionChanged({ ...promotion, locationIds: [] });
        }}
        testID="location-modal-deselect-all-locations-button"
        variant="text"
      >
        Deselect all
      </Button>
      <Button
        disabled={!promotion.locationIds?.length}
        onClick={onClose}
        testID="location-modal-done-button"
      >
        Done
      </Button>
    </Stack>
  </Modal>;
};
