import {
  Input,
  PhoneInput,
  Text,
  VSpacer,
  ZipInput,
} from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import Square from '@mui/icons-material/Square';
import { isValidPhoneNumber } from '@shared/utilities';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';
import { Box, Stack } from '@mui/material';

export const InputDemo = () => {

  const [text, setText] = useState("");
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);

  return (
    <Box padding={1} sx={{ backgroundColor: "background.default" }}>
      <DemoBlock label="Basic">
        <Input
          label="Label"
          onChangeText={setText}
          testID="input-demo-basic"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Required">
        <Input
          label="Label"
          onChangeText={setText}
          required
          testID="input-demo-required"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Disabled">
        <Input
          disabled
          label="Label"
          onChangeText={setText}
          testID="input-demo-disabled"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Error">
        <Input
          error
          helperText="The specified name already exists"
          label="Label"
          onChangeText={setText}
          testID="input-demo-error"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Leading Icon">
        <Input
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-leading-icon"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Trailing Icon">
        <Input
          label="Label"
          onChangeText={setText}
          testID="input-demo-trailing-icon"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Leading and Trailing Icon">
        <Input
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-leading-and-trailing-icon"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Disabled">
        <Input
          disabled
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-trailing-icon-disabled"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Leading and Trailing Icon Error">
        <Input
          error
          helperText="The specified name already exists"
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-leading-and-trailing-icon"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Phone">
        <Stack>
          <PhoneInput
            error={isInvalidPhone}
            label="Label"
            onChangeText={(phone) => {
              setIsInvalidPhone(!isValidPhoneNumber(phone, AppConfig.env.test));
              setPhone(phone);
            }}
            required
            testID="phone-input"
            value={phone}
            width={300}
          />
          <VSpacer size="3" />
          <Text>Value: {`${phone}`}</Text>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Zip">
        <Stack>
          <ZipInput
            label="Label"
            onChangeText={setZip}
            required
            testID="zip-input"
            value={zip}
            width={300}
          />
          <VSpacer size="3" />
          <Text>Value: {`${zip}`}</Text>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Multiline">
        <Input
          label="Label"
          maxLength={2000}
          maxRows={6}
          multiline
          onChangeText={setText}
          showCharacterLimitMessage
          testID="input-demo-trailing-icon-multiline"
          value={text}
          width={372}
        />
      </DemoBlock>
      <DemoBlock label="Transparent">
        <Input
          isTransparent
          maxLength={2000}
          maxRows={6}
          multiline
          onChangeText={setText}
          testID="input-demo-trailing-icon-multiline-transparent"
          value={text}
          width={372}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Regular">
        <Input
          label="Label"
          onChangeText={setText}
          testID="input-demo-outline-basic"
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Support text">
        <Input
          helperText="This is a support text"
          label="Label"
          onChangeText={setText}
          testID="input-demo-outline-support-basic"
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Required">
        <Input
          label="Label"
          onChangeText={setText}
          required
          testID="input-demo-outline-required-basic"
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Error">
        <Input
          error
          label="Label"
          onChangeText={setText}
          testID="input-demo-outline-error-basic"
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Disabled">
        <Input
          disabled
          label="Label"
          onChangeText={setText}
          testID="input-demo-outline-disabled-basic"
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Disabled Trailing Icon">
        <Input
          disabled
          label="Label"
          onChangeText={setText}
          testID="input-demo-outline-trailing-disabled-basic"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Trailing Icon">
        <Input
          label="Label"
          onChangeText={setText}
          testID="input-demo-outline-trailing-basic"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Trailing Icon Error">
        <Input
          error
          label="Label"
          onChangeText={setText}
          testID="input-demo-outline-trailing-error-basic"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Disabled Leading Icon">
        <Input
          disabled
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-outline-leading-disabled-basic"
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Leading Icon">
        <Input
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-outline-leading-basic"
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Leading Icon Error">
        <Input
          error
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-outline-leading-error-basic"
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Disabled Leading Trailing Icon">
        <Input
          disabled
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-outline-leading-trailing-disabled-basic"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Leading Trailing Icon">
        <Input
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-outline-leading-trailing-basic"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Outlined Leading Trailing Icon Error">
        <Input
          error
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="input-demo-outline-leading-trailing-error-basic"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          variant="outlined"
          width={300}
        />
      </DemoBlock>
    </Box>
  );
};
