import {
  Button,
  Card,
  Chip,
  DataPoint,
  Dialog,
  HSpacer,
  IconButton,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import {
  NotificationInputs,
  NotificationModal,
} from '@/pages/Admin/ManageNotifications/NotificationModal';
import {
  ViewAddedRecipientsModal,
} from '@/pages/Admin/ManageNotifications/ViewAddedRecipientsModal';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ScheduledNotificationApi } from '@/utilities/api/ScheduledNotificationApi';
import { ScheduledNotificationEndpoint } from '@api/endpoints';
import { ApiScheduledNotification } from '@api/interfaces';
import { Warning } from '@mui/icons-material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Stack, useTheme } from '@mui/material';
import { omit } from '@shared/utilities';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ScheduledNotificationType } from '@shared/enums/ScheduledNotificationType';

interface ScheduledNotificationCardProps {
  notification: ApiScheduledNotification,
}

export const ScheduledNotificationCard = ({
  notification,
}: ScheduledNotificationCardProps) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { openSnackbar } = useSnackbar();

  const [showViewRecipientsModal, setShowViewRecipientsModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const createdDate = DateTime.fromJSDate(notification.createdAt).toFormat('MMM dd');

  const isCustom = notification.type === ScheduledNotificationType.Custom;
  const luxonSendDate = DateTime.fromJSDate(notification.sendDate);
  const sendDateDate = luxonSendDate.toFormat('MMM dd, y');
  const sendDateTime = luxonSendDate.toFormat('h:mma')
    .replace('AM', 'am')
    .replace('PM', 'pm');
  const sendDate = `${sendDateDate} at ${sendDateTime}`;
  const title = notification.title ?? `${notification.type} notification`;

  const { mutateAsync: deleteNotification } = useMutation(
    () => {
      return ScheduledNotificationApi.delete(notification.id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_SCHEDULED_NOTIFICATIONS);
        openSnackbar('Custom notification deleted successfully');
      },
      onError: () => {
        openSnackbar('Failed to delete custom notification');
      },
    },
  );

  const { mutateAsync: updateNotification } = useMutation(
    (args: ScheduledNotificationEndpoint.Update.Request) => {
      return ScheduledNotificationApi.update(notification.id, args);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_SCHEDULED_NOTIFICATIONS);
        openSnackbar('Custom notification edited successfully');
      },
      onError: () => {
        openSnackbar('Failed to edit custom notification');
      },
    },
  );

  const handleDeleteNotification = async () => {
    setShowDeleteModal(false);
    await deleteNotification();
  };

  const handleEditNotification = async (inputs: NotificationInputs) => {
    await updateNotification({
      ...omit(inputs, ['farmerIds', 'message', 'salespersonIds', 'title']),
      recipientIds: inputs.farmerIds.concat(inputs.salespersonIds),
      ...(inputs.message ? { message: inputs.message } : {}),
      ...(inputs.title ? { title: inputs.title } : {}),
      ...(inputs.options ? {
        options: Object.fromEntries(
          Object.entries(inputs.options).filter(([_, value]) => value !== null),
        ),
      } : {}),
    });
  };

  return (
    <Card
      elevation={4}
      onClick={isCustom ? undefined : () => setShowViewModal(true)}
      sx={{ cursor: isCustom ? 'default' : 'pointer' }}
      testID={notification.id}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack>
          <Text category="title-medium">{title}</Text>
          <VSpacer size="1" />
          <Text category="body-small">Created on {createdDate}</Text>
        </Stack>
        {!notification.isSent && !notification.deletedAt && (
          <Stack direction="row">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setShowEditModal(true);
              }}
              testID={`${notification.id}-edit-button`}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteModal(true);
              }}
              testID={`${notification.id}-delete-button`}
            >
              <DeleteOutline />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <VSpacer size="1" />
      <Chip
        color={notification.isSent ? 'info' : 'warning'}
        label={notification.isSent ? 'Sent' : 'Pending'}
        testID={`${notification.id}-status-chip`}
      />
      {!!notification.message && (
        <>
          <VSpacer size="1" />
          <Text category="body-medium">{notification.message}</Text>
        </>
      )}
      <VSpacer size="1" />
      <Stack alignItems="center" direction="row">
        <DataPoint label="Send date:" testID={`${notification.id}-send-date-data-point`}>
          {sendDate}
        </DataPoint>
        <HSpacer size="5" />
        {isCustom ? (
          <Button
            onClick={() => setShowViewRecipientsModal(true)}
            testID={`${notification.id}-recipients-button`}
            variant="text"
          >
            {notification.recipientIds?.length ?? 0} recipients
          </Button>
        ) : (
          <Text category="title-small" sx={{ color: theme.palette.text.secondary }}>
            Recipient: {notification.retailer?.name}
          </Text>
        )}
      </Stack>
      {showViewRecipientsModal && (
        <ViewAddedRecipientsModal
          onClose={() => setShowViewRecipientsModal(false)}
          recipientIds={notification.recipientIds ?? []}
          testID={`${notification.id}-view-recipients-modal`}
        />
      )}
      {showEditModal && (
        <NotificationModal
          notification={notification}
          onClose={() => setShowEditModal(false)}
          onSubmit={handleEditNotification}
          testID={`${notification.id}-edit-notification-modal`}
          type={notification.type}
        />
      )}
      {showViewModal && (
        <NotificationModal
          notification={notification}
          onClose={() => setShowViewModal(false)}
          readOnly
          testID={`${notification.id}-view-notification-modal`}
          type={notification.type}
        />
      )}
      {showDeleteModal && (
        <Dialog
          acceptButton={() => (
            <Button
              onClick={handleDeleteNotification}
              testID={`${notification.id}-delete-confirm-button`}
              variant="contained"
            >
              Yes, delete
            </Button>
          )}
          cancelButton={() => (
            <Button
              onClick={() => setShowDeleteModal(false)}
              testID={`${notification.id}-delete-cancel-button`}
              variant="text"
            >
              Go back
            </Button>
          )}
          heroIcon={<Warning color="warning" />}
          onClose={() => setShowDeleteModal(false)}
          open
          showCloseButton={false}
          testID={`${setShowDeleteModal}-delete-confirmation-dialog`}
          title="Delete notification"
        />
      )}
    </Card>
  );
};
