import { ApiAccountGroupCustomer, ApiUser } from "@api/interfaces";
import { AccountGroupCustomerRole } from '@shared/enums';
import { getAccountName } from '@shared/utilities';

export const getUserDisplayName = (user: ApiUser) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  return user.businessName  || user.email || user.telephone || user.id;
};

export const sortLinkedAccounts = (linkedAccounts: ApiAccountGroupCustomer[]) => {
  const roleOrder: { [key in AccountGroupCustomerRole]: number } = {
    [AccountGroupCustomerRole.Primary]: 1,
    [AccountGroupCustomerRole.Partner]: 2,
    [AccountGroupCustomerRole.Secondary]: 3,
  };

  return linkedAccounts.sort((a, b) => {
    const roleComparison = roleOrder[a.customerRole] - roleOrder[b.customerRole];

    if (roleComparison !== 0) {
      return roleComparison;
    }

    const aAccountName = getAccountName(a.customer!);
    const bAccountName = getAccountName(b.customer!);
    return aAccountName.localeCompare(bAccountName);
  });
};
