import { Button, HSpacer, Text } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { MARK_AS_ELIGIBLE, stringifyCount } from '@/constants/HierarchyOfRetailers';
import { QueryKeys } from '@/constants/QueryKeys';
import { RetailerLocationApi } from '@/utilities/api/RetailerLocationApi';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Box, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import SelectFarmers from './SelectFarmers';
import ViewFarmers from './ViewFarmers';

function clickLink (url: string) {
  const a = document.createElement('a');
  a.href = url;
  a.click();
}

const PreferredRetailerCard = ({
  isPreferredEligible,
}: {
  isPreferredEligible: boolean;
}) => {
  const { locationId = '' } = useParams();
  const [isSelectFarmers, setIsSelectFarmers] = useState(false);
  const [isViewFarmers, setIsViewFarmers] = useState(false);
  const theme = useTheme();

  const { data: allFarmersData } = useQuery(
    [QueryKeys.GET_RETAILER_FARMERS_BY_ID],
    () => RetailerLocationApi.getFarmersForLocation(locationId, undefined, true),
  );

  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='space-between'
      sx={{ marginTop: '25px', marginBottom: '25px' }}
    >
      <Stack spacing={2}>
        <Text category='h6'>Preferred Retailer</Text>
        {isPreferredEligible ? (
          <Text sx={{ color: theme.palette.text.secondary }}>
            {stringifyCount(allFarmersData?.total, 'Farmer', 'Farmers')}
          </Text>
        ): (
          <Text sx={{ color: theme.palette.text.secondary }}>{MARK_AS_ELIGIBLE}</Text>
        )}
      </Stack>
      <Box>
        <Button
          disabled={!allFarmersData?.total || !isPreferredEligible}
          onClick={() =>
          {
            clickLink(
              `${AppConfig.biddingApiUrl}/location/${locationId}/farmers/export`,
            );
          }
          }
          size='medium'
          startIcon={<ExitToAppIcon />}
          testID='preferred-location-export-farmers-button'
          variant='text'
        >
          Export
        </Button>
        <HSpacer size='4' />
        <Button
          disabled={!isPreferredEligible}
          onClick={() => setIsViewFarmers(true)}
          size='medium'
          testID='preffered-location-view-farmers-button'
          variant='outlined'
        >
          View Farmers
        </Button>
        <HSpacer size='4' />
        <Button
          disabled={!isPreferredEligible}
          onClick={() => setIsSelectFarmers(true)}
          size='medium'
          testID='preffered-location-select-farmers-button'
          variant='outlined'
        >
          Select Farmers
        </Button>
      </Box>
      <SelectFarmers
        isSelectFarmers={isSelectFarmers}
        locationId={locationId}
        setIsSelectFarmers={setIsSelectFarmers}
      />
      {isViewFarmers && (
        <ViewFarmers
          isViewFarmers={isViewFarmers}
          setIsViewFarmers={setIsViewFarmers}
        />
      )}
    </Stack>
  );
};

export default PreferredRetailerCard;
