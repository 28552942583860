import { Button, Dialog, Text, VSpacer } from '@/components/DesignSystem';
import { LinkedAccountCard } from '@/pages/FarmerList/LinkedAccountCard';
import { sortLinkedAccounts } from '@/utilities/Farmer';
import { ApiAccountGroupCustomer } from '@api/interfaces';
import { Divider } from '@mui/material';
import React, { Fragment, useMemo } from 'react';

interface LinkedAccountsDialogProps {
  linkedAccounts: ApiAccountGroupCustomer[],
  onClose: () => void,
  show: boolean,
}

export const LinkedAccountsDialog = ({
  linkedAccounts,
  onClose,
  show,
}: LinkedAccountsDialogProps) => {
  const sortedAccounts = useMemo(() => sortLinkedAccounts(linkedAccounts), [linkedAccounts]);

  return (
    <Dialog
      acceptButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
          testID="close-linked-accounts-dialog-button"
          variant="contained"
        >
          Close
        </Button>
      )}
      dialogWidth="560px"
      onClose={onClose}
      open={show}
      showCloseButton={false}
      testID="linked-accounts-dialog"
      title="Linked accounts"
    >
      <Divider />
      {!sortedAccounts.length && (
        <>
          <VSpacer size="5" />
          <Text>No linked accounts</Text>
        </>
      )}
      {sortedAccounts.map((account) => (
        <Fragment key={account.id}>
          <LinkedAccountCard account={account} />
          <Divider />
        </Fragment>
      ))}
    </Dialog>
  );
};
