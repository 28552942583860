import defaultLocationImage from '@/assets/images/default-location-image.png';
import { Button, HSpacer, Switch, Text, VSpacer } from '@/components/DesignSystem';
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';
import { AppConfig } from '@/constants/AppConfig';
import { useGetLocationById } from '@/hooks/useRetailerLocations';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import EditIcon from '@mui/icons-material/Edit';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Card, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import EditLocation from './EditLocation';

const LocationDetailsCard = ({
  defaultSalesPerson,
}: {
  defaultSalesPerson?: string;
}) => {
  const [isEditLocation, setIsEditLocation] = useState(false);
  const { locationId = '' } = useParams();
  const theme = useTheme();

  const { location, invalidateRetailerLocation } = useGetLocationById(locationId);

  const editLocation = () => {
    setIsEditLocation(!isEditLocation);
  };

  const logoSrc = location?.image && `${AppConfig.staticImageHost}/${location.image}`;

  return (
    <Card
      sx={{
        marginBottom: '20px',
        padding: '24px 24px 32px 24px',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={2.5}>
          <Stack
            alignItems={'center'}
            direction='row'
            justifyContent={'space-between'}
          >
            <Stack direction='row' spacing={2}>
              <Avatar
                alt='location-logo'
                height={56}
                src={logoSrc ?? defaultLocationImage}
                width={56}
              />
              <Stack spacing={1.5}>
                <Text category="overline" sx={{ color: theme.palette.text.secondary }}>
                  ID# {location?.id}
                </Text>
                <Text category="headline-medium">
                  {location?.name ?? '-'}
                </Text>
              </Stack>
            </Stack>
          </Stack>
          {location?.isPreferred &&
            <Stack direction="row">
              <VerifiedIcon color="success" />
              <HSpacer size="3" />
              <Text color="success">Preferred Retailer</Text>
            </Stack>
          }
          <Stack direction="row">
            <Text>
              Default Salesperson:
            </Text>
            <Text color="primary" textTransform="capitalize">
              &nbsp; {defaultSalesPerson || '-'}
            </Text>
          </Stack>
        </Stack>
        <Stack>
          <Button
            onClick={editLocation}
            startIcon={<EditIcon />}
            testID='edit-details'
            variant='outlined'
          >
            Edit Details
          </Button>
          <VSpacer size="12" />
          <Switch
            checked={location?.isPreferred ?? false}
            label="Set as Preferred"
            labelPlacement="start"
            onClick={async () => {
              const isPreferred = !location?.isPreferred;
              await HierarchyOfRetailersApi.updateRetailerLocation(locationId, { isPreferred });
              await invalidateRetailerLocation();
            }}
            testID='set-as-preferred-switch'
          />
        </Stack>
      </Stack>
      {isEditLocation && (
        <EditLocation
          location={{
            address1: location?.address1,
            address2: location?.address2,
            city: location?.city,
            id: location?.id,
            image: location?.image,
            name: location?.name,
            postal: location?.postal,
          }}
          onClose={() => setIsEditLocation(false)}
          show={isEditLocation}
        />
      )}
    </Card>
  );
};

export default LocationDetailsCard;
