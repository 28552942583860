import { Switch } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';

export const SwitchDemo = () => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <DemoBlock>
      <Stack alignItems="center" direction="row" spacing={2}>
        <Switch
          checked={checked}
          onChangeChecked={setChecked}
          testID="switch-enabled">
          Basic
        </Switch>
        <Switch
          checked={checked}
          disabled
          onChangeChecked={setChecked}
          testID="switch-disabled">
          Disabled
        </Switch>
      </Stack>
    </DemoBlock>
  );
};
