import defaultProductImage from '@/assets/images/default-product-img-growers.png';
import { AppConfig } from '@/constants/AppConfig';
import { ApiProduct, ApiPromotion } from '@api/interfaces';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { formatDateOnly } from '@shared/utilities';

export const getProductImageUrl = (image?: string | null) => {
  const defaultImageUrl = defaultProductImage;
  const productImageUrl = image && `${AppConfig.staticImageHost}/${image}`;
  return productImageUrl || defaultImageUrl;
};

export const getFarmerPromotions = (
  promotions: ApiPromotion[],
  product: ApiProduct,
): ApiPromotion[]  => {
  const currentDate = formatDateOnly(new Date());
  return promotions.filter(
    (promotion) => (
      promotion.targetUserType === PromotionTargetUserType.Farmer
      && promotion.productIdsForEarningPoints.includes(product.id)
      && promotion.startDate <= currentDate && promotion.endDate >= currentDate
    ),
  ) ?? [];
};

export const getRetailerPromotions = (
  promotions: ApiPromotion[],
  product: ApiProduct,
): ApiPromotion[] => {
  const currentDate = formatDateOnly(new Date());
  return promotions.filter(
    (promotion) => (
      promotion.targetUserType === PromotionTargetUserType.Retailer
      && promotion.productIdsForEarningPoints.includes(product.id)
      && promotion.startDate <= currentDate && promotion.endDate >= currentDate
    ),
  ) ?? [];
};
