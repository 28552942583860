import { Toolbar, VSpacer } from "@/components/DesignSystem";
import ERPRetailerSelector from '@/components/shared/ListSelector/ERPRetailerSelector';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { ApiRetailer, ApiUser } from "@api/interfaces";
import { Container, Pagination, Stack } from "@mui/material";
import { UserType } from "@shared/enums";
import React, { Fragment, useEffect, useState } from "react";
import CustomerCard from "./CustomerCard";
import { useQuery } from "react-query";
import { QueryKeys } from "@/constants/QueryKeys";
import { UserApi } from "@/utilities/api/UserApi";
import { Filter, FilterSelections } from "@/components/DesignSystem/Toolbar/interfaces";
import { useSearch } from "@/hooks/useSearch";
import { useRetailerLocationFilter } from "@/hooks/useRetailerLocationFilter";
import { useSearchParams } from "react-router-dom";

enum QueryParams {
  'search' = 'search',
}

const CustomerList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setSearch, debouncedSearch } = useSearch(3);
  const [page, setPage] = useState(0);

  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());
  const { user, retailer } = useAuthentication();
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>(retailer);

  useEffect(() => {
    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      newParams.set(QueryParams.search, debouncedSearch);
      return newParams;
    });
    setPage(0);
  }, [debouncedSearch]);

  const locationId = Array.from(filterSelections?.get('locations') ?? []);
  const sortDesc = filterSelections?.get('sortDesc')?.has('desc');

  const userQuery = {
    locationId,
    page,
    retailerId: selectedRetailer ? selectedRetailer.id : null,
    search: debouncedSearch,
    sortDesc,
    userType: [UserType.Farmer],
  };

  const salespersonQuery = {
    page,
    search: debouncedSearch,
    sortDesc,
  };

  const locationFilter = useRetailerLocationFilter();
  const filters: Filter[] = [
    {
      id: 'sortDesc',
      label: 'Sort by',
      options: [
        { id: 'asc', label: 'A - Z' },
        { id: 'desc', label: 'Z - A' },
      ],
      selectionMethod: 'single-select',
    },
  ];

  if (user?.userType === UserType.RetailerAdmin) {
    filters.push(locationFilter);
  }

  const isSalesperson = user?.userType === UserType.SalesPerson;
  const { data: users } = useQuery(
    [QueryKeys.GET_USERS, isSalesperson ? salespersonQuery : userQuery],
    async () => {
      if (isSalesperson) {
        return UserApi.getFarmersAssociatedToSalesperson(salespersonQuery);
      } else {
        return UserApi.list(userQuery);
      }
    },
    { staleTime: 5 * 60 * 1000 },
  );

  return (
    <Container maxWidth="lg">
      <Stack>
        <VSpacer size="8" />
        <ERPRetailerSelector
          hasErpProducts={true}
          header='Customers'
          logo="none"
          onSelect={(retailer) => setSelectedRetailer(retailer)}
        />
        <VSpacer size="6" />
        <Toolbar
          filters={filters}
          initialSearch={searchParams.get(QueryParams.search)}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            setFilterSelections(selections);
          }}
          testID="promotions-toolbar-generic"
          totalItems={users?.total}
          totalUnit="customers"
        />
        {!!users && users.data.map((user) => (
          <Fragment key={user.id}>
            <CustomerCard customer={user as ApiUser}/>
            <VSpacer size="5" />
          </Fragment>
        ))}
        {!!users && users.lastPage !== 0 &&
          <Stack alignItems="center">
            <VSpacer size="9" />
            <Pagination
              count={users.lastPage + 1}
              onChange={(_, page) => {
                setPage(page - 1);
              }}
              page={users.page + 1}
            />
          </Stack>
        }
        <VSpacer size="14" />
      </Stack>
    </Container>
  );
};

export default CustomerList;

