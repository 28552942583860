import {
  Button,
  Checkbox,
  HSpacer,
  Input,
  MenuItem,
  Modal,
  Select,
  Text,
  TextAreaInput,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { DatePickerCalendar } from '@/components/DesignSystem/DatePicker/DatePickerCalendar';
import { useUserList } from '@/hooks/useUserList';
import { AddRecipientsDialog } from '@/pages/Admin/ManageNotifications/AddRecipientsDialog';
import {
  ViewAddedRecipientsModal,
} from '@/pages/Admin/ManageNotifications/ViewAddedRecipientsModal';
import { ApiScheduledNotification } from '@api/interfaces';
import Add from '@mui/icons-material/Add';
import { Divider, Menu, Popover, Stack, TextField } from '@mui/material';
import { UserType } from '@shared/enums';
import React, { useEffect, useMemo, useState } from 'react';
import { ScheduledNotificationType } from '@shared/enums/ScheduledNotificationType';
import { RewardsBalanceNotificationOptions } from "../../../../../db/src/interfaces";
import { floor } from "lodash";
import { useQuery } from 'react-query';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { QueryKeys } from '@/constants/QueryKeys';
import { formatPhoneNumber } from '@shared/utilities';
import { SharedConfig } from '@shared/SharedConfig';

const MaxTitleLength = 39;
const MaxMessageLength = 500;

export type NotificationInputs = {
  farmerIds: string[],
  message: string,
  options: RewardsBalanceNotificationOptions,
  retailerId?: string,
  salespersonIds: string[],
  sendDate: Date,
  title: string,
  type: ScheduledNotificationType,
};

interface NotificationModalProps {
  notification?: ApiScheduledNotification,
  onClose: () => void,
  onSubmit?: (notification: NotificationInputs) => void,
  readOnly?: boolean,
  testID: string,
  type: ScheduledNotificationType,
}

function numberOrNull (input: string) {
  const number = floor(parseFloat(input), 4);
  return isNaN(number) ? null : number;
}

export const NotificationModal = ({
  notification,
  onClose,
  onSubmit,
  readOnly = false,
  testID,
  type,
}: NotificationModalProps) => {
  const defaultSendDate = useMemo(() => {
    const date = new Date();
    // First time must be at least 30 mins away
    date.setMinutes(date.getMinutes() + 30);
    date.setMinutes(Math.ceil(date.getMinutes() / 30) * 30, 0, 0);
    return date;
  }, []);

  const { users, isFetched } = useUserList(
    { id: notification?.recipientIds ?? undefined },
    true,
    type === ScheduledNotificationType.Custom && !!notification?.recipientIds?.length,
  );

  useEffect(() => {
    if (isFetched) {
      const farmerIds = (
        users?.data.filter((user) => user.userType === UserType.Farmer).map((user) => user.id)
      ) ?? [];
      const salespersonIds = (
        users?.data.filter((user) => user.userType === UserType.SalesPerson).map((user) => user.id)
      ) ?? [];
      setNotificationState((previous) => ({
        ...previous,
        farmerIds,
        salespersonIds,
      }));
    }
  }, [isFetched, users]);

  const [notificationState, setNotificationState] =
    useState<NotificationInputs>({
      farmerIds: [],
      message: notification?.message ?? '',
      options: notification?.options ?? {},
      retailerId: notification?.retailerId ?? undefined,
      salespersonIds: [],
      sendDate: notification ? new Date(notification.sendDate) : defaultSendDate,
      title: notification?.title ?? '',
      type,
    });

  const [showViewAddedRecipientsModal, setShowViewAddedRecipientsModal] = useState(false);
  const [showAddRecipientsModal, setShowAddRecipientsModal] = useState(false);
  const [datePickerDate, setDatePickerDate] = useState(notificationState.sendDate);
  const [datePickerAnchor, setDatePickerAnchor] = useState<null | HTMLElement>(null);
  const [timeMenuAnchor, setTimeMenuAnchor] = useState<null | HTMLElement>(null);

  const { data: retailers } = useQuery(
    [QueryKeys.GET_RETAILERS],
    () => HierarchyOfRetailersApi.listRetailers({
      hasErpProducts: true,
      limit: SharedConfig.maxPageLimit,
    }),
  );

  const halfHourlyTimes = useMemo(() => {
    const times = [];
    const sendDateIsToday = (
      notificationState.sendDate.getDate() === defaultSendDate.getDate()
      && notificationState.sendDate.getMonth() === defaultSendDate.getMonth()
      && notificationState.sendDate.getFullYear() === defaultSendDate.getFullYear()
    );
    const nextDayMidnight = new Date();
    nextDayMidnight.setHours(24, 0, 0, 0);
    const thirtyMinuteIncrements = 30 * 60 * 1000;
    const increments = sendDateIsToday
      ? Math.floor(
        (nextDayMidnight.getTime() - defaultSendDate.getTime()) / thirtyMinuteIncrements,
      )
      : 48;
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0);
    const startTime = sendDateIsToday
      ? defaultSendDate.getTime()
      : midnight.getTime();
    for (let i = 0; i < increments; i++) {
      const time = new Date(startTime + i * thirtyMinuteIncrements);
      times.push(time);
    }
    return times;
  }, [defaultSendDate, notificationState.sendDate]);


  const onSelectDate = (date: Date) => {
    const newSendDate = new Date(notificationState.sendDate);
    newSendDate.setFullYear(date.getFullYear());
    newSendDate.setMonth(date.getMonth());
    newSendDate.setDate(date.getDate());
    setNotificationState({ ...notificationState, sendDate: newSendDate });
  };

  const onSelectTime = (time: Date) => {
    const newSendDate = new Date(notificationState.sendDate);
    newSendDate.setHours(time.getHours());
    newSendDate.setMinutes(time.getMinutes());
    setNotificationState({ ...notificationState, sendDate: newSendDate });
  };

  function getDateString (date: Date) {
    return date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
  }

  function getTimeString (time: Date) {
    return time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
  }

  const isCustom = notificationState.type === ScheduledNotificationType.Custom;
  const selectedRetailer = retailers?.data.find((retailer) => (
    notificationState.retailerId === retailer.id
  ));
  const isSelectedRetailerValid
    = !!selectedRetailer?.messagingTelephone && !!selectedRetailer.shortUrlName;
  const recipientCount = isCustom ? (
    notificationState.farmerIds.length + notificationState.salespersonIds.length
  ) : notificationState.retailerId ? 1 : 0;
  const earliestValidSendDate = new Date();
  earliestValidSendDate.setMinutes(earliestValidSendDate.getMinutes() + 30);
  const isSendDateValid = notificationState.sendDate >= earliestValidSendDate;
  const isFormValid = isCustom ? (
    !!notificationState.title.length && notificationState.title.length <= MaxTitleLength
    && !!notificationState.message.length && notificationState.message.length <= MaxMessageLength
    && isSendDateValid
    && !!recipientCount
  ) : (
    !!notificationState.retailerId
    && isSendDateValid
    && isSelectedRetailerValid
  );

  const title = isCustom
    ? `${notification ? 'Edit' : 'Create'} Custom Notification`
    : `${readOnly ? 'View Scheduled' : 'Schedule'} Notification`;

  const Inputs = isCustom && (
    <>
      <Input
        disabled={readOnly}
        label="Notification title"
        maxLength={MaxTitleLength}
        onChangeText={(title) => setNotificationState({ ...notificationState, title })}
        showCharacterCountdown
        showCharacterLimitMessage
        testID={`${testID}-title`}
        value={notificationState.title}
      />
      <VSpacer size="7" />
      <TextAreaInput
        disabled={readOnly}
        label="Message body"
        maxLength={MaxMessageLength}
        onChangeText={(message) => setNotificationState({ ...notificationState, message })}
        showCharacterCountdown
        showCharacterLimitMessage
        testID={`${testID}-message`}
        value={notificationState.message}
      />
    </>
  );

  const SendDateSelector = (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="title-medium">
          Send date
        </Text>
        <Stack alignItems="center" direction="row" gap="4px">
          <Button
            disabled={readOnly}
            onClick={(event) => setDatePickerAnchor(event.currentTarget)}
            testID={`${testID}-send-date`}
            variant="text"
          >
            {getDateString(notificationState.sendDate)}
          </Button>
          <Text>
            at
          </Text>
          <Button
            disabled={readOnly}
            onClick={(event) => setTimeMenuAnchor(event.currentTarget)}
            testID={`${testID}-send-time`}
            variant="text"
          >
            {getTimeString(notificationState.sendDate)}
          </Button>
        </Stack>
      </Stack>
      {!isSendDateValid && !readOnly && (
        <Text category="body-small" color="error">
          Send date must be at least 30 minutes in the future.
        </Text>
      )}
      <Popover
        PaperProps={{
          sx: {
            borderRadius: "16px",
            width: "360px",
          },
        }}
        anchorEl={datePickerAnchor}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={() => setDatePickerAnchor(null)}
        open={!!datePickerAnchor}
        sx={{ marginTop: "7px" }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <DatePickerCalendar
          internalStartDate={datePickerDate}
          onChange={(date) => setDatePickerDate(date)}
          testID={`${testID}-date-picker`}
          variant="dropdown"
        />
        <Stack
          alignItems="center"
          direction="row"
          height={52}
          justifyContent="flex-end"
          pb={1}
          px={1.5}
        >
          <Button
            onClick={() => setDatePickerAnchor(null)}
            testID={`${testID}-date-picker-cancel-button`}
            variant="text"
          >
            Cancel
          </Button>
          <HSpacer size="2" />
          <Button
            onClick={() => {
              setDatePickerAnchor(null);
              onSelectDate(datePickerDate);
            }}
            testID={`${testID}-date-picker-submit-button`}
            variant="text"
          >
            OK
          </Button>
        </Stack>
      </Popover>
      <Menu
        anchorEl={timeMenuAnchor}
        onClose={() => setTimeMenuAnchor(null)}
        open={!!timeMenuAnchor}
        sx={{ maxHeight: '300px' }}
      >
        {halfHourlyTimes.map((time, i) => (
          <MenuItem
            density={4}
            key={time.toISOString()}
            onClick={() => {
              setTimeMenuAnchor(null);
              onSelectTime(time);
            }}
            testID={`${testID}-time-menu-item-${i}`}
          >
            {getTimeString(time)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  const RecipientsSelector = (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="title-medium">
          {isCustom ? 'Recipients' : 'Retailer'}
        </Text>
        {isCustom ? (
          <Button
            disabled={readOnly}
            onClick={() => setShowAddRecipientsModal(true)}
            startIcon={<Add />}
            testID={`${testID}-add-recipients-button`}
            variant="outlined"
          >
            Add
          </Button>
        ) : (
          <Select
            disabled={readOnly}
            onChangeValue={(id) => (
              setNotificationState({ ...notificationState, retailerId: id })
            )}
            testID={`${testID}-retailer-selector`}
            value={notificationState.retailerId}
            width={400}
          >
            {retailers?.data.map((retailer) => (
              <MenuItem
                key={retailer.id}
                testID={`${testID}-retailer-selector-item-${retailer.id}`}
                value={retailer.id}
              >
                {retailer.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Stack>
      {!!recipientCount && isCustom && (
        <TextLink
          category="title-small"
          onClick={() => setShowViewAddedRecipientsModal(true)}
          testID={`${testID}-view-recipients-textlink`}
        >
          View {recipientCount} recipient{recipientCount > 1 ? 's' : ''}
        </TextLink>
      )}
      {!isCustom && !!selectedRetailer && (
        <>
          <VSpacer size="4" />
          <Text category="body-small" color={isSelectedRetailerValid ? undefined : 'error'}>
            {isSelectedRetailerValid ? (
              `Messages will be sent from ${formatPhoneNumber(selectedRetailer.messagingTelephone!)}
            and the customer rewards dashboard link will display as
            ${selectedRetailer.shortUrlName}.${SharedConfig.shortUrlDomain}`
            ) : (
              'Retailer must have a messaging phone number and URL name set up' +
              ' to send rewards balance messages.'
            )}
          </Text>
        </>
      )}
    </>
  );

  const OptionsSelection = () => {
    const updateNotificationOptions = (update: Partial<NotificationInputs['options']>) => {
      setNotificationState((existing) => ({
        ...existing,
        options: { ...existing.options, ...update },
      }));
    };
    return (
      <>
        <VSpacer size="4" />
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 0 }}>
          Only send to farmers with a minimum of
          <TextField
            disabled={readOnly}
            id="minPoints"
            inputProps={{ min: 0 }}
            onChange={(e) => updateNotificationOptions({ minPoints: numberOrNull(e.target.value) })}
            placeholder="Min"
            style={{ margin: '0 8px', width: '80px' }}
            type="number"
            value={notificationState.options.minPoints ?? ''}
          />
          points
        </div>
        <VSpacer size="4" />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Only send to farmers with a maximum of
          <TextField
            disabled={readOnly}
            id="maxPoints"
            inputProps={{ min: 0 }}
            onChange={(e) => updateNotificationOptions({ maxPoints: numberOrNull(e.target.value) })}
            placeholder="Max"
            style={{ margin: '0 8px', width: '80px' }}
            type="number"
            value={notificationState.options.maxPoints ?? ''}
          />
          points
        </div>
        <VSpacer size="4" />
        <Divider />
        <VSpacer size="7" />
        <Checkbox
          alignItems="flex-start"
          checked={notificationState.options.sendToCustomersWithoutTier ?? false}
          disabled={readOnly}
          onChangeChecked={(checked) => (
            updateNotificationOptions({ sendToCustomersWithoutTier: checked })
          )}
          testID="send-to-farmers-with-no-tier-checkbox-id"
        >
          <Text category="title-medium">
            Send to farmers with no current tier
          </Text>
        </Checkbox>
        <VSpacer size="7" />
        <Divider />
      </>
    );
  };

  const saveText = isCustom ? (
    notification ? 'Save changes' : 'Create notification'
  ) : 'Save';

  return (
    <>
      <Modal
        acceptButton={readOnly ? undefined : (props) => (
          <Button
            {...props}
            disabled={!isFormValid}
            onClick={() => {
              onClose();
              if (onSubmit) {
                onSubmit(notificationState);
              }
            }}
            variant="contained"
          >
            {saveText}
          </Button>
        )}
        cancelButton={(props) => (
          <Button
            {...props}
            onClick={onClose}
          >
            {readOnly ? 'Close' : 'Cancel'}
          </Button>
        )}
        onClose={onClose}
        open
        stickyFooter
        testID={testID}
        title={title}
        width={560}
      >
        <Stack>
          {isCustom ? (
            <>
              {Inputs}
              <VSpacer size="6" />
            </>
          ) : (
            <>
              <Text category="body-medium">
                Schedule rewards balance notification
              </Text>
              <VSpacer size="4" />
            </>
          )}
          <Divider />
          <VSpacer size="4" />
          {SendDateSelector}
          <VSpacer size="4" />
          <Divider />
          <VSpacer size="4" />
          {RecipientsSelector}
          <VSpacer size="4" />
          <Divider />
          {!isCustom && OptionsSelection()}
        </Stack>
      </Modal>
      {isCustom && showAddRecipientsModal && (
        <AddRecipientsDialog
          farmerIds={notificationState.farmerIds}
          onSave={(farmerIds, salespersonIds) => {
            setShowAddRecipientsModal(false);
            setNotificationState({ ...notificationState, farmerIds, salespersonIds });
          }}
          salespersonIds={notificationState.salespersonIds}
          show
        />
      )}
      {isCustom && showViewAddedRecipientsModal && (
        <ViewAddedRecipientsModal
          onChange={(farmerIds, salespersonIds) => (
            setNotificationState({
              ...notificationState,
              farmerIds,
              salespersonIds,
            })
          )}
          onClose={() => setShowViewAddedRecipientsModal(false)}
          recipientIds={[...notificationState.farmerIds, ...notificationState.salespersonIds]}
          testID={`${testID}-view-added-recipients-modal`}
        />
      )}
    </>
  );
};
