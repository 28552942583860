import { IconButton, Text, VSpacer } from '@/components/DesignSystem';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { ApiCategory } from '@api/interfaces';
import { Close } from '@mui/icons-material';
import { Divider, useTheme } from '@mui/material';
import React from 'react';

interface ProgramExclusionsBottomSheetProps {
  categories: ApiCategory[],
  onClose: () => void,
  segmentSpendCategoryExclusionIds: string[],
  tierSpendCategoryExclusionIds: string[],
}
export const ProgramExclusionsBottomSheet = ({
  categories,
  onClose,
  segmentSpendCategoryExclusionIds,
  tierSpendCategoryExclusionIds,
}: ProgramExclusionsBottomSheetProps) => {
  const theme = useTheme();

  const segmentSpendCategoryExclusions = categories.filter(
    (category) => segmentSpendCategoryExclusionIds.includes(category.id),
  ).sort((a, b) => a.name.localeCompare(b.name));
  const tierSpendCategoryExclusions = categories.filter(
    (category) => tierSpendCategoryExclusionIds.includes(category.id),
  ).sort((a, b) => a.name.localeCompare(b.name));

  const getCategoryNameLabel = (category: ApiCategory) => {
    const parentCategory = category.parentId
      ? categories.find((c) => category.parentId === c.id) : null;
    let label = '';
    if (parentCategory) {
      label += `${parentCategory.name}: `;
    }
    label += category.name;
    return label;
  };

  return (
    <ModalBottomSheet
      closeButton={(
        <IconButton
          onClick={onClose}
          sx={{
            marginLeft: "-8px",
            marginRight: "-2px",
          }}
          testID="close-button"
        >
          <Close sx={{ height: 24, width: 24 }} />
        </IconButton>
      )}
      containerStyle={{ p: 'px', backgroundColor: theme.palette.background.default }}
      headerStyle={{ backgroundColor: theme.palette.background.default }}
      onClose={onClose}
      open
      showFooter={false}
      testID="program-exclusions-bottom-sheet"
      title="Program exclusions"
    >
      <Text category="body-medium">
        Categories and subcategories that are not eligible towards earning program tiers.
      </Text>
      <VSpacer size="7" />
      {!!tierSpendCategoryExclusions.length && (
        <>
          <Divider />
          <VSpacer size="5" />
          <Text category="title-medium">
            Excluded from tier minimum spend
          </Text>
          <VSpacer size="2" />
          {tierSpendCategoryExclusions.map((category) => (
            <Text category="body-large" key={`${category.id}-tier-exclusion`}>
              {getCategoryNameLabel(category)}
            </Text>
          ))}
          <VSpacer size="5" />
        </>
      )}
      {!!segmentSpendCategoryExclusions.length && (
        <>
          <Divider />
          <VSpacer size="5" />
          <Text category="title-medium">
            Excluded from tier minimum categories
          </Text>
          <VSpacer size="2" />
          {segmentSpendCategoryExclusions.map((category) => (
            <Text category="body-large" key={`${category.id}-segment-exclusion`}>
              {getCategoryNameLabel(category)}
            </Text>
          ))}
          <VSpacer size="5" />
        </>
      )}
      <Divider />
    </ModalBottomSheet>
  );
};
