import { Divider, Grid, Stack } from "@mui/material";
import { Button, Card, Modal, Text, VSpacer } from "@/components/DesignSystem";
import React, { useState } from "react";
import {
  BrandAssetsCardGroupItem,
} from "@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/BrandAssetsCardGroupItem";
import { Image } from "@mui/icons-material";
import ExampleAppIcon from '@/assets/images/example-app-icon.png';
import ExampleLogo from '@/assets/images/example-logo.png';
import ExampleLogomark from '@/assets/images/example-logomark.png';

interface BrandAssetsCardGroupProps {
  assets: {
    name: string,
    value: string | null,
  }[],
  onClick: (asset: string, mode: "add" | "edit" | "delete") => void,
  title: string,
}

export const BrandAssetsCardGroup = ({
  assets,
  onClick,
  title,
}: BrandAssetsCardGroupProps) => {
  const [showExampleAssets, setShowExampleAssets] = useState(false);
  return (
    <Card
      cardContentStyle={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        padding: '16px',
        width: '100%',
      }}
      testID={`${title}-card`}
    >
      <Stack>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text category="title-medium">
            {title}
          </Text>
          <Button
            color="primary"
            onClick={() => setShowExampleAssets(true)}
            startIcon={<Image />}
            testID="show-brand-assets-examples-button"
            variant="text"
          >
            View examples
          </Button>
        </Stack>
        <VSpacer size="4" />
        <Grid container spacing={2}>
          {assets.map((asset, i) => (
            <Grid item key={i} sm={12 / assets.length} xs={12}>
              <BrandAssetsCardGroupItem
                onClick={onClick}
                statusText={asset.value}
                title={asset.name}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
      {showExampleAssets && (
        <Modal
          onClose={() => setShowExampleAssets(false)}
          open
          testID="example-image-types"
          title="Example image types"
          width={540}
        >
          <Stack direction="row" justifyContent="space-between">
            <Text category="title-medium">
              Logo
            </Text>
            <Stack direction="row" justifyContent="center" sx={{ width: "440px" }}>
              <img alt="example-logo" src={ExampleLogo} width="250px"/>
            </Stack>
          </Stack>
          <VSpacer size="5" />
          <Divider />
          <VSpacer size="5" />
          <Stack direction="row" justifyContent="space-between">
            <Text category="title-medium">
              Logomark
            </Text>
            <Stack direction="row" justifyContent="center" sx={{ width: "440px" }}>
              <img alt="example-logomark" src={ExampleLogomark} width="60px"/>
            </Stack>
          </Stack>
          <VSpacer size="5" />
          <Divider />
          <VSpacer size="5" />
          <Stack direction="row" justifyContent="space-between">
            <Text category="title-medium">
              App icon
            </Text>
            <Stack direction="row" justifyContent="center" sx={{ width: "400px" }}>
              <img alt="example-app-icon" src={ExampleAppIcon} width="80px"/>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="right">
            <Button
              onClick={() => setShowExampleAssets(false)}
              testID="example-image-types-close-button"
            >
              Close
            </Button>
          </Stack>
        </Modal>
      )}
    </Card>
  );
};