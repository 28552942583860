import { HSpacer, Text } from '@/components/DesignSystem';
import { Stack } from '@mui/material';

interface ImageRequirementsHeaderProps {
  fileType?: string,
  maxFileSize?: string,
}

export const ImageRequirementsHeader = ({
  fileType = 'PNG, JPG, SVG',
  maxFileSize = '5 MB',
}: ImageRequirementsHeaderProps) => {
  return (
    <Stack direction="row" spacing="12px">
      {[
        ['File type', fileType],
        ['Max file size', maxFileSize],
      ].map(([label, value]) => (
        <Stack direction="row" key={label}>
          <Text category="label-medium">
            {label}:
          </Text>
          <HSpacer size="2" />
          <Text category="label-medium">
            {value}
          </Text>
        </Stack>
      ))}
    </Stack>
  );
};