import { Box, LinearProgress, Stack, useTheme } from '@mui/material';
import React from 'react';
import { Text } from '@/components/DesignSystem';
import { formatAmount } from '@/pages/CustomerDashboard/helpers';

interface TotalSpendProgressBarProps {
  minimumDollars: number,
  totalSpend: number,
}

export const TotalSpendProgressBar = ({
  minimumDollars,
  totalSpend,
}: TotalSpendProgressBarProps) => {
  const theme = useTheme();

  const progressRatio = Math.min(totalSpend / minimumDollars, 1);
  const isComplete = totalSpend >= minimumDollars;

  const variantThreshold = 0.08;
  const showLeftVariant = progressRatio <= variantThreshold;
  const showRightVariant = progressRatio >= 1 - variantThreshold;

  const TotalSpendIndicator = (
    <Stack height="39px">
      <Box
        bgcolor="#237804"
        borderRadius={`4px 4px ${showRightVariant ? "0px" : "4px"} ${showLeftVariant ? "0px" : "4px"}`}
        left={`${progressRatio * 100}%`}
        p="6px"
        position="absolute"
        sx={{
          transform: `translate(${showLeftVariant ? "0%" : showRightVariant ? "-100%" : "-50%"}, 0%)`,
          '::after': {
            content: '""',
            position: 'absolute',
            bottom: '-6px',
            left: showLeftVariant ? "0%" : showRightVariant ? "calc(100% - 3px)" : "50%",
            transform: `translateX(${showLeftVariant ? "0%" : "-50%"})`,
            width: 0,
            height: 0,
            borderLeft: `${showLeftVariant ? "0" : "6px"} solid transparent`,
            borderRight: `${showRightVariant ? "0" : "6px"} solid transparent`,
            borderTop: '6px solid #237804',
          },
        }}
      >
        <Text fontSize="16px" fontWeight={500} sx={{ color: 'white' }}>
          {formatAmount(totalSpend)}
        </Text>
      </Box>
    </Stack>
  );

  return (
    <Stack position="relative">
      {progressRatio < 1 && TotalSpendIndicator}
      <LinearProgress
        sx={{
          bgcolor: theme.palette.grey[400],
          '.MuiLinearProgress-bar1Determinate': {
            bgcolor: isComplete ? '#237804' : theme.palette.grey[900],
          },
          borderRadius: '8px',
          height: '8px',
        }}
        value={progressRatio * 100}
        variant="determinate"
      />
    </Stack>
  );
};
