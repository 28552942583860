import { Button, Input, Modal, PhoneInput } from '@/components/DesignSystem';
import { useState } from 'react';

interface PropertyModalProps {
  existingValue?: string | null,
  isPhoneNumber?: boolean,
  onClose: () => void,
  onSave: (value: string) => void,
  propertyName: string,
  validate?: (value: string) => boolean,
  validateOnSave?: (value: string) => Promise<boolean>,
}

export const PropertyModal = ({
  existingValue,
  isPhoneNumber = false,
  onClose,
  onSave,
  propertyName,
  validate,
  validateOnSave,
}: PropertyModalProps) => {
  const [value, setValue] = useState(existingValue ?? '');
  const isEditing = !!existingValue;
  const isValueChanged = value !== (existingValue ?? '');
  const isValid = (
    isValueChanged
    && value.length
    && (!validate || validate(value))
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={async () => {
            if (validateOnSave) {
              const isValid = await validateOnSave(value);
              if (!isValid) {
                return;
              }
            }
            onClose();
            onSave(value);
          }}
          testID={`${propertyName}-modal-save-button`}
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
          testID={`${propertyName}-modal-cancel-button`}
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID={`${propertyName}-modal`}
      title={`${isEditing ? 'Edit' : 'Add'} ${propertyName}`}
    >
      {isPhoneNumber ? (
        <PhoneInput
          label={propertyName}
          onChangeText={setValue}
          testID={`${propertyName}-modal-input`}
          value={value}
        />
      ) : (
        <Input
          label={propertyName}
          onChangeText={setValue}
          testID={`${propertyName}-modal-input`}
          value={value}
        />
      )}
    </Modal>
  );
};
