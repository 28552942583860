import {
  Checkbox,
  HSpacer,
  IconButton,
  Text,
  Toolbar,
  TopAppBar,
  VSpacer,
} from '@/components/DesignSystem';
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { InfiniteScroll } from '@/components/shared/InfiniteScroll';
import StickyElement from '@/components/shared/StickyElement';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useDashboard } from '@/hooks/useDashboard';
import { useSearch } from '@/hooks/useSearch';
import { defaultDashboardData } from '@/pages/CustomerDashboard/CustomerDashboard';
import { PromotionCard } from '@/pages/CustomerDashboard/Promotions/PromotionCard';
import { PromotionDetailsModal } from '@/pages/CustomerDashboard/Promotions/PromotionDetailsModal';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { PromotionEndpoint } from '@api/endpoints';
import { ApiPromotion } from '@api/interfaces';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { CircularProgress, Container, Divider, Stack, useTheme } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDashboardNavigate } from '../helpers';

export const PromotionsPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken') ?? params.accessToken;
  const navigateToPage = useDashboardNavigate();
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());
  const { setSearch, debouncedSearch } = useSearch(1);
  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );
  const [includeExpired, setIncludeExpired] = useState<boolean>(false);
  const [selectedPromotion, setSelectedPromotion] = useState<ApiPromotion>();
  const theme = useTheme();

  const { data: dashboardData } = useDashboard(accessToken);

  const {
    categories,
    manufacturers,
    retailer,
  } = dashboardData ?? defaultDashboardData;

  const manufacturerIds = Array.from(filterSelections?.get('manufacturer') ?? []);
  const categoryIds = Array.from(filterSelections?.get('category') ?? []);
  const daysUntilExpiration = filterSelections?.get('expiration')?.values().next().value;
  const promotionsQuery: PromotionEndpoint.ListForRetailer.Query = {
    accessToken,
    categoryId: categoryIds,
    includeExpired,
    limit: 20,
    manufacturerIds,
    retailerId: retailer?.id,
    search: debouncedSearch,
    sort: 'endDate',
    sortDesc: includeExpired,
  };
  if (daysUntilExpiration) {
    promotionsQuery.daysUntilExpiration = daysUntilExpiration;
  }

  const {
    data: promotions,
    fetchNextPage,
    hasNextPage,
    isFetching: arePromotionsFetching,
    isLoading: isLoadingPromotions,
  } = useInfiniteQuery(
    [QueryKeys.GET_PROMOTIONS_FOR_RETAILER, promotionsQuery, selectionsString],
    async ({ pageParam }) => PromotionApi.listForRetailer({
      ...promotionsQuery,
      page: pageParam,
    }),
    {
      enabled: !!retailer?.id,
      getNextPageParam: (lastPage) => (
        lastPage.lastPage > lastPage.page
          ? lastPage.page + 1
          : undefined
      ),
    },
  );

  if (!dashboardData) {
    return (
      <Container>
        <Stack alignItems="center">
          <VSpacer size="14" />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  const filters: Filter[] = [
    {
      id: 'category',
      label: 'Category',
      options: categories.map(({ id, name }) => ({
        id,
        label: name ?? id,
      })) ?? [],
      selectionMethod: 'multi-select',
    },
    {
      id: 'manufacturer',
      label: 'Manufacturer',
      options: manufacturers.map(({ id, name }) => ({
        id,
        label: name ?? id,
      })) ?? [],
      selectionMethod: 'multi-select',
    },
    {
      id: 'expiration',
      label: 'Expiration',
      options: [
        {
          id: '3',
          label: 'Next 3 days',
        },
        {
          id: '7',
          label: 'Next 7 days',
        },
        {
          id: '30',
          label: 'Next 30 days',
        },
      ],
      selectionMethod: 'single-select',
    },
  ];

  const Header = (
    <TopAppBar
      isStatic
      leftAccessory={(
        <IconButton
          onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD, accessToken)}
          sx={{ color: 'white' }}
          testID="back-button"
        >
          <ArrowBack />
        </IconButton>
      )}
      rightAccessory={<HSpacer size="7" />}
      sx={{ bgcolor: retailer.brandColor }}
      testID="top-app-bar"
    >
      <Text category="title-large" sx={{ color: 'white' }}>
        Promotions
      </Text>
    </TopAppBar>
  );

  return  (
    <InfiniteScroll
      fetchMoreItems={fetchNextPage}
      hasMore={hasNextPage ?? true}
      isLoading={arePromotionsFetching || isLoadingPromotions}
      sx={{
        backgroundColor: theme.palette.background.default,
        maxWidth: '599px',
        minWidth: '360px',
      }}
    >
      {Header}
      <StickyElement>
        <Stack
          bgcolor={theme.palette.background.default}
          maxWidth="599px"
          minWidth="360px"
          px="16px"
        >
          <VSpacer size="5" />
          <Text category="body-small">
            Point values are per $1 spent or per unit of measure purchased. See promotion details.
          </Text>
          <VSpacer size="5" />
        </Stack>
      </StickyElement>
      <Stack px="16px">
        <Toolbar
          filters={filters}
          hideAllFilter={false}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            setFilterSelections(selections);
          }}
          testID="promotions-toolbar"
          themeColor={retailer.brandColor}
        />
        <VSpacer size="6" />
        <Checkbox
          checked={includeExpired}
          onChangeChecked={setIncludeExpired}
          removeLabelSpacing
          sx={{ marginLeft: '12px' }}
          testID="include-expired-checkbox"
        >
          <Text category="body-large">
            Include expired
          </Text>
        </Checkbox>
        <VSpacer size="5" />
      </Stack>
      <Divider />
        <Stack px="16px">
          {promotions?.pages.map((group, i) => (
            <Fragment key={i}>
              {group.data.map((promotion) => (
                <Fragment key={promotion.id}>
                  <VSpacer size="5" />
                  <PromotionCard
                    listItem
                    onClickDetails={() => {
                      setSelectedPromotion(promotion);
                    }}
                    promotion={promotion}
                    width="100%"
                  />
                </Fragment>
              ))}
            </Fragment>
          ))}
          <VSpacer size="5" />
        </Stack>
      {!!selectedPromotion && accessToken && (
        <PromotionDetailsModal
          accessToken={accessToken}
          onClose={() => {
            setSelectedPromotion(undefined);
          }}
          promotionId={selectedPromotion.id}
          retailerName={retailer.name}
        />
      )}
    </InfiniteScroll>
  );
};
