import { QueryKeys } from '@/constants/QueryKeys';
import { useQuery } from 'react-query';
import { RetailerLocationApi } from "@/utilities/api/RetailerLocationApi";
import { Resolved } from "@shared/utilities/UtilityTypes";
import { Filter, FilterOption } from "@/components/DesignSystem/Toolbar/interfaces";
import { SharedConfig } from "@shared/SharedConfig";
import { HierarchyOfRetailersApi } from "@/utilities/api/HierarchyOfRetailersApi";
import { useAuthentication } from "@/contexts/dataSync/AuthenticationContext";

export const useRetailerLocationFilter = (): Filter => {
  const { retailer, isInternal } = useAuthentication();

  const { data: locations } = useQuery(
    [retailer?.id ? QueryKeys.GET_RETAILER_LOCATION_LIST : QueryKeys.GET_LOCATIONS],
    async () => {
      if (retailer?.id) {
        return HierarchyOfRetailersApi.getRetailerLocations(retailer.id, {
          limit: SharedConfig.maxPageLimit,
        });
      } else if (isInternal) {
        return RetailerLocationApi.list({
          isActive: true,
          limit: SharedConfig.maxPageLimit,
        });
      }
    },
    { staleTime: 5 * 60 * 1000 },
  );

  if (retailer) {
    return {
      id: 'locations',
      label: 'Locations',
      options: locations?.data.map((location) => ({
        id: location.id,
        label: location.name,
      })) ?? [],
      selectionMethod: 'multi-select',
    };
  }

  const filterOptionsMap:
    Map<string, Resolved<FilterOption, 'subOptionLabel' | 'subOptions'>> = new Map();

  locations?.data.forEach(({ id, name, retailer }) => {
    if (!retailer) {
      return;
    }
    if (filterOptionsMap.has(retailer.id)) {
      filterOptionsMap.get(retailer.id)?.subOptions.push({
        id,
        label: name,
      });
    } else {
      filterOptionsMap.set(retailer.id, {
        id: retailer.id,
        label: retailer.name,
        subOptionLabel: 'Locations',
        subOptions: [{
          id,
          label: name,
        }],
      });
    }
  });

  return {
    id: 'locations',
    label: 'Retailer & Locations',
    options: [...filterOptionsMap.values()]
      .sort((a, b) => a.label.localeCompare(b.label)),
    selectionMethod: 'multi-select',
  };
};
