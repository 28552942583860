import { Button, HSpacer, ProgressLoader, Text, VSpacer } from '@/components/DesignSystem';
import React, { Fragment } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Divider, Stack } from '@mui/material';
import ManufacturerListItem from './ManufacturerListItem';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { QueryKeys } from '@/constants/QueryKeys';
import { useQuery } from 'react-query';
import UploadImage from './UploadImage';
import { LoyaltySkuTable } from "@/pages/Admin/LoyaltySkuTable";
import { HierarchyOfRetailersApi } from "@/utilities/api/HierarchyOfRetailersApi";
import { ReactComponent as DefaultRetailerIcon } from '@/assets/icons/retailer-icon.svg';
import { AppConfig } from "@/constants/AppConfig";
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';

const ManufacturerDetails = () => {
  const { id: manufacturerId = '' } = useParams();
  const navigate = useNavigate();

  const { data: manufacturer, isFetching } = useQuery(
    [QueryKeys.GET_MANUFACTURER_BY_ID, manufacturerId],
    () => manufacturerId ? ManufacturerApi.get(manufacturerId) : null,
    { enabled: !!manufacturerId },
  );

  const { data: retailer } = useQuery(
    [QueryKeys.GET_RETAILER_BY_ID, manufacturer?.retailerId],
    () => {
      return HierarchyOfRetailersApi.getRetailerById(manufacturer?.retailerId ?? '');
    },
    { enabled: !!manufacturer?.retailerId },
  );

  if (isFetching) {
    return (
      <div
        style={{
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <ProgressLoader type='circular' />
      </div>
    );
  }

  const imageUrl = retailer?.image && `${AppConfig.staticImageHost}/${retailer.image}`;

  return (
    <Fragment>
      <Container maxWidth='lg'>
        <VSpacer size='5' />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => navigate(-1)}
            sx={{ display: 'flex', align: 'center' }}
            testID='back-to-all-retailers-button'
            variant='text'
          >
            <KeyboardBackspaceIcon sx={{ marginRight: '5px' }} />
            Back to all manufacturers
          </Button>
        </div>
        {!!manufacturer && (<>
          <VSpacer size='5' />
          {retailer && (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              sx={{
                backgroundColor: '#EBEBEB',
                padding: "8px 0",
              }}
            >
              <Avatar
                alt={`${retailer.name} logo`}
                height={20}
                src={imageUrl ?? undefined}
                sx={{
                  filter: 'invert(100%)',
                }}
                width={20}
              >
                {retailer.image ? null : (
                  <DefaultRetailerIcon height={14} width={14}/>
                )}
              </Avatar>
              <HSpacer size="3"/>
              <Text category="title-small" sx={{ color: "#141414" }}>
                {retailer.name} manufacturer
              </Text>
            </Stack>
          )}
          <ManufacturerListItem manufacturer={manufacturer} showID={true}/>
          <VSpacer size="5"/>
          <UploadImage
            image={manufacturer.logo}
            manufacturerId={manufacturerId}
            type="Logo"
          />
          <VSpacer size="2"/>
          <Divider/>
          <VSpacer size="2"/>
          <UploadImage
            image={manufacturer.logomark}
            manufacturerId={manufacturerId}
            type="Logomark"
          />
          <VSpacer size="2"/>
          <Divider/>
          <VSpacer size="2"/>
          {retailer && (
            <Stack sx={{ padding: "16px" }}>
              <LoyaltySkuTable
                manufacturerId={manufacturer.id}
                retailerId={retailer.id}
              />
            </Stack>
          )}
        </>)
        }
      </Container>
    </Fragment>
  );
};

export default ManufacturerDetails;
