import { Button, Input, Text, VSpacer } from '@/components/DesignSystem';
import { useAddRetailer, useEditRetailer } from '@/hooks/useHierarchyOfRetailers';
import { ApiRetailer, UpdateRetailer } from '@api/interfaces';
import { Alert, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getObjectChanges, trimValue } from '@shared/utilities';

type FormInputs = Pick<ApiRetailer, 'isActive' | 'isFeatured' | 'name'>;

type RetailerFormDialogProps = {
  isAddRetailerOpen: boolean;
  setIsAddRetailerOpen: (isAddRetailerOpen: boolean) => void;
  initialValue?: FormInputs;
};
const RetailerFormDialog = ({
  isAddRetailerOpen,
  initialValue = {
    isActive: true,
    isFeatured: false,
    name: '',
  },
  setIsAddRetailerOpen,
}: RetailerFormDialogProps) => {

  const [retailer, setRetailer] = useState(initialValue);
  const [error, setError] = useState('');

  const { id } = useParams();

  const isEditDisabled = Object.entries(retailer).every(
    ([key, value]: [string, string | boolean | undefined | null]) =>
      initialValue[key as keyof FormInputs] === value,
  );
  const isEditing = !!initialValue.name;

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'name') {
      setError('');
    }
    setRetailer({
      ...retailer,
      [e.target.name]: e.target.value.trimStart(),
    });
  };

  const onClose = () => {
    setIsAddRetailerOpen(false);
  };

  const { addRetailer, isLoading } = useAddRetailer(setIsAddRetailerOpen, setError);
  const { editRetailer, isLoading: isEditLoading } = useEditRetailer(
    id ?? '',
    setIsAddRetailerOpen,
    setError,
  );

  const onSave = async () => {
    const trimmedInputs = Object.fromEntries(
      Object.entries(retailer).map(([key, nestedValue]) =>
        [key, trimValue(nestedValue)]),
    ) as FormInputs;

    const changedInputs = getObjectChanges(
      initialValue,
      trimmedInputs,
    ) as UpdateRetailer;

    if (id) {
      editRetailer(changedInputs);
    } else {
      addRetailer({
        ...changedInputs,
        name: changedInputs?.name?.toString() ?? '',
      });
    }
  };

  return (
    <Dialog fullWidth onClose={onClose} open={isAddRetailerOpen}>
      <DialogContent>
        <Stack spacing={3}>
          {!!error && (
            <Alert color='error' icon={false}>{error}</Alert>
          )}
          <Text category='h6'>{isEditing ? 'Edit' : 'Add'} Retailer</Text>
          <Input
            label='Organization name'
            name='name'
            onChange={onInputChange}
            required
            testID='hierarchy-retailer-name'
            value={retailer.name}
          />
        </Stack>
      </DialogContent>
      <VSpacer size='8' />
      <DialogActions>
        <Button
          disabled={isLoading || isEditLoading}
          onClick={onClose}
          testID='hierarchy-retailer-cancel'
          variant='text'
        >
          Cancel
        </Button>
        <Button
          disabled={!retailer?.name
            || isEditDisabled || isLoading || isEditLoading || !!error}
          loading={isLoading || isEditLoading}
          onClick={onSave}
          testID='hierarchy-retailer-save'
        >
          Save
        </Button>
      </DialogActions>
      <VSpacer size='5' />
    </Dialog>
  );
};

export default RetailerFormDialog;
