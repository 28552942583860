import { Modal, ModalProps } from '@/components/DesignSystem/Modal/Modal';
import StickyElement from '@/components/shared/StickyElement';
import { Stack, useTheme } from '@mui/material';
import { Button, Text, VSpacer } from '@/components/DesignSystem';
import Close from '@mui/icons-material/Close';
import React from 'react';

interface DashboardModalProps extends ModalProps {
  hideCloseButtonIcon?: boolean;
  title: string,
}

export const DashboardModal = ({
  hideCloseButtonIcon = false,
  title,
  ...props
}: DashboardModalProps) => {
  const theme = useTheme();
  return (
    <Modal
      containerStyleOverride={{ height: '100%', pb: '68px', pt: 0, px: 0 }}
      hideOnlyHeaderOnMobile
      {...props}
    >
      <Stack height="100%">
        <StickyElement>
          <Stack
            alignItems="center"
            bgcolor={theme.palette.background.default}
          >
            <VSpacer size="6"/>
            <Text category="title-large">
              {title}
            </Text>
            <VSpacer size="6"/>
          </Stack>
        </StickyElement>
        <Stack flexGrow={1} overflow="auto">
          {props.children}
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        bgcolor="white"
        bottom="0"
        justifyContent="center"
        minHeight="72px"
        padding="0px 16px"
        position="fixed"
        sx={{ boxShadow: '0px 2px 16px 0px #0E1E2552' }}
        width="100%"
      >
        <Button
          onClick={props.onClose}
          startIcon={hideCloseButtonIcon ? undefined : <Close />}
          sx={{
            bgcolor: theme.palette.grey[100],
            boxShadow: 'none',
            color: theme.palette.text.primary,
            width: '100%',
            '&:hover': { bgcolor: 'transparent', borderColor: 'inherit' },
          }}
          testID={`${props.testID}-close-button`}
        >
          Close
        </Button>
      </Stack>
    </Modal>
  );
};
