import { PieChart as MuiPieChart } from '@mui/x-charts/PieChart';

interface PieChartProps {
  heightAndWidth: number,
  values: number[],
}

export const ColorIndex = [
  '#1A90FE',
  '#0241A0',
  '#06BAD1',
  '#822B9D',
  '#D0D004',
  '#454B1B',
  '#FE5448',
  '#A81022',
  '#CF7FFC',
  '#E28922',
];

export const PieChart = ({
  heightAndWidth,
  values,
}: PieChartProps) => {
  const seriesData = values.map((value, index) => ({ color: ColorIndex[index], value }));

  return (
    <MuiPieChart
      height={heightAndWidth}
      margin={{ right: 0 }}
      series={[{ data: seriesData }]}
      width={heightAndWidth}
    />
  );
};
