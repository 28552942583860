import { AppConfig } from '@/constants/AppConfig';
import { getUserDisplayName } from '@/utilities/Farmer';
import { ApiUser } from '@api/interfaces';
import { UserType } from '@shared/enums';
import { isRetailerUser } from '@shared/utilities';

type AccountList = {
  [id: string]: {
    name: string,
    token: string,
    type: UserType,
  },
}

type Account = {
  id: string,
  name: string,
  type: UserType,
}

const key = "Bidding_Accounts";

function read () {
  return JSON.parse(localStorage.getItem(key) ?? '{}') as AccountList;
}

function write (list: AccountList) {
  localStorage.setItem(key, JSON.stringify(list));
}

/**
 * Used in concert with UserSwitcher to store account tokens in Local Storage.
 */
export class LocalAccountList {
  static clear () {
    write({});
  }

  static get (id: string) {
    return read()[id];
  }

  static getToken (id: string): string {
    return read()[id].token;
  }

  static isActive (): boolean {
    if (AppConfig.env.prod) { return false; }
    const accounts = Object.values(read());
    return accounts.some((account) => account.type === UserType.Internal);
  }

  static list ()  {
    const list = read();
    const accounts: Account[] = [];
    Object.keys(list).forEach((id) => {
      accounts.push({
        id,
        name: list[id].name,
        type: list[id].type,
      });
    });
    return accounts;
  }

  static remove (id: string) {
    const list = read();
    delete list[id];
    write(list);
  }

  static set (user: ApiUser, token: string) {
    if (user.userType !== UserType.Internal && !this.isActive()) {
      return;
    }
    const userName = getUserDisplayName(user);
    const name = isRetailerUser(user)
      ? user.businessName!
      : userName;

    const list = read();
    list[user.id] = {
      name,
      token,
      type: user.userType,
    };
    write(list);
  }
}
