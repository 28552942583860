import { VSpacer } from '@/components/DesignSystem';
import { Routes } from "@/constants/Routes";
import CustomerList from '@/pages/FarmerList/CustomerList';
import ProductList from '@/pages/Product/ProductList';
import { Promotions } from '@/pages/Promotions/Promotions';
import { VerifyEmail } from '@/pages/Auth/VerifyEmail';
import { RetailerHomePage } from '@/pages/HomePage/RetailerHomePage';
import { RetailerAppFooter } from '@/views/RetailerAppFooter';
import { AppHeader } from '@/components/shared/AppHeader';
import React, { ReactNode } from 'react';
import { Navigate, Route, Routes as AppRoutes } from "react-router-dom";
import CustomerDetails from "@/pages/FarmerList/CustomerDetails";

export const RetailerView = () => {
  const createLayout = (element: ReactNode) => {
    return (
      <>
        <AppHeader />
        <VSpacer size="11" />
        {element}
        <VSpacer size="11" />
        <RetailerAppFooter />
      </>
    );
  };

  return (
    <AppRoutes>
      <Route element={createLayout(<RetailerHomePage/>)} path={Routes.HOMEPAGE} />
      <Route element={createLayout(<CustomerList />)} path={Routes.CUSTOMERS} />
      <Route element={createLayout(<CustomerDetails />)} path={Routes.CUSTOMER_DETAILS} />
      <Route element={createLayout(<Promotions />)} path={Routes.PROMOTIONS} />
      <Route element={createLayout(<ProductList />)} path={Routes.PRODUCTS} />
      <Route element={createLayout(<VerifyEmail />)} path={Routes.VERIFY_EMAIL} />
      <Route element={<Navigate to={Routes.HOMEPAGE}/>} path={"*"}/>
    </AppRoutes>
  );
};
