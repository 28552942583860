import DivisionSign from '@/assets/icons/division-sign.svg';
import EqualSign from '@/assets/icons/equal-sign.svg';
import { HSpacer, MenuItem, NumericInput, Select, Text, VSpacer } from '@/components/DesignSystem';
import { RewardsLoyaltyValueInput } from "@/components/shared/RewardsLoyaltyValueInput";
import { PromotionInputs } from "@/pages/Promotions/PromotionModal/PromotionModal";
import { Stack } from '@mui/material';
import { roundToFixed } from '@shared/utilities';
import { isNil } from 'lodash';
import React, { useState } from "react";

interface WorkflowStep2Props {
  onPromotionChanged: (promotion: PromotionInputs) => void,
  promotion: PromotionInputs,
}

type CapCalculationType = 'dollars' | 'points';

export const WorkflowStep2 = ({
  onPromotionChanged,
  promotion,
}: WorkflowStep2Props) => {
  let initialCapCalculationType: CapCalculationType | null = null;
  if (!isNil(promotion.dollarCap)) {
    initialCapCalculationType = 'dollars';
  } else if (!isNil(promotion.pointCap)) {
    initialCapCalculationType = 'points';
  }
  const [capCalculationType, setCapCalculationType] = useState<CapCalculationType | null>(
    initialCapCalculationType,
  );
  return (
    <>
      <RewardsLoyaltyValueInput
        onUpdate={(updates) => {
          onPromotionChanged(
            {
              ...promotion,
              ...updates,
              ...(updates.uom !== promotion.uom && {
                productIdsForEarningPoints: undefined,
                productIdsForRedeemingPoints: undefined,
              }),
            },
          );
        }}
        rewardType={promotion.rewardType}
        testID={`${promotion.id}-loyalty-rewards-input`}
        uom={promotion.uom}
        value={promotion.value}
        width="560px"
      />
      <VSpacer size="9" />
      <Text category="title-medium">
        Cap on loyalty points earned (optional)
      </Text>
      <VSpacer size="4" />
      <Stack alignItems="center" direction="row">
        <Select
          label="Calculate as"
          onChangeValue={(value) => {
            setCapCalculationType(value as CapCalculationType);
            onPromotionChanged(
              {
                ...promotion,
                dollarCap: null,
                pointCap: null,
              },
            );
          }}
          testID="cap-calculation-select"
          value={capCalculationType}
          width={150}
        >
          <MenuItem testID="dollars-menu-item" value="dollars">
            Dollars
          </MenuItem>
          <MenuItem testID="points-menu-item" value="points">
            Points
          </MenuItem>
        </Select>
        {!!capCalculationType && (
          <>
            <HSpacer size="3" />
            <NumericInput
              decimals={2}
              minValue={0}
              onChangeNumber={(value) => {
                let pointCap = null;
                if (value) {
                  if (capCalculationType === 'dollars') {
                    pointCap = Number(roundToFixed(value / 1.265, 2));
                  } else {
                    pointCap = value;
                  }
                }
                onPromotionChanged(
                  {
                    ...promotion,
                    dollarCap: capCalculationType === 'dollars' ? (value ?? null) : null,
                    pointCap,
                  },
                );
              }}
              placeholder={capCalculationType === 'points' ? '0.00' : '$0.00'}
              prefix={capCalculationType === 'points' ? '' : '$'}
              showFixedDecimals={capCalculationType === 'dollars'}
              testID="cap-calculation-input"
              value={promotion.dollarCap ?? promotion.pointCap ?? undefined}
              width={139}
            />
            {capCalculationType === 'dollars' && (
              <>
                <HSpacer size="5"/>
                <img alt="division sign" src={DivisionSign} />
                <HSpacer size="4"/>
                <Text category="body-large">1.265</Text>
                <HSpacer size="4"/>
                <img alt="equal sign" src={EqualSign} />
                <HSpacer size="4"/>
                {!isNil(promotion.dollarCap) && !isNil(promotion.pointCap) && (
                  <Text category="body-large">
                    {promotion.pointCap} points
                  </Text>
                )}
              </>
            )}
          </>
        )}
      </Stack>
    </>
  );
};
