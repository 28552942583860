import { Button, ProgressLoader, Text, VSpacer } from "@/components/DesignSystem";
import { FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { ConfirmDialog } from '@/components/shared/ConfirmDialog';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSearch } from '@/hooks/useSearch';
import { PromotionCard } from '@/pages/Promotions/PromotionCard';
import { PromotionDetailsModal } from '@/pages/Promotions/PromotionDetailsModal';

import { PromotionInputs, PromotionModal } from '@/pages/Promotions/PromotionModal/PromotionModal';

import { PromotionApi } from '@/utilities/api/PromotionApi';
import { Container, Pagination, Stack } from "@mui/material";
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { formatDateOnly } from "@shared/utilities";
import { PromotionEndpoint } from "@api/endpoints";
import { ToastMessages } from "@/constants/constant";
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { DetailedApiError } from "@/utilities/api/DetailedApiError";
import { RewardsType } from "@shared/enums/RewardsType";
import { round } from "lodash";
import { PromotionsToolbar } from "@/pages/Promotions/PromotionsToolbar";
import { useAuthentication } from "@/contexts/dataSync/AuthenticationContext";

export const Promotions = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();

  const { retailer, isInternal } = useAuthentication();

  const { setSearch, debouncedSearch } = useSearch(3);

  const [page, setPage] = useState(0);
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPromotionDetailsModal, setShowPromotionDetailsModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  useEffect(() => {
    setPage(0);
  }, [selectionsString, debouncedSearch]);

  async function handleDelete (id: string) {
    await PromotionApi.delete(id);
    await queryClient.invalidateQueries(QueryKeys.GET_PROMOTIONS);
  }

  async function handleRestart (id: string) {
    await PromotionApi.restartProcessing(id);
    await queryClient.invalidateQueries(QueryKeys.GET_PROMOTIONS);
  }

  async function onSave (promotion: PromotionInputs) {
    try {
      await createPromotion(promotion as Omit<Required<PromotionInputs>, 'id'>);
      await queryClient.invalidateQueries([QueryKeys.GET_PROMOTIONS]);
      openSnackbar(ToastMessages.promotionCreated);
      setShowPromotionModal(false);
    } catch (err) {
      if (err instanceof DetailedApiError) {
        openSnackbar(err.message);
      }
    }
  }

  const { mutateAsync: createPromotion } = useMutation(
    async (promotion: Omit<Required<PromotionInputs>, 'id'>) => {
      const requestBody = {
        ...promotion,
        manufacturerId: promotion.manufacturerId ? promotion.manufacturerId : undefined,
        startDate: formatDateOnly(promotion.startDate),
        endDate: formatDateOnly(promotion.endDate),
        value: promotion.rewardType === RewardsType.Dollars
          ? round(promotion.value, 4)
          : round(promotion.value / 100, 5),
      };
      return PromotionApi.create(requestBody as PromotionEndpoint.Create.Request);
    },
  );

  const isActive = !filterSelections?.get('show-expired')?.has('show-expired') || undefined;
  const manufacturerIds = Array.from(filterSelections?.get('manufacturers') ?? []);
  const productIds = Array.from(filterSelections?.get('products') ?? []);
  const locationIds = Array.from(filterSelections?.get('locations') ?? []);
  const sortBy = Array.from(filterSelections?.get('sortBy') ?? []);

  const query = {
    isActive,
    limit: 20,
    locationIds,
    manufacturerIds,
    page,
    productIds,
    retailerId: retailer?.id,
    search: debouncedSearch,
    sort: sortBy[0],
  };

  const { data: promotions, isFetching: arePromotionsFetching } = useQuery(
    [QueryKeys.GET_PROMOTIONS, query],
    async () => PromotionApi.list(query),
    { staleTime: 5 * 60 * 1000 },
  );

  const Header = (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Text category="display-small">
          Promotions
        </Text>
        {isInternal && (
          <Button
            onClick={() => {
              setShowPromotionModal(true);
            }}
            testID="create-promotion-button"
          >
            Add Promotion
          </Button>
        )}
      </Stack>
      <VSpacer size="5" />
      <PromotionsToolbar
        onChange={({ search, selections }) => {
          setSearch(search ?? '');
          setFilterSelections(selections);
        }}
        totalItems={promotions?.total}
      />
    </>
  );

  const PromotionCards = !!promotions?.data.length && (
    <Stack gap="16px">
      {promotions.data.map((promotion) => (
        <PromotionCard
          key={promotion.id}
          onDelete={() => {
            setSelectedId(promotion.id);
            setShowDeleteDialog(true);
          }}
          onEdit={() => {
            setSelectedId(promotion.id);
            setShowPromotionModal(true);
          }}
          onRestartProcessing={() => handleRestart(promotion.id)}
          onView={() => {
            setSelectedId(promotion.id);
            setShowPromotionDetailsModal(true);
          }}
          promotion={promotion}
          readonly={!!retailer?.id}
          testID={`promotion-${promotion.name}`}
        />
      ))}
    </Stack>
  );

  return (
    <>
      <Container maxWidth="lg">
        <VSpacer size="10" />
        {Header}
        <VSpacer size="6" />
        {arePromotionsFetching ? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : PromotionCards}
        {!!promotions?.total && promotions?.lastPage > 0 && (
          <Stack alignItems="center" py="20px">
            <Pagination
              count={promotions?.lastPage + 1}
              onChange={(event, page) => {
                setPage(page - 1);
              }}
              page={promotions?.page + 1}
            />
          </Stack>
        )}
      </Container>
      {showPromotionModal && (
        <PromotionModal
          onClose={() => {
            setShowPromotionModal(false);
            setSelectedId(undefined);
          }}
          onSave={onSave}
          show
        />
      )}
      {showDeleteDialog && !!selectedId && (
        <ConfirmDialog
          cancelText="Go back"
          confirmText="Yes, delete"
          onCancel={() => {
            setShowDeleteDialog(false);
            setSelectedId(undefined);
          }}
          onConfirm={async () => {
            setShowDeleteDialog(false);
            await handleDelete(selectedId);
            setSelectedId(undefined);
          }}
          open
          testID={'delete-promotion-dialog'}
          title="Delete Promotion"
        >
          Are you sure you want to delete this promotion?
        </ConfirmDialog>
      )}
      {showPromotionDetailsModal && !!selectedId && (
        <PromotionDetailsModal
          onClose={() => setShowPromotionDetailsModal(false)}
          promotionId={selectedId}
        />
      )}
    </>
  );
};
