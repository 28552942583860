import { Button, Text } from '@/components/DesignSystem';
import { Stack, useTheme } from '@mui/material';

export const RetailerAppFooter = () => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      bgcolor={theme.palette.background.default}
      borderTop="1px solid rgba(0, 0, 0, 0.04)"
      bottom={0}
      direction="row"
      height="64px"
      justifyContent="space-between"
      p="16px 32px"
      position="fixed"
      width="100%"
    >
      <Stack alignItems="center" direction="row">
        <Text category="body-small" sx={{ color: '#393939' }}>Powered by&nbsp;</Text>
        <Text
          category="body-small"
          fontWeight={700}
          letterSpacing={0.5}
          sx={{ color: '#393939' }}
        >
          GROWERS
        </Text>
      </Stack>
      <Stack direction="row">
        <Button
          href="https://growers.ag/privacy-policy/#privacypolicy"
          onClick={(e) => {
            e.preventDefault();
            window.open('https://growers.ag/privacy-policy/#privacypolicy', '_blank');
          }}
          testID="privacy-policy-button"
          variant="text"
        >
          Privacy policy
        </Button>
        <Button
          href="https://growers.ag/privacy-policy/#termsofuse"
          onClick={(e) => {
            e.preventDefault();
            window.open('https://growers.ag/privacy-policy/#termsofuse', '_blank');
          }}
          testID="terms-of-use-button"
          variant="text"
        >
          Terms of use
        </Button>
      </Stack>
    </Stack>
  );
};
