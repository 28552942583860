import { useColor } from '@/hooks/useColor';
import { Color } from '@/themes/MUITheme/palette';
import { ButtonGroup, ButtonGroupProps, useTheme } from '@mui/material';
import { Children, cloneElement, ReactElement } from 'react';

interface SegmentedButtonProps extends ButtonGroupProps {
  children: ReactElement[],
  color: Color,
  selectedIndex: number | null,
}

export const SegmentedButton = ({
  children,
  color,
  selectedIndex,
  ...rest
}: SegmentedButtonProps) => {
  const theme = useTheme();
  const { getColor } = useColor();
  const getChildStyles = (child: ReactElement, index: number) => {
    if (child.props.disabled) {
      return {};
    }
    if (index === selectedIndex) {
      return {
        "&:hover": {
          borderColor: theme.palette.divider,
        },
        backgroundColor: getColor(color, 1),
        borderColor: theme.palette.divider,
        color: getColor(color, 5),
      };
    }
    return {
      "&:hover": {
        borderColor: theme.palette.divider,
      },
      borderColor: theme.palette.divider,
      color: theme.palette.text.primary,
    };
  };

  const renderChildren = () => {
    return Children.map(children, (child: ReactElement, index) => {
      return cloneElement(child, {
        sx: getChildStyles(child, index),
        variant: child.props.disabled ? undefined : 'outlined',
      });
    });
  };
  return (
    <ButtonGroup color={color} {...rest}>
      {renderChildren()}
    </ButtonGroup>
  );
};
