import BlackFullLogo from '@/assets/logos/black/app_full_black.svg';
import { VSpacer } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { useRetailerMetadata } from '@/hooks/useHierarchyOfRetailers';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { LoginForm } from '@/pages/Auth/LoginForm';
import OtpForm from '@/pages/Auth/OtpForm';
import { Stack } from '@mui/material';
import { SharedConfig } from '@shared/SharedConfig';
import React, { useState } from 'react';

export const LoginPage = () => {
  const { isMobile } = useMediaQuery();
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [lastFour, setLastFour] = useState<string>();
  const [username, setUsername] = useState<string>();
  const { metadata } = useRetailerMetadata();

  const retailerImageUrl = metadata?.image && `${AppConfig.staticImageHost}/${metadata.image}`;
  const isLoyaltyProgramDomain = window.location.hostname.includes(SharedConfig.shortUrlDomain);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      paddingTop={isMobile ? '0px' : '100px'}
    >
      <Stack display="flex" flex={1} maxWidth="512px">
        <Stack
          alignItems="center"
          borderRadius="24px"
          boxShadow={
            "0px 1px 2px 0px rgba(0, 0, 0, 0.07),"
            + "0px 2px 4px 0px rgba(0, 0, 0, 0.07),"
            + "0px 4px 8px 0px rgba(0, 0, 0, 0.07),"
            + "0px 8px 16px 0px rgba(0, 0, 0, 0.07),"
            + "0px 16px 32px 0px rgba(0, 0, 0, 0.07),"
            + "0px 32px 64px 0px rgba(0, 0, 0, 0.07)"
          }
          padding="60px 24px 40px 24px"
          width="560px"
        >
          {(isLoyaltyProgramDomain || retailerImageUrl) ? (
            <img
              alt={`${metadata?.name} logo`}
              height={40}
              src={retailerImageUrl ?? undefined}
            />
          ) : (
            <img
              alt="logo"
              src={BlackFullLogo}
              style={{ height: '40px', width: '205px', objectFit: 'contain' }}
            />
          )}
          <VSpacer size="8" />
          {(showOtpForm && username && lastFour) ? (
            <OtpForm
              lastFour={lastFour}
              onCancel={() => setShowOtpForm(false)}
              sendOtp={false}
              username={username}
            />
          ) : (
            <LoginForm
              onConfirmSuccess={({ username, lastFour }) => {
                setShowOtpForm(true);
                setLastFour(lastFour);
                setUsername(username);
              }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
