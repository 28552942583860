import { Button, Chip, HSpacer, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { Table, TableRow } from '@/components/DesignSystem/Table/Table';
import ConfirmModal from '@/components/shared/ConfirmModal';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { useGetRetailerById, useGetRetailerMembers } from '@/hooks/useHierarchyOfRetailers';
import { useSearch } from '@/hooks/useSearch';
import {
  AddRecipientsSection,
} from '@/pages/Admin/HierarchyOfRetailers/Recipients/AddRecipientsSection';
import {
  BrandAssetInputs,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/BrandAssetInputs';
import { DescriptionCard } from '@/pages/Admin/HierarchyOfRetailers/Retailer/DescriptionCard';
import { ImportErpModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/ImportErpModal';
import {
  RewardsProgramsCard,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramsCard';
import { LoyaltySkuTable } from '@/pages/Admin/LoyaltySkuTable';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { Color } from '@/themes/MUITheme/palette';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { ImportApi } from '@/utilities/api/ImportApi';
import { JobApi } from '@/utilities/api/JobApi';
import { RetailerEndpoint } from '@api/endpoints';
import { ApiImportHistory } from '@api/interfaces';
import { Autorenew, DeleteOutline } from '@mui/icons-material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import UploadIcon from '@mui/icons-material/Upload';
import {
  CircularProgress,
  Container,
  Divider,
  Pagination,
  Stack,
  TableCell,
  TableRow as MuiTableRow,
} from '@mui/material';
import { ErrorType, ImportStatus } from '@shared/enums';
import { ProcessingStatus } from '@shared/enums/ProcessingStatus';
import { stringify } from 'csv-stringify/browser/esm/sync';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import LocationCard from './Locations/LocationOverviewSection';
import MembersCard from './Members/MemberOverviewSection';
import RetailerDetailsCard from './RetailerDetailsCard';

const Loader = () => (
  <Container>
    <Stack alignItems='center'>
      <VSpacer size='14' />
      <CircularProgress />
    </Stack>
  </Container>
);

const RetailerDetailsOverviewPage = () => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [showAddMember, setShowAddMember] = useState(false);
  const [showViewMember, setShowViewMember] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [historyPage, setHistoryPage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const backToAllRetailers = () => navigate(-1);

  const onAddMember = () => setShowAddMember(!showAddMember);
  const onViewMember = () => setShowViewMember(!showViewMember);
  const onAddLocation = () => setShowLocation(!showLocation);
  const { debouncedSearch, search, setSearch } = useSearch();

  const { retailerMembers, isLoading } = useGetRetailerMembers(id, debouncedSearch);

  const { data: importHistoryResult, isFetching: isFetchingHistory } = useQuery(
    [QueryKeys.GET_IMPORT_HISTORY_LIST, historyPage, id],
    () => ImportApi.getHistory({ limit: 5, page: historyPage, retailerId: id }),
    { enabled: !!id },
  );

  const { data: isImportingResult } = useQuery(
    [QueryKeys.GET_IMPORT_IS_IMPORTING, id],
    () => ImportApi.getHistory({ importStatus: ImportStatus.Importing, limit: 1, retailerId: id }),
    {
      enabled: !!id,
      refetchInterval: (result) => (result?.total ? 5000 : false),
    },
  );
  const isImporting = (isImportingResult?.total ?? 0) > 0;

  const { data: pendingImportResult } = useQuery(
    [QueryKeys.GET_IMPORT_PROCESSING_RESULT, id],
    () => ImportApi.getHistory({
      hasPointsProcessed: false,
      importStatus: ImportStatus.Complete,
      limit: 1,
      retailerId: id,
    }),
    {
      enabled: !!id,
      refetchInterval: (result, _) => {
        const _isAnyImportProcessing = !!result?.data.some(({ processingStatus }) => (
          processingStatus === ProcessingStatus.InProgress
        ));
        return _isAnyImportProcessing ? 5000 : false;
      },
    },
  );

  const { data: isProcessing } = useQuery(
    [QueryKeys.GET_JOB_IS_PROCESSING],
    () => JobApi.getIsProcessing(),
    {
      enabled: !!id,
      refetchInterval: (result, _) => result ? 5000 : false,
    },
  );

  useEffect(() => {
    void queryClient.invalidateQueries(QueryKeys.GET_IMPORT_HISTORY_LIST);
    void queryClient.invalidateQueries(QueryKeys.GET_IMPORT_PROCESSING_RESULT);
  }, [isImporting, queryClient]);

  useEffect(() => {
    void queryClient.invalidateQueries(QueryKeys.GET_IMPORT_HISTORY_LIST);
    void queryClient.invalidateQueries(QueryKeys.GET_JOB_IS_PROCESSING);
  }, [isProcessing, queryClient]);

  const { retailer } = useGetRetailerById(id ?? '');
  const rewardsProgram = retailer?.rewardsPrograms?.[0];
  const rewardsStartDate = rewardsProgram?.startDate
    ? DateTime.fromISO(rewardsProgram.startDate).toJSDate() : undefined;
  const rewardsEndDate = rewardsProgram?.endDate
    ? DateTime.fromISO(rewardsProgram.endDate).toJSDate() : undefined;
  const currentDate = new Date();
  const hasActiveRewardsProgram = !!rewardsStartDate
    && !!rewardsEndDate
    && rewardsStartDate <= currentDate
    && rewardsEndDate >= currentDate;

  const { mutate: updateRetailer } = useMutation(
    (updates: RetailerEndpoint.Update.Request) => (
      HierarchyOfRetailersApi.updateRetailer(id, updates)
    ),
    {
      onSuccess: async (_, updates) => {
        if (!retailer?.description && updates.description) {
          openSnackbar('Description added');
        } else if (retailer?.description && updates.description) {
          openSnackbar('Description updated');
        } else if (retailer?.description && updates.description === null) {
          openSnackbar('Description deleted');
        }

        if (updates.image) {
          openSnackbar('Logo uploaded');
        } else if (updates.image === null) {
          openSnackbar('Logo deleted');
        }

        if (!retailer?.appIcon && updates.appIcon) {
          openSnackbar('App icon added');
        } else if (retailer?.appIcon && updates.appIcon) {
          openSnackbar('App icon updated');
        } else if (retailer?.appIcon && updates.appIcon === null) {
          openSnackbar('App icon deleted');
        }

        if (!retailer?.brandColor && updates.brandColor) {
          openSnackbar('Brand color added');
        } else if (retailer?.brandColor && updates.brandColor) {
          openSnackbar('Brand color updated');
        } else if (retailer?.brandColor && updates.brandColor === null) {
          openSnackbar('Brand color deleted');
        }

        if (!retailer?.templateType && updates.templateType) {
          openSnackbar('Deliverable template added');
        } else if (retailer?.templateType && updates.templateType) {
          openSnackbar('Deliverable template updated');
        } else if (retailer?.templateType && updates.templateType === null) {
          openSnackbar('Deliverable template deleted');
        }

        if (!retailer?.websiteUrl && updates.websiteUrl) {
          openSnackbar('Website added');
        } else if (retailer?.websiteUrl && updates.websiteUrl) {
          openSnackbar('Website updated');
        } else if (retailer?.websiteUrl && updates.websiteUrl === null) {
          openSnackbar('Website deleted');
        }

        if (!retailer?.shortUrlName && updates.shortUrlName) {
          openSnackbar('Short URL added');
        } else if (retailer?.shortUrlName && updates.shortUrlName) {
          openSnackbar('Short URL updated');
        } else if (retailer?.shortUrlName && updates.shortUrlName === null) {
          openSnackbar('Short URL deleted');
        }

        await queryClient.invalidateQueries(QueryKeys.GET_RETAILER_BY_ID);
      },
      onError: (error: Error) => {
        openSnackbar(error.message || 'An error has occurred');
      },
    },
  );

  const { data: categories } = useQuery(
    [QueryKeys.GET_RETAILER_CATEGORIES, id],
    async () => {
      const result = await HierarchyOfRetailersApi.getRetailerCategories(id);
      return result.filter(({ name }) => name);
    },
  );

  if (isLoading) {
    return <Loader />;
  }

  const handleDownloadErrorCsv = async (history: ApiImportHistory, errorType: ErrorType) => {
    const importErrors = await ImportApi.getImportErrors(history.id, errorType);
    let csvString = '';
    if (importErrors.length) {
      csvString = stringify(
        importErrors,
        {
          header: true,
        },
      );
    }

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `import-${errorType}s.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getImportStatusChip = (history: ApiImportHistory) => {
    let color: Color = 'success';
    let label: string = history.importStatus;
    if (history.importStatus === ImportStatus.Failed) {
      color = 'error';
    } else if (history.importStatus === ImportStatus.Importing) {
      color = 'info';
    } else if (history.hasImportWarnings) {
      label = 'complete with warnings';
    }
    return (
      <Chip
        color={color}
        label={label}
        size="small"
        sx={{ padding: 0 }}
        testID={`${history.fileName}-${history.importStatus}-chip`}
      />
    );
  };

  return (
    <Container maxWidth="lg">
      <Stack direction="row" justifyContent="space-between" py="24px">
        <Button
          onClick={backToAllRetailers}
          startIcon={<KeyboardBackspaceIcon />}
          testID="back-to-all-retailers"
          variant="text"
        >
          Back to all retailers
        </Button>
        {AppConfig.env.test && (
          <Button
            color="error"
            onClick={() => setShowDeleteModal(true)}
            startIcon={<DeleteOutline />}
            testID="delete-retailer-button"
          >
            Delete retailer
          </Button>
        )}
      </Stack>
      <Stack spacing="2">
        <RetailerDetailsCard />
        <VSpacer size="6" />
        <Divider />
        <VSpacer size="6" />
        <DescriptionCard
          description={retailer?.description}
          onChange={(description) => (
            updateRetailer({ description: description || null })
          )}
        />
        <VSpacer size="6" />
        {!!retailer && (
          <>
            <BrandAssetInputs
              onChange={updateRetailer}
              retailer={retailer}
            />
            <VSpacer size="6" />
            <Divider />
            <VSpacer size="6" />
            <AddRecipientsSection
              retailerId={retailer.id}
            />
            <VSpacer size="6" />
          </>
        )}
        <Divider />
        <VSpacer size="6" />
        <MembersCard
          members={retailerMembers}
          onAddMember={onAddMember}
          onViewMember={onViewMember}
          search={search}
          setSearch={setSearch}
          showAddMember={showAddMember}
          showViewMember={showViewMember}
        />
        <VSpacer size="6" />
        <Divider />
        <VSpacer size="6" />
        <LocationCard
          isRetailerActive={retailer?.isActive ?? false}
          onAddLocation={onAddLocation}
          retailerId={id}
          show={showLocation}
        />
        <VSpacer size="6" />
        <Divider />
        <VSpacer size="6" />
        {!!retailer && (
          <>
            <RewardsProgramsCard
              disabled={!categories?.length}
              retailerId={retailer.id}
              rewardsProgram={retailer?.rewardsPrograms?.[0]}
            />
            <VSpacer size="6" />
            <Divider />
          </>
        )}
        <VSpacer size="6" />
        <LoyaltySkuTable retailerId={id} />
        <Stack>
          <VSpacer size="6" />
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Text category="body-xlarge">ERP Imports</Text>
            <Stack direction="row">
              <Button
                color="primary"
                disabled={ !hasActiveRewardsProgram
                  || !!(pendingImportResult?.total === 0)
                  || isImporting
                  || isProcessing
                }
                loading={isProcessing}
                onClick={async () => {
                  try {
                    await HierarchyOfRetailersApi.processRewards(id);
                    void queryClient.invalidateQueries(QueryKeys.GET_IMPORT_HISTORY_LIST);
                    void queryClient.invalidateQueries(QueryKeys.GET_IMPORT_PROCESSING_RESULT);
                    void queryClient.invalidateQueries(QueryKeys.GET_JOB_IS_PROCESSING);
                  } catch (e: any) {
                    if (
                      e.message === 'Cannot run until the points engine ' +
                      'or another promotion has finished processing'
                    ) {
                      openSnackbar(e.message);
                      void queryClient.invalidateQueries(QueryKeys.GET_JOB_IS_PROCESSING);
                    } else {
                      openSnackbar('Rewards processing failed');
                    }
                  }
                }}
                square
                startIcon={<Autorenew />}
                testID="points-engine-button"
              >
                Run points engine
              </Button>
              <HSpacer size="4" />
              <Button
                disabled={isProcessing || isImporting || isFetchingHistory}
                loading={isImporting}
                onClick={() => setShowImportModal(true)}
                startIcon={<UploadIcon />}
                testID="import-erp-button"
                variant="outlined"
              >
                Import ERP data
              </Button>
            </Stack>
          </Stack>
          <VSpacer size="6" />
          <Table
            headerWidths={[193, 120, undefined, 190]}
            headers={['Import date', 'Import type', 'File name', 'Import status']}
          >
            {importHistoryResult?.total ? (
              <>
                {importHistoryResult.data.map((history) => (
                  <TableRow
                    key={history.id}
                    values={[
                      <Stack alignItems="center" direction="row" key={`${history.id}-order-date`}>
                        {DateTime.fromJSDate(
                          history.createdAt, { zone: 'America/New_York' },
                        ).toFormat('MMM dd, yyyy')}
                        <HSpacer size="4" />
                        {history.type === 'orders'
                          && !history.pointsProcessedDate
                          && ImportStatus.Complete === history.importStatus
                          && (<Chip
                            color="warning"
                            label="Pending"
                            size="small"
                            sx={{ padding: 0 }}
                            testID={`${history.fileName}-pending-chip`}
                          />)
                        }
                      </Stack>,
                      history.type.charAt(0).toUpperCase() + history.type.slice(1),
                      history.fileName,
                      <Stack key={`${history.id}-import-status`}>
                        {getImportStatusChip(history)}
                        {history.importStatus === ImportStatus.Failed && (
                          <>
                            <VSpacer size="3" />
                            <TextLink
                              category="body-medium"
                              onClick={async () => {
                                await handleDownloadErrorCsv(history, ErrorType.Error);
                              }}
                              testID={`${history.id}-error-download-link`}
                            >
                              Download errors
                            </TextLink>
                          </>
                        )}
                        {history.hasImportWarnings && (
                          <>
                            <VSpacer size="3" />
                            <TextLink
                              category="body-medium"
                              onClick={async () => {
                                await handleDownloadErrorCsv(history, ErrorType.Warning);
                              }}
                              testID={`${history.id}-warning-download-link`}
                            >
                              Download warnings
                            </TextLink>
                          </>
                        )}
                      </Stack>,
                    ]}
                  />
                ))}
              </>
            ) : (
              <MuiTableRow>
                <TableCell align="center" colSpan={3}>No ERP files have been uploaded</TableCell>
              </MuiTableRow>
            )}
            </Table>
            {!!importHistoryResult && importHistoryResult.lastPage > 0 && (
              <>
                <VSpacer size="6" />
                <Pagination
                  count={importHistoryResult.lastPage + 1}
                  onChange={(_, page) => {
                    setHistoryPage(page - 1);
                  }}
                  page={importHistoryResult.page + 1}
                  sx={{ alignSelf: 'center' }}
                />
              </>
            )}
          <VSpacer size="6" />
        </Stack>
      </Stack>
      {showImportModal && (
        <ImportErpModal
          onClose={() => setShowImportModal(false)}
          open
          retailerId={id}
        />
      )}
      {showDeleteModal && (
        <ConfirmModal
          confirmButtonText="Yes, delete retailer"
          isLoading={isDeleting}
          message="Are you sure you want to delete the retailer?"
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={async () => {
            setIsDeleting(true);
            try {
              await HierarchyOfRetailersApi.deleteRetailer(id);
              await queryClient.invalidateQueries([QueryKeys.GET_RETAILERS]);
              openSnackbar('Retailer deleted successfully.');
              backToAllRetailers();
            } catch (error) {
              openSnackbar('Retailer delete unsuccessful');
            }
            setIsDeleting(false);
          }}
          open={showDeleteModal}
          testID="confirm-delete-retailer-modal"
          title="Delete retailer"
        />
      )}
    </Container>
  );
};

export default RetailerDetailsOverviewPage;
