import { Button as Button, Text } from '@/components/DesignSystem';
import { ConfirmDialog } from '@/components/shared/ConfirmDialog';
import { ToastMessages } from '@/constants/constant';
import { QueryKeys } from '@/constants/QueryKeys';
import { ImageModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/ImageModal';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { getOriginalFileName } from '@shared/utilities';

interface UploadImageProps {
  image: string | null,
  manufacturerId: string,
  type: "Logo" | "Logomark",
}

const UploadImage = ({
  image,
  manufacturerId,
  type,
}: UploadImageProps) => {
  const [showConfirmRemoveImageModal, setShowConfirmRemoveImageModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: uploadImage, isLoading } = useMutation(
    (image: string | null) => {
      const update = type === "Logo" ? { logo: image } : { logomark: image };
      return ManufacturerApi.update(manufacturerId, update);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURER_BY_ID);
        openSnackbar(ToastMessages.adminRetailerUploadLogoSuccess);
      },
      onError: () => {
        openSnackbar(ToastMessages.adminRetailerUploadLogoFailure);
      },
    },
  );

  const { mutate: deleteImage, isLoading: isRemoveImageLoading } = useMutation(
    () => {
      const update = type === "Logo" ? { logo: null } : { logomark: null };
      return ManufacturerApi.update(manufacturerId, update);
    },
    {
      onSuccess: async () => {
        openSnackbar(`${type} removed successfully`);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURER_BY_ID);
        setShowConfirmRemoveImageModal(false);
      },
    },
  );

  return (
    <>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={3}
        sx={{ padding: '20px' }}
      >
        <Stack spacing={1}>
          <Text category='h6'>{type}</Text>
        </Stack>
        <Box>
          {!image ? (
            <Button
              loading={isLoading}
              onClick={() => setShowImageModal(true)}
              startIcon={<UploadIcon />}
              testID='upload-logo-button'
              variant='outlined'
            >
              Upload {type}
            </Button>
          ) : (
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
            >
              <Typography sx={{ marginRight: '28px' }}>
                {image ? getOriginalFileName(image) : ''}
              </Typography>
              <Button
                onClick={() => {
                  setShowConfirmRemoveImageModal(true);
                }}
                startIcon={<DeleteOutlineIcon />}
                testID='delete-logo'
                variant='text'
              >
                Remove
              </Button>
            </Stack>
          )}
          {showConfirmRemoveImageModal && (
            <ConfirmDialog
              cancelText="No"
              confirmText="Yes"
              isLoading={isRemoveImageLoading}
              onCancel={() => setShowConfirmRemoveImageModal(false)}
              onConfirm={() => {
                deleteImage();
                setShowConfirmRemoveImageModal(false);
              }}
              open={showConfirmRemoveImageModal}
              testID="remove-logo-confirm-modal"
              title={`Delete ${type}`}
            >
              Are you sure you want to delete the logo?
            </ConfirmDialog>
          )}
        </Box>
      </Stack>
      {showImageModal && (
        <ImageModal
          imageFilename={image}
          onClose={() => setShowImageModal(false)}
          onSave={(image) => uploadImage(image)}
          title={`Upload ${type}`}
        />
      )}
    </>
  );
};

export default UploadImage;
