import { useRetailerMetadata } from '@/hooks/useHierarchyOfRetailers';
import { GlobalSnackbarProvider } from '@/providers/GlobalSnackbarProvider';
import { components } from '@/themes/MUITheme/components';
import { themes } from '@/themes/MUITheme/MaterialUITheme';
import { LightPalette } from '@/themes/MUITheme/palette';
import { defaultTypography } from '@/themes/MUITheme/typography';
import { OtherEventType, useLogEvent } from '@/utilities/Analytics';
import { LoginView } from '@/views/LoginView';
import { alpha, CircularProgress, Container, CssBaseline, Stack } from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { SharedConfig } from '@shared/SharedConfig';
import { isFarmerUser, isRetailerUser } from '@shared/utilities';
import React, { ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter, matchPath, PathMatch, useLocation, useNavigate } from 'react-router-dom';
import { VSpacer } from './components/DesignSystem';
import { AppConfig } from './constants/AppConfig';
import { DashboardRoutes, PublicRoutes, Routes } from './constants/Routes';
import {
  AuthenticationProvider,
  SessionState,
  useAuthentication,
} from './contexts/dataSync/AuthenticationContext';
import { DashboardView } from './views/DashboardView';
import { InternalView } from './views/InternalView';
import { PublicView } from './views/PublicView';
import { RetailerView } from './views/RetailerView';

const MainView = () => {
  const location = useLocation();

  const { isInternal, sessionState, user } = useAuthentication();
  const logEvent = useLogEvent();
  const navigate = useNavigate();

  useEffect(() => {
    const routesToNotRedirect = [
      Routes.MY_PROFILE,
      ...DashboardRoutes,
    ] as string[];
    const isRedirectRoute = !routesToNotRedirect.includes(location.pathname);
    if (isRedirectRoute && user) {
      if (isRetailerUser(user)
        && (!user.otpVerified || !user.email || !user.businessName)
      ) {
        navigate(Routes.MY_PROFILE);
        return;
      } else if (isFarmerUser(user)
        && (!user.otpVerified || !user.firstName || !user.lastName || !user.state || !user.countyId)
      ) {
        navigate(Routes.MY_PROFILE);
        return;
      }
    }
    logEvent(OtherEventType.PageView, { page_location: location.pathname });
  }, [location, user]);

  useEffect(() => (
    window.scrollTo(0, 0)
  ), [location.pathname]);

  for (const route of DashboardRoutes) {
    const match = matchPath(
      route,
      location.pathname,
    );
    if (match) {
      return (<DashboardView />);
    }
  }

  for (const route of PublicRoutes) {
    const match = matchPath(
      route,
      location.pathname,
    );
    if (match) {
      return (<PublicView />);
    }
  }

  if (sessionState === SessionState.LOGGED_OUT) {
    return <LoginView />;
  } else if (SessionState.LOGGED_IN && user) {
    if (isInternal) {
      return <InternalView />;
    }
    return <RetailerView />;
  } else {
    return (
      <Container>
        <Stack alignItems="center">
          <VSpacer size="14" />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const GrowersHelmet = () => {
  return <Helmet>
    <title>GROWERS</title>
    <meta content="GROWERS" name="description"/>
    <link
      href="apple-icon-57x57.png"
      rel="apple-touch-icon"
      sizes="57x57"
    />
    <link
      href="apple-icon-60x60.png"
      rel="apple-touch-icon"
      sizes="60x60"
    />
    <link
      href="apple-icon-72x72.png"
      rel="apple-touch-icon"
      sizes="72x72"
    />
    <link
      href="apple-icon-76x76.png"
      rel="apple-touch-icon"
      sizes="76x76"
    />
    <link
      href="apple-icon-114x114.png"
      rel="apple-touch-icon"
      sizes="114x114"
    />
    <link
      href="apple-icon-120x120.png"
      rel="apple-touch-icon"
      sizes="120x120"
    />
    <link
      href="apple-icon-144x144.png"
      rel="apple-touch-icon"
      sizes="144x144"
    />
    <link
      href="apple-icon-152x152.png"
      rel="apple-touch-icon"
      sizes="152x152"
    />
    <link
      href="apple-icon-180x180.png"
      rel="apple-touch-icon"
      sizes="180x180"
    />
    <link
      href="android-icon-192x192.png"
      rel="icon"
      sizes="192x192"
      type="image/png"
    />
    <link
      href="growers-favicon.ico"
      rel="icon"
      sizes="32x32"
      type="image/png"
    />
    <link
      href="growers-favicon-96x96.png"
      rel="icon"
      sizes="96x96"
      type="image/x-icon"
    />
    <link
      href="growers-favicon.ico"
      rel="icon"
      sizes="16x16"
      type="image/x-icon"
    />
  </Helmet>;
};

const LoyaltyProgramHelmet = () => {
  const subdomain = window.location.hostname.split('.')[0];

  const { metadata } = useRetailerMetadata();
  const location = useLocation();

  const isCustomerLoyaltyDashboard = DashboardRoutes.reduce<PathMatch<'*' | 'accessToken'> | null>(
    (match, pattern) => (match ? match : matchPath(pattern, location.pathname)),
    null,
  );

  if (!metadata || !metadata.appIcon || !metadata.appName || !isCustomerLoyaltyDashboard) {
    return (
      <Helmet>
        {!!metadata?.appName && (
          <>
            <title>{metadata.appName}</title>
            <meta content={metadata.appName} name="description" />
          </>
        )}
        <link
          href={`${AppConfig.staticImageHost}/icons/${subdomain}/favicon.png`}
          rel="icon"
          type="image/x-icon"
        />
      </Helmet>
    );
  }

  const { appIcon, appName } = metadata;
  const manifest = {
    name: appName,
    icons: [
      {
        sizes: 'any',
        src: `${AppConfig.staticImageHost}/icons/${subdomain}/192x192-${appIcon}`,
        type: 'image/png',
        purpose: 'any maskable',
      },
    ],
  };
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestUrl = URL.createObjectURL(blob);

  return <Helmet>
    <title>{appName}</title>
    <meta content={appName} name="description" />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/${appIcon}`}
      rel="icon"
      type="image/png"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/32x32-${appIcon}`}
      rel="icon"
      sizes="32x32"
      type="image/png"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/180x180-${appIcon}`}
      rel="apple-touch-icon"
      sizes="180x180"
      type="image/png"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/192x192-${appIcon}`}
      rel="icon"
      sizes="192x192"
      type="image/png"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/512x512-${appIcon}`}
      rel="icon"
      sizes="512x512"
      type="image/png"
    />
    <link
      href={manifestUrl}
      rel="manifest"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/favicon.png`}
      rel="icon"
      type="image/x-icon"
    />
  </Helmet>;
};

const AppHelmet = () => {
  const isLoyaltyProgramDomain = window.location.hostname.includes(SharedConfig.shortUrlDomain);
  return isLoyaltyProgramDomain ? <LoyaltyProgramHelmet /> : <GrowersHelmet />;
};

const DynamicThemeProvider = ({ children } :{
  children: ReactNode,
}) => {
  const defaultTheme = themes['marketplaceLight'];
  const [themeState, setThemeState] = useState({
    theme: defaultTheme,
    isLoading: true,
  });
  const { retailer } = useAuthentication();
  const location = useLocation();

  useEffect(() => {
    const { brandColor } = retailer || {};
    if (brandColor) {
      if (themeState.theme.palette.primary.main !== brandColor) {
        const baseRetailerTheme = createTheme({
          palette: {
            ...LightPalette,
            primary: { main: brandColor },
            primaryContainer: alpha(brandColor, .18),
          },
        });

        const newTheme = createTheme({
          components: components(baseRetailerTheme),
          palette: baseRetailerTheme.palette,
          typography: defaultTypography,
        });

        setThemeState({
          theme: newTheme,
          isLoading: false,
        });
      }
    } else {
      setThemeState({
        theme: defaultTheme,
        isLoading: false,
      });
    }
  }, [location.pathname, retailer, themeState.theme.palette.primary.main]);

  return (
    <MuiThemeProvider theme={themeState.theme}>
      {themeState.isLoading ? (
        <CircularProgress />
      ) : (
        children
      )}
    </MuiThemeProvider>
  );
};

const App = () => {
  return (
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <DynamicThemeProvider>
            <CssBaseline />
            <GlobalSnackbarProvider>
              <AppHelmet />
              <MainView />
            </GlobalSnackbarProvider>
          </DynamicThemeProvider>
        </AuthenticationProvider>
      </QueryClientProvider>
    </HashRouter>
  );
};

export default App;
