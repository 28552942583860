import React, { useEffect, useState } from "react";
import { Button, FullscreenDialog, HSpacer, Text, VSpacer } from "@/components/DesignSystem";
import { SponsorType } from "@shared/enums";
import { PromotionTargetUserType } from "@shared/enums/PromotionTargetUserType";
import { Stack } from "@mui/material";
import { ProgressBars } from "@/pages/Promotions/PromotionModal/ProgressBars";
import { WorkflowStep1 } from "@/pages/Promotions/PromotionModal/WorkflowStep1";
import { WorkflowStep2 } from "@/pages/Promotions/PromotionModal/WorkflowStep2";
import { WorkflowStep3 } from "@/pages/Promotions/PromotionModal/WorkflowStep3";
import { WorkflowStep4 } from "@/pages/Promotions/PromotionModal/WorkflowStep4";
import { ArrowForward } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGetRetailerById } from "@/hooks/useHierarchyOfRetailers";
import { useGetRetailerLocations } from "@/hooks/useRetailerLocations";
import { SharedConfig } from "@shared/SharedConfig";
import { RewardsUom } from "@shared/enums/RewardsUom";
import { RewardsType } from "@shared/enums/RewardsType";
import { WorkflowStepBase } from "@/pages/Promotions/PromotionModal/WorkflowStepBase";
import { useQuery } from "react-query";
import { QueryKeys } from "@/constants/QueryKeys";
import { RewardsProgramApi } from "@/utilities/api/RewardsProgramApi";

export type PromotionInputs = {
  description: string,
  dollarCap: number | null,
  endDate?: Date,
  id?: string,
  locationIds?: string[],
  manufacturerId?: string,
  name?: string,
  overridePointsEngine: boolean,
  pointCap: number | null,
  productIdsForEarningPoints?: string[],
  productIdsForRedeemingPoints?: string[],
  redeemOnAllProducts?: boolean,
  retailerId?: string,
  rewardType: RewardsType,
  showInDashboardCarousel: boolean,
  showInDashboardTable: boolean,
  sponsorType: SponsorType,
  startDate?: Date,
  targetUserType: PromotionTargetUserType,
  uom?: RewardsUom,
  value?: number,
}

interface PromotionModalProps {
  onClose: () => void,
  onSave: (promotion: PromotionInputs) => Promise<void>,
  show: boolean,
}

const initialPromotionState: PromotionInputs = {
  description: '',
  dollarCap: null,
  manufacturerId: '',
  overridePointsEngine: false,
  pointCap: null,
  rewardType: RewardsType.Dollars,
  showInDashboardCarousel: false,
  showInDashboardTable: false,
  sponsorType: SponsorType.Retailer,
  targetUserType: PromotionTargetUserType.Farmer,
  uom: RewardsUom.Dollars,
};

export const PromotionModal = ({
  onClose,
  onSave,
  show,
}: PromotionModalProps) => {
  const [currentWorkflowStep, setCurrentWorkflowStep] = useState(0);
  const [progress, setProgress] = useState([0, 0, 0, 0]);
  const [promotion, setPromotion] = useState<PromotionInputs>(initialPromotionState);
  const [isSaving, setIsSaving] = useState(false);

  const workflowStepRequiredFields : Record<number, (keyof PromotionInputs)[]> = {
    0: [
      'endDate',
      'locationIds',
      'manufacturerId',
      'name',
      'redeemOnAllProducts',
      'retailerId',
      'sponsorType',
      'startDate',
    ],
    1: ['uom', 'value'],
    2: ['productIdsForEarningPoints'],
    3: ['productIdsForRedeemingPoints'],
    4: [],
  };

  const totalWorkflowSteps = Object.keys(workflowStepRequiredFields).length;

  const { retailer: selectedRetailer } = useGetRetailerById(promotion.retailerId || '');

  const { data: rewardsProgram } = useQuery(
    [QueryKeys.GET_ACTIVE_PROGRAM_FOR_RETAILER, selectedRetailer?.id],
    () => RewardsProgramApi.getActiveProgramForRetailer(selectedRetailer?.id ?? ''),
    { enabled: !!selectedRetailer?.id },
  );

  const { retailerLocations: retailerLocationsResponse } =
    useGetRetailerLocations(promotion.retailerId ?? '', {
      limit: SharedConfig.maxPageLimit,
    });

  const isStepComplete = (currentStep: number) => {
    const requiredFields = workflowStepRequiredFields[currentStep];
    for (const input of requiredFields) {
      const isValid = promotion[input] !== undefined;
      if (!isValid) {
        return false;
      }
    }
    return true;
  };

  const areAllStepsComplete = () => {
    for (let i = 0; i < totalWorkflowSteps; i++) {
      if (!isStepComplete(i)) {
        return false;
      }
    }
    return true;
  };

  const updateProgress = () => {
    const updatedStepProgress = isStepComplete(currentWorkflowStep) ? 100 : 10;
    let updatedProgress = progress;
    if (currentWorkflowStep === 0) {
      updatedProgress = [updatedStepProgress, 0, 0, 0];
    } else if (currentWorkflowStep === 1) {
      updatedProgress = [100, updatedStepProgress, 0, 0];
    } else if (currentWorkflowStep === 2) {
      updatedProgress = [100, 100, isStepComplete(currentWorkflowStep) ? 50 : 10, 0];
    } else if (currentWorkflowStep === 3) {
      updatedProgress = [100, 100, isStepComplete(currentWorkflowStep) ? 100 : 50, 0];
    } else if (currentWorkflowStep === 4) {
      updatedProgress = [100, 100, 100, 90];
    }
    setProgress(updatedProgress);
  };

  const onPromotionChanged = (promotion: PromotionInputs) => {
    setPromotion(promotion);
  };

  const navigateToStep = (step: number) => {
    setCurrentWorkflowStep(step);
  };

  const onSaveButtonClicked = async () => {
    setIsSaving(true);
    await onSave(promotion);
    setIsSaving(false);
  };

  useEffect(() => {
    if (retailerLocationsResponse) {
      const selectedLocationIDs = new Set<string>();
      retailerLocationsResponse.data.forEach(location => selectedLocationIDs.add(location.id));
      const updatedPromotion = { ...promotion, locationIds: [...selectedLocationIDs] };
      onPromotionChanged(updatedPromotion);
    }
  }, [retailerLocationsResponse]);

  useEffect(() => {
    updateProgress();
  }, [currentWorkflowStep, promotion]);

  return (
    <FullscreenDialog
      dialogActions={
        <Stack
          alignItems="center"
          direction="row"
          height={"80px"}
          justifyContent="space-between"
          sx={{ margin: "0 auto" }}
          width={"800px"}
        >
          <Button
            onClick={onClose}
            testID="create-promotion-modal-button-cancel-id"
            variant={"outlined"}
          >
            Cancel
          </Button>
          <Stack direction="row" justifyContent="space-between">
            {currentWorkflowStep > 0 && (
              <Stack direction="row">
                <Button
                  onClick={() => {
                    if (currentWorkflowStep === 4 && promotion.redeemOnAllProducts) {
                      navigateToStep(2);
                    } else {
                      navigateToStep(currentWorkflowStep - 1);
                    }
                  }}
                  startIcon={<ArrowBackIcon />}
                  testID="create-promotion-modal-button-back-id"
                  variant={'outlined'}
                >
                  Back
                </Button>
                <HSpacer size="5" />
              </Stack>
            )}
            {currentWorkflowStep < totalWorkflowSteps - 1 && (
              <Button
                disabled={!isStepComplete(currentWorkflowStep)}
                endIcon={<ArrowForward />}
                onClick={() => {
                  if (currentWorkflowStep === 2 && promotion.redeemOnAllProducts) {
                    onPromotionChanged({
                      ...promotion,
                      productIdsForRedeemingPoints: [],
                    });
                    navigateToStep(4);
                  } else {
                    navigateToStep(currentWorkflowStep + 1);
                  }
                }}
                testID="create-promotion-modal-button-next-id"
              >
                Next
              </Button>
            )}
            {currentWorkflowStep === totalWorkflowSteps - 1 && (
              <Button
                disabled={!areAllStepsComplete() || isSaving}
                onClick={async () => {
                  await onSaveButtonClicked();
                }}
                testID="create-promotion-modal-button-save-id"
              >
                Create Promotion
              </Button>
            )}
          </Stack>
        </Stack>
      }
      hideCloseButton={true}
      onClose={onClose}
      open={show}
      testID="create-promotion-modal-id"
      titleComponent={
        <Stack sx={{ width: '800px' }}>
          <VSpacer size="4" />
          <Text category="overline" sx={{ margin: '0 auto' }}>create promotion</Text>
          <VSpacer size="5" />
          <ProgressBars progress={progress}/>
          <VSpacer size="4" />
        </Stack>
      }
      titleStyle={{ margin: "0 auto" }}
    >
      <Stack sx={{ margin: '0 auto', maxWidth: '800px' }}>
        <VSpacer size="6" />
        {currentWorkflowStep === 0 && (
          <WorkflowStepBase title="Let's get set up">
            <WorkflowStep1
              activeRewardsProgram={rewardsProgram}
              onPromotionChanged={onPromotionChanged}
              promotion={promotion}
              retailerLocations={retailerLocationsResponse?.data}
              selectedRetailer={selectedRetailer}
            />
          </WorkflowStepBase>
        )}
        {currentWorkflowStep === 1 && (
          <WorkflowStepBase title="Add loyalty value">
            <WorkflowStep2
              onPromotionChanged={onPromotionChanged}
              promotion={promotion}
            />
          </WorkflowStepBase>
        )}
        {currentWorkflowStep === 2 && (
          <WorkflowStepBase
            title="Add products that EARN points"
            width="840px"
          >
            <WorkflowStep3
              onPromotionChanged={onPromotionChanged}
              productType="earn"
              promotion={promotion}
              selectedRetailer={selectedRetailer}
            />
          </WorkflowStepBase>
        )}
        {currentWorkflowStep === 3 && (
          <WorkflowStepBase
            title="Add products that REDEEM points"
            width="840px"
          >
            <WorkflowStep3
              onPromotionChanged={onPromotionChanged}
              productType="redeem"
              promotion={promotion}
              selectedRetailer={selectedRetailer}
            />
          </WorkflowStepBase>
        )}
        {currentWorkflowStep === 4 && (
          <WorkflowStepBase title="Everything look good?">
            <WorkflowStep4
              activeRewardsProgram={rewardsProgram}
              navigateToStep={navigateToStep}
              onPromotionChanged={onPromotionChanged}
              promotion={promotion}
              retailerLocations={retailerLocationsResponse?.data}
              selectedRetailer={selectedRetailer}
            />
          </WorkflowStepBase>
        )}
      </Stack>
    </FullscreenDialog>
  );
};
