import defaultLocationImage from '@/assets/images/default-location-image.png';
import { Button, Dialog, HSpacer, Switch, Text, VSpacer } from '@/components/DesignSystem';
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';
import { ConfirmDialog } from '@/components/shared/ConfirmDialog';
import { AppConfig } from '@/constants/AppConfig';
import { ConfirmationDialog } from '@/constants/constant';
import { stringifyCount, TOGGLE_LOCATION_POP_UP } from '@/constants/HierarchyOfRetailers';
import { Routes } from '@/constants/Routes';
import { useEditRetailerLocation } from '@/hooks/useRetailerLocations';
import { ApiRetailerLocation } from '@api/interfaces';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Card, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const LocationOverviewCard = ({
  isRetailerActive,
  location,
}: {
  isRetailerActive: boolean;
  location: ApiRetailerLocation;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const [changeIsActive, setChangeIsActive] = useState(false);

  const { updateRetailerLocation, isLoading } = useEditRetailerLocation(
    location.id ?? '',
    setChangeIsActive,
  );

  const detailsNavigator = () => {
    navigate(
      `${Routes.ADMIN_HIERARCHY_OF_RETAILERS}/${id}/location/${location.id}`,
    );
  };

  const logoSrc = location.image && `${AppConfig.staticImageHost}/${location.image}`;

  return (
    <Card
      onClick={detailsNavigator}
      sx={{
        cursor: 'pointer',
        padding: '20px',
      }}
    >
      <Stack
        alignItems={'center'}
        direction='row'
        justifyContent={'space-between'}
      >
        <Stack direction='row' spacing={2}>
          <Avatar
            alt={`${location.name}-logo`}
            height={56}
            src={logoSrc ?? defaultLocationImage}
            width={56}
          />
          <Stack ml={'24px !important'} spacing={1}>
            <Text category="overline" sx={{ color: theme.palette.text.secondary }}>
              ID: {location.id}
            </Text>
            <Text category="body-large">{location.name}</Text>
            <Text category="body-medium">
              {stringifyCount(location.salespersons?.length, 'member', 'members')}
            </Text>
          </Stack>
        </Stack>
        <Stack>
          <Switch
            checked={location.isActive}
            label={location.isActive ? 'Active' : 'Inactive'}
            labelPlacement='start'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setChangeIsActive(true);
            }}
            sx={{ marginRight: '0px' }}
            testID='active-toggle'
          />
          {location.isPreferred &&
            <>
              <VSpacer size="4" />
              <Stack direction="row">
                <VerifiedIcon color="success" />
                <HSpacer size="3" />
                <Text color="success">Preferred Retailer</Text>
              </Stack>
            </>
          }
        </Stack>
      </Stack>
      {changeIsActive && isRetailerActive && (
        <ConfirmDialog
          isLoading={isLoading}
          onCancel={() => setChangeIsActive(false)}
          onConfirm={() => {
            updateRetailerLocation({ isActive: !location.isActive });
          }}
          open={changeIsActive}
          testID="change-active-confirm-dialog"
          title={
            location.isActive
              ? ConfirmationDialog.inActivateLocationTitle
              : ConfirmationDialog.activateLocationTitle
          }
        >
          {location.isActive
            ? ConfirmationDialog.inActivateLocationMessage
            : ConfirmationDialog.activateLocationMessage}
        </ConfirmDialog>
      )}
      {changeIsActive && !isRetailerActive && (
        <Dialog
          acceptButton={(props) => (
            <Button
              {...props}
              onClick={() => setChangeIsActive(false)}
              variant="contained"
            >
              Close
            </Button>
          )}
          onClose={() => setChangeIsActive(false)}
          open={changeIsActive}
          showCloseButton={false}
          testID="active-warning-confirm-dialog"
        >
          {TOGGLE_LOCATION_POP_UP}
        </Dialog>
      )}
    </Card>
  );
};

export default LocationOverviewCard;
