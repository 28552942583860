import { InlineMessage } from '@/components/DesignSystem';
import CheckCircle from '@mui/icons-material/CheckCircle';

interface AcceptedMessageProps {
  testID: string,
  title?: string,
}

export const AcceptedMessage = ({
  testID,
  title,
}: AcceptedMessageProps) => {
  return (
    <InlineMessage
      icon={<CheckCircle color="success" fontSize="small" />}
      testID={`product-card-is-accepted-${testID}`}
      title={title ?? 'Accepted'}
      titleSpacing="3"
    />
  );
};
