import { Chip, HSpacer, Text } from '@/components/DesignSystem';
import { Filter, FilterSelection } from '@/components/DesignSystem/Toolbar/interfaces';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Stack, useTheme } from '@mui/material';
import React from 'react';
import { Check, Close } from "@mui/icons-material";

const styles: SXStyles = {
  dropdownIcon: {
    height: '18px',
    marginRight: '-4px',
    width: '18px',
  },
} as const;

interface FilterChipProps {
  disabled?: boolean,
  filter: Filter,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  selection?: FilterSelection,
  testID: string,
}

export const FilterChip = ({
  disabled = false,
  filter,
  onClick,
  selection,
  testID,
}: FilterChipProps) => {
  const theme = useTheme();
  const isBoolean = filter.selectionMethod === 'boolean';
  const showCount = ['chips', 'multi-select'].includes(filter.selectionMethod);
  const selectionCount = selection?.size ?? 0;
  const hasSubOptions = filter.options.some((option) => !!option.subOptions?.length);
  const labelText = selectionCount && showCount && !hasSubOptions
    ? `${selectionCount} ${filter.label}`
    : filter.label;
  const showSelectedState = !!selection?.size;
  return (
    <Chip
      disabled={disabled}
      key={filter.id}
      label={
        <Stack alignItems="center" direction="row">
          {(showSelectedState && !isBoolean) && (
            <>
              <Check sx={{ width: "18px" }} />
              <HSpacer size="3" />
            </>
          )}
          <Text category="label-large" testID={`${testID}-label`}>
            {labelText}
          </Text>
          {(showSelectedState && isBoolean) && (
            <>
              <HSpacer size="3" />
              <Close sx={{ width: "18px" }} />
            </>
          )}
          {!isBoolean && (
            <>
              <HSpacer size="3"/>
              <ArrowDropDown sx={styles.dropdownIcon} />
            </>
          )}
        </Stack>
      }
      {...(disabled ? {} : { onClick })}
      size="medium"
      sx={{
        "&:hover": {
          backgroundColor: showSelectedState ? theme.palette.primaryContainer : undefined,
        },
        borderColor: "#bdbdbd",
        backgroundColor: showSelectedState ? theme.palette.primaryContainer : undefined,
      }}
      testID={testID}
      variant={showSelectedState ? 'filled' : 'outlined'}
    />
  );
};
