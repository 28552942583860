import { Button, FullscreenDialog, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { Table, TableRow } from '@/components/DesignSystem/Table/Table';
import { QueryKeys } from '@/constants/QueryKeys';
import {
  formatHurdleText,
  getCurrentRank,
  getCurrentTier,
  getNextTier,
} from '@/pages/FarmerList/helper';
import { RewardsProgramStatusCards } from '@/pages/FarmerList/RewardsProgramStatusCards';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Stack } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';

interface RewardsProgramProgressModalProps {
  customerId: string,
  farmerRewards: ApiFarmerRewards | null,
  isRewardsFetching: boolean,
  onClose: () => void,
  open: boolean,
  rewardsProgram: ApiRewardsProgram,
}

export const RewardsProgramProgressModal = ({
  customerId,
  farmerRewards,
  isRewardsFetching,
  onClose,
  open,
  rewardsProgram,
}: RewardsProgramProgressModalProps) => {

  const { retailerId } = rewardsProgram;

  const { data: categories } = useQuery(
    [QueryKeys.GET_RETAILER_CATEGORIES, retailerId],
    async () => HierarchyOfRetailersApi.getRetailerCategories(retailerId),
  );

  const getCategoryName = (categoryId: string) => (
    categories?.find(({ id }) => id === categoryId)?.name
  );

  const currentRank = getCurrentRank(farmerRewards, rewardsProgram);
  const tiers = rewardsProgram.tiers ?? [];
  const nextTier = getNextTier(currentRank, tiers);

  const getTierToDisplay = () => {
    if (nextTier) {
      return nextTier;
    }
    if (currentRank === null) {
      return getCurrentTier(0, tiers);
    }
    return getCurrentTier(currentRank, tiers);
  };

  const tier = getTierToDisplay();
  const hasSomeSubcategories = !!tier?.segments?.some(
    (segment) => !!segment.subcategoryRewards,
  );
  const TierTable = (
    !!categories && (
      <Table
        headers={['Category', 'Minimum hurdle', 'Current total', 'Amount remaining', '']}
      >
        {tier?.segments?.map(({
          categoryId,
          id,
          minimumHurdle,
          subcategoryRewards,
          uom,
        }) => {
          const hurdleText = formatHurdleText(minimumHurdle, uom);
          const rewardSegment = farmerRewards?.totalPerSegment[categoryId];
          const currentTotal = rewardSegment?.total ?? 0;
          let amountRemaining = 0;
          if (currentTotal < minimumHurdle) {
            amountRemaining = minimumHurdle - currentTotal;
          }
          const isComplete = amountRemaining === 0;
          return (
            <TableRow
              expandedComponent={!subcategoryRewards ? undefined : (
                Object.entries(subcategoryRewards).map(([subcategoryId]) => {
                  const subcategoryTotal = rewardSegment?.subcategories?.[subcategoryId].total ?? 0;
                  let subcategoryAmountRemaining = 0;
                  if (currentTotal < minimumHurdle) {
                    subcategoryAmountRemaining = minimumHurdle - subcategoryTotal;
                  }
                  return (
                    <TableRow
                      key={subcategoryId}
                      values={[
                        '',
                        getCategoryName(subcategoryId),
                        '',
                        formatHurdleText(subcategoryTotal, uom),
                        formatHurdleText(subcategoryAmountRemaining, uom),
                        '',
                      ]}
                    />
                  );
                })
              )}
              key={id}
              values={[
                ...(subcategoryRewards ? [] : hasSomeSubcategories ? [''] : ''),
                getCategoryName(categoryId),
                hurdleText,
                formatHurdleText(currentTotal, uom),
                formatHurdleText(amountRemaining, uom),
                isComplete && (
                  <Stack alignItems="center" direction="row">
                    <CheckCircle color="success" />
                    <HSpacer size="2" />
                    <Text category="label-small" color="success">COMPLETE</Text>
                  </Stack>
                ),
              ]}
            />
          );
        })}
      </Table>
    )
  );

  return (
    <FullscreenDialog
      actionButton={(
        <Button
          onClick={onClose}
          testID="rewards-program-progress-dialog-close-button"
        >
          Close
        </Button>
      )}
      hideCloseButton
      onClose={onClose}
      open={open}
      testID="rewards-program-progress-dialog"
      title="Rewards program progress"
    >
      <VSpacer size="7" />
      <RewardsProgramStatusCards
        customerId={customerId}
        farmerRewards={farmerRewards}
        hideTotalPoints
        isRewardsFetching={isRewardsFetching}
        rewardsProgram={rewardsProgram}
      />
      <VSpacer size="5" />
      {TierTable}
    </FullscreenDialog>
  );
};
