import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { ProductUom, State, StateAbbreviations } from '@shared/enums';
import { compactJoin, formatCurrency, localizeNumber, roundToFixed } from '@shared/utilities';

export class StringUtility {
  static isNaN = (num: number) => {
    return Number.isNaN(num) || num === undefined || num === null;
  };

  static formatDecimal = (num: number, length = 3): string => {
    if (isNaN(num)) {
      return '';
    }

    if (!(num % 1)) { // If it's a whole number
      return num.toString();
    }

    let str = roundToFixed(num, length);
    while (str[str.length - 1] === '0' && length > 0) {
      str = str.slice(0, str.length - 1);
    }

    return str.endsWith('.') ? str.slice(0, str.length - 1) : str;
  };

  static formatCurrencyAccounting = (num: number, symbol = '$') => {
    if (num < 0) {
      return `(${formatCurrency(num * -1, symbol)})`;
    }
    return formatCurrency(num, symbol);
  };

  static formatFlatCurrencyAccounting = (num: number, symbol = '$') => {
    if (num < 0) {
      return `(${formatCurrency(num * -1, symbol, 0)})`;
    }
    return formatCurrency(num, symbol, 0);
  };

  static formatFlatDiscount = (num: number, symbol = '−$') => (
    `(${formatCurrency(num, symbol, 0)})`
  );
  static formatDiscount = (num: number, symbol = '−$') => (
    `(${formatCurrency(num, symbol)})`
  );

  static formatFlatCurrency = (num: number, symbol = '$') => (
    `${symbol}${localizeNumber(num, 0, true) || '0'}`
  );

  static formatCurrencyShort = (num: number, symbol = '$') => {
    if (num < 1000000) {
      return formatCurrency(num, symbol);
    }

    return StringUtility.formatFlatCurrencyShort(num);
  };

  static formatFlatCurrencyShort = (num: number, symbol = '$') => {
    let suffix = '';
    let adjusted = Math.round(num);

    if (adjusted >= 10 ** 12) {
      adjusted = Math.round(num / 10 ** 9) / 1000;
      suffix = 'T';
    } else if (adjusted >= 10 ** 9) {
      adjusted = Math.round(num / 10 ** 6) / 1000;
      suffix = 'B';
      if (adjusted === 1000) {
        adjusted = 1;
        suffix = 'T';
      }
    } else if (adjusted >= 10 ** 6) {
      adjusted = Math.round(num / 1000) / 1000;
      suffix = 'M';
      if (adjusted === 1000) {
        adjusted = 1;
        suffix = 'B';
      }
    }

    const localized = localizeNumber(adjusted, suffix ? 3 : 0);

    return `${symbol}${localized}${suffix}`;
  };

  static formatFlatCurrencyShortBasic = (
    num: number,
    symbol = '$',
  ) => {
    let suffix = '';
    let adjusted = Math.round(num);

    if (adjusted >= 10 ** 12) {
      adjusted = (num / 10 ** 9) / 1000;
      suffix = 'T';
    } else if (adjusted >= 10 ** 9) {
      adjusted = (num / 10 ** 6) / 1000;
      suffix = 'B';
      if (Math.round(adjusted) === 1000) {
        adjusted = 1;
        suffix = 'T';
      }
    } else if (adjusted >= 10 ** 6) {
      adjusted = (num / 1000) / 1000;
      suffix = 'M';
      if (Math.round(adjusted) === 1000) {
        adjusted = 1;
        suffix = 'B';
      }
    } else if (adjusted >= 10 ** 3) {
      adjusted = (num / 1000);
      suffix = 'K';
      if (Math.round(adjusted) === 1000) {
        adjusted = 1;
        suffix = 'M';
      }
    }

    const localized = localizeNumber(adjusted, 2);

    return `${symbol}${localized}${suffix}`;
  };

  static formatQuantityWithUom = (quantity?: number, uom?: ProductUom) => {
    const quantityText = quantity ? localizeNumber(quantity, 2) : 0;
    const uomText = uom ? FriendlyUoM[uom] : "";
    return `${quantityText} ${uomText}`;
  };

  static formatAddress = (
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: State | null,
    postalCode?: string | null,
  ) => {
    return compactJoin([
      address1,
      address2,
      compactJoin([
        !city ? '' : `${city},`,
        !state ? '' : StateAbbreviations[state],
        postalCode,
      ], ' '),
    ], '\n');
  };
}
