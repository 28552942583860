import { DashboardRoutes, Routes } from '@/constants/Routes';
import { BenefitsPage } from '@/pages/CustomerDashboard/BenefitsPage';
import { CustomerDashboard } from '@/pages/CustomerDashboard/CustomerDashboard';
import { HowItWorksPage } from '@/pages/CustomerDashboard/HowItWorksPage';
import { PromotionsPage } from '@/pages/CustomerDashboard/Promotions/PromotionsPage';
import { WalletPage } from '@/pages/CustomerDashboard/WalletPage';
import React from 'react';
import {
  matchPath,
  PathMatch,
  Route,
  Routes as AppRoutes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useDashboard } from "@/hooks/useDashboard";

export const DashboardView = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const match = DashboardRoutes.reduce<PathMatch<'*' | 'accessToken'> | null>(
    (match, pattern) => (match ? match : matchPath(pattern, location.pathname)),
    null,
  );

  const accessToken = searchParams.get('accessToken') ?? match?.params.accessToken;
  const { data: dashboardData } = useDashboard(accessToken);

  if (!dashboardData) {
    return null;
  }

  return (
    <AppRoutes>
      <Route element={<CustomerDashboard />} path={Routes.CUSTOMER_DASHBOARD} />
      <Route element={<CustomerDashboard />} path={Routes.CUSTOMER_DASHBOARD_ALT} />
      <Route element={<BenefitsPage />} path={Routes.CUSTOMER_DASHBOARD_BENEFITS} />
      <Route element={<BenefitsPage />} path={Routes.CUSTOMER_DASHBOARD_BENEFITS_ALT} />
      <Route element={<HowItWorksPage />} path={Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS_ALT} />
      <Route element={<HowItWorksPage />} path={Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS} />
      <Route element={<WalletPage />} path={Routes.CUSTOMER_DASHBOARD_WALLET} />
      <Route element={<WalletPage />} path={Routes.CUSTOMER_DASHBOARD_WALLET_ALT} />
      <Route element={<PromotionsPage />} path={Routes.CUSTOMER_DASHBOARD_PROMOTIONS} />
      <Route element={<PromotionsPage />} path={Routes.CUSTOMER_DASHBOARD_PROMOTIONS_ALT} />
    </AppRoutes>
  );
};
