import { Button, Checkbox, Dialog, MenuItem, Select } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { RetailerRecipientApi } from '@/utilities/api/RetailerReceipientApi';
import { ApiUser, ApiUserPublic } from '@api/interfaces';
import { ApiRetailerRecipient } from '@api/interfaces/ApiRetailerRecipient';
import { getAccountName } from '@shared/utilities';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

interface AddRecipientsModalProps {
  addedRecipients: ApiRetailerRecipient[],
  onClose: () => void,
  retailerAdminUsers: (ApiUser | ApiUserPublic)[],
  retailerId: string,
}

export const AddRecipientsModal = ({
  addedRecipients,
  onClose,
  retailerAdminUsers,
  retailerId,
}: AddRecipientsModalProps) => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>(
    addedRecipients.map(recipient => recipient.userId),
  );

  const queryClient = useQueryClient();

  const handleSave = async () => {
    await RetailerRecipientApi.sync(retailerId, selectedUserIds);
    void queryClient.invalidateQueries(QueryKeys.LIST_RETAILER_RECIPIENTS);
    onClose();
  };

  return (
    <Dialog
      acceptButton={(props) => (
        <Button {...props} onClick={handleSave} variant="contained">
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button {...props} onClick={onClose}>
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      showCloseButton={false}
      subtitle="Users unable to be selected do not have a valid email address on their account."
      testID="add-retailer-recipients-dialog"
      title="Add Admin email recipients"
    >
      <Select
        label="User accounts"
        multiple
        onChange={(e) => {
          const userIds = e.target.value as string[];
          setSelectedUserIds(userIds);
        }}
        renderValue={() => `${selectedUserIds.length} of ${retailerAdminUsers.length} recipients`}
        testID="user-accounts-select"
        value={selectedUserIds}
      >
        {retailerAdminUsers.map((user) => (
          <MenuItem
            key={user.id}
            testID={`${user.id}-menu-item`}
            value={user.id}
          >
            <Checkbox
              checked={selectedUserIds.includes(user.id)}
              testID={`${user.id}-menu-item-checkbox`}
            >
              {getAccountName(user)}
            </Checkbox>
          </MenuItem>
        ))}
      </Select>
    </Dialog>
  );
};
