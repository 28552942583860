import {
  alpha,
  FormControlLabel,
  FormControlLabelProps,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import { HSpacer } from '..';

interface SwitchProps extends Partial<FormControlLabelProps> {
  children?: ReactNode,
  disabled?: boolean,
  onChangeChecked?: (checked: boolean) => void,
  testID: string,
}

const CustomSwitch = styled((props: MuiSwitchProps) => (
  <MuiSwitch
    focusRipple={false}
    focusVisibleClassName=".Mui-focusVisible"
    sx={{ overflow: 'initial !important' }}
    {...props}
  />
))(({ theme }) => ({
  width: 52,
  padding: 0,
  marginLeft: 10,
  '& .MuiTouchRipple-root': {
    width: "40px",
    height: "40px",
    top: "-6px",
    left: "-6px",
  },
  '& .MuiTouchRipple-ripple': {
    backgroundColor: alpha(theme.palette.background.paper, .5),
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    paddingTop: 2,
    paddingLeft: 2,
    transitionDuration: '300ms',
    margin: 2,
    color: theme.palette.text.secondary,
    //Checked
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: theme.palette.background.default,
      // Track, checked
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.success.main,
        height: 32,
        opacity: 1,
        border: 0,
      },
      // Thumb, checked
      '& .MuiSwitch-thumb': {
        backgroundRepeat: "no-repeat",
        boxShadow: 'none',
        boxSizing: 'border-box',
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette.text.secondary,
      },
      ':hover': {
        color: theme.palette.background.default,
      },
      // Track, disabled, checked
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: theme.palette.grey['400'],
      },
      // Thumb, disabled, checked
      '&.Mui-disabled .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        color: theme.palette.background.paper,
        opacity: 1,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.text,
    },
    ':hover': {
      color: theme.palette.text,
    },
    // Thumb, disabled, not checked
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.text.secondary,
      opacity: 0.38,
    },
    // Track, disabled, not checked
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: "transparent",
      opacity: 0.38,
    },
  },
  '& .MuiSwitch-thumb': {
    width: 24,
    height: 24,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    border: `1px solid ${theme.palette.grey['500']}`,
    borderRadius: 100,
    backgroundColor: "transparent",
    height: 32,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const Switch = ({
  children,
  disabled = false,
  onChangeChecked,
  testID,
  ...rest
}: SwitchProps) => {
  return (
    <FormControlLabel
      control={
        <CustomSwitch
          disabled={disabled}
          onChange={rest.onChange
            ? rest.onChange
            : ((e) => onChangeChecked?.(e.target.checked))}
        />
      }
      data-testid={testID}
      label={
        <Box pb={1}>
          <HSpacer size="4" />
          {children}
        </Box>
      }
      {...rest}
    />
  );
};
